import {useRecoilState} from 'recoil';
import {toggle} from '@mindfulness/utils/boolean';
import {playerAutoPlayState} from '../../../state/atoms';
import {SmallSwitch} from '../../forms/SmallSwitch';

export const AutoPlaySwitch: React.FC = () => {
  const [autoplay, setAutoplay] = useRecoilState<boolean>(playerAutoPlayState);
  return (
    <SmallSwitch
      title="Autoplay next"
      checked={autoplay}
      onChange={() => setAutoplay(toggle)}
    />
  );
};
