import {selector, selectorFamily} from 'recoil';
import {switchEnum} from '@mindfulness/utils/logic';

import {
  onboardingSelectionsState,
  onboardingStepsState,
  onboardingStepState,
} from '../state/atoms';
import {Steps} from '../types/types';

export const onboardingStepValues = selectorFamily({
  key: 'OnboardingStepValues',
  get:
    (key: Steps) =>
      ({get}) => {
        const selections = get(onboardingSelectionsState);
        return selections[key];
      },
  set:
    (key: Steps) =>
      ({set}, value) => {
        return set(onboardingSelectionsState, (prev) => {
          return {
            ...prev,
            [key as Steps]: value as Array<string>,
          };
        });
      },
});

export const onboardingSteps = selectorFamily({
  key: 'OnboardingSteps',
  get:
    (key?: 'next' | number) =>
      ({get}) => {
        const step = get(onboardingStepState);
        const steps = get(onboardingStepsState);
        if (!key) {
          return steps[steps.indexOf(step)];
        }
        if (typeof key === 'number') {
          return steps[key];
        }
        return switchEnum(key, {
          next: () => {
            const nextIndex = steps.indexOf(step) + 1;
            return steps[nextIndex];
          },
          else: () => steps[steps.indexOf(step)],
        });
      },
});

export const onboardingNextStep = selector({
  key: 'OnboardingNextStep',
  get: ({get}) => {
    const step = get(onboardingStepState);
    const steps = get(onboardingStepsState);
    const nextIndex = steps.indexOf(step) + 1;
    return steps[nextIndex];
  },
});

export const onboardingProgress = selector({
  key: 'OnboardingProgress',
  get: ({get}) => {
    const step = get(onboardingStepState);
    const steps = get(onboardingStepsState);
    return `${((steps.indexOf(step) + 1) / (steps.length + 1)) * 100}%`;
  },
});
