import {gql} from '@apollo/client';
import {FinalizedOrderFragment} from '../fragments';

export const GET_FINALIZED_ORDERS = gql`
  query getFinalizedOrders {
    orders(filter: {finalized: true}) {
      ...FinalizedOrderFragment
    }
  }
  ${FinalizedOrderFragment}
`;
