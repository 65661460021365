import {gql} from '@apollo/client';
import {OrderFragment} from '../fragments';

export const MUTATE_UPDATE_ORDER = gql`
  mutation updateOrder($order: ID!, $changes: OrderInput) {
    updateOrder(order: $order, changes: $changes) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;
