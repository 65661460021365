import {TagFeedFragment} from '../../../types/api';
import {getAllFeedIds, getRelatedFeeds, getTagFeed} from '../../../api';
import {Maybe} from '../../../types/types';

const mapRelatedFeeds = async ({
  slugs,
}: {
  slugs: Array<RelatedFeedData>;
}): Promise<Array<TagFeedFragment>> =>
  Promise.all(
      slugs
          .map(async ({id, title}: RelatedFeedData) => {
            const tagFeed = await getTagFeed({
              id,
              withUserData: false,
              resultsTake: 10,
            });

            return {
              ...tagFeed,
              title,
            };
          })
          .filter(Boolean),
  ) as Promise<Array<TagFeedFragment>>;

export const getFeeds = async (id: string): Promise<Maybe<APIProps>> => {
  try {
    const tagFeed = await getTagFeed({
      id,
      withUserData: false,
      resultsTake: 10,
    });

    const relatedFeedSlugs = await getRelatedFeeds(id);

    const relatedFeeds = await mapRelatedFeeds({
      slugs: relatedFeedSlugs || [],
    });

    return {
      tagFeed,
      relatedFeeds,
    };
  } catch (e) {
    console.error('Failed to get feeds', e);
  }
};

export const getStaticProps = getFeeds;

export const getStaticPaths = async (): Promise<
  Array<{ params: { slug: string } }>
> => {
  const allFeeds = await getAllFeedIds();
  return allFeeds.map((id) => ({params: {slug: id}}));
};

export type APIProps = {
  tagFeed: Maybe<TagFeedFragment | null>;
  relatedFeeds: Array<TagFeedFragment>;
};

type RelatedFeedData = { title: string; id: string; slug: string };
