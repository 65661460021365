import * as React from 'react';
import Link from 'next/link';

import {Container, Row, Column} from '../layout';
import {H3, Text} from '../typography';
import linkButton from '../forms/LinkButton/LinkButton.module.css';

export const EmptyCart: React.FC = () => (
  <Container gutter={{xs: 16, md: 80}} spacing={30}>
    <Row>
      <Column>
        <H3 weight="bold" as="p" spacing={16}>
          No items in cart
        </H3>
        <Text>
          {`It appears as though you have no items in your cart. If you're
          having issues adding items to your cart please contact our `}
          <Link className={linkButton.styledLink} href={`/contact`}>support team</Link> for further
          assistance.
        </Text>
      </Column>
    </Row>
  </Container>
);
