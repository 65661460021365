import {useContext, useEffect, useMemo, useState} from 'react';
import {omitEmpty} from '@mindfulness/utils/object';
import {Maybe, when} from '@mindfulness/utils/maybe';
import {useElements, useStripe} from '@stripe/react-stripe-js';
import type {PaymentMethod} from '@stripe/stripe-js';
import {useRecoilValue, useResetRecoilState} from 'recoil';
import router from 'next/router';

import {OpenCreditCard} from '../Checkout/OpenCreditCard';
import {CreditCard} from '../Checkout/CreditCard';
import {createPaymentMethod} from '../../../utils/stripe';
import {useNavigation} from '../../../hooks/useNavigation';
import {Context, ModalContext} from '../../global';
import {checkout} from '../../../api/functions/checkout';
import {
  orderIdState,
  orderItemsState,
  redirectState,
} from '../../../state/atoms';
import {assertString} from '../../../utils/string';
import {assertNumber} from '../../../utils';
import {GetOrderQuery} from '../../../types/api';
import {joinCohort} from '../../../api';
import {useTrack} from '../../global/SegmentProvider';

export const CheckoutForm: React.FC<Props> = ({
  order,
  onto,
  open,
  setOpen,
}) => {
  const track = useTrack();
  const clearOrderId = useResetRecoilState(orderIdState);
  const clearCartItem = useResetRecoilState(orderItemsState);
  const redirectReset = useResetRecoilState(redirectState);
  const redirectValue = useRecoilValue(redirectState);
  const {session, refreshSession} = useContext(Context);
  const {openModal} = useContext(ModalContext);
  const {goTo} = useNavigation();
  const [cardChanged, setCardChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const stripe = useStripe();
  const elements = useElements();

  const allHaveTrial = useMemo(() => {
    return order?.purchasables?.every(
        (item) =>
          assertNumber(item.trialDays) > 0 ||
        assertNumber(item.price?.discount?.trialDays) > 0,
    );
  }, [order?.purchasables]);

  const handleCheckout = async ({
    paymentMethod,
  }: {
    paymentMethod: PaymentMethod;
  }) => {
    if (!order?.id) {
      console.error('No order ID found. Can\'t checkout.');
      return;
    }
    try {
      const res = await checkout({
        order: order?.id,
        stripe: {
          paymentMethodId: paymentMethod.id,
        },
      });
      if (order.metadata.cohort) {
        await joinCohort({cohort: order.metadata.cohort});
      }

      track('Checkout completed', {
        order_id: order?.id,
        purchasables:
          res?.purchasables?.map((p) => assertString(p.title)) || [],
        checkout_url: router.asPath,
      });
      const hasSub = order.purchasables.find((p) => p.type === 'SUBSCRIPTION');
      const altTy = hasSub ? '/' : '/thank-you/checkout';

      clearOrderId();
      clearCartItem();
      await refreshSession?.();

      if (redirectValue) {
        redirectReset();
        const [pathname, search] = redirectValue.split('?');
        const params = new URLSearchParams(search);
        const queryObject = Object.fromEntries(params);
        goTo({
          pathname,
          query: {
            ...queryObject,
            ty: hasSub ?
              `${hasSub.trialDays ? hasSub.trialDays : 'true'}` :
              undefined,
          },
        });
        return;
      }

      goTo({
        pathname:
          when(onto, (o) => decodeURIComponent(o).split('?')[0]) || altTy,
        query: omitEmpty({
          order: order?.id,
          ty: hasSub ?
            `${hasSub.trialDays ? hasSub.trialDays : 'true'}` :
            undefined,
        }),
      });
    } catch (e) {
      setLoading(false);
      if (e instanceof Error) {
        setErrors({
          form: e.message,
        });
      }

      track('Checkout failed', {
        order_id: order?.id,
        checkout_url: router.asPath,
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const paymentMethod = await createPaymentMethod(elements, stripe);

      if (session) {
        await handleCheckout({
          paymentMethod,
        });

        return;
      }

      openModal?.({
        name: 'action',
        props: {
          redirect: async () => {
            await handleCheckout({
              paymentMethod,
            });
          },
        },
      });
    } catch (e) {
      setLoading(false);
      if (e instanceof Error) {
        setErrors({
          form: e.message,
        });
        return;
      }

      setErrors({
        form: 'An unexpected error occured.',
      });
    }
  };

  useEffect(() => {
    if (cardChanged) {
      track('Checkout payment entered', {});
    }
  }, [cardChanged, track]);

  return open ? (
    <CreditCard
      loading={loading}
      onSubmit={handleSubmit}
      errors={errors}
      onCardChanged={() => setCardChanged(true)}
      cardChanged={cardChanged}
      allHaveTrial={!!allHaveTrial}
    />
  ) : (
    <OpenCreditCard onClick={() => setOpen(true)} />
  );
};

type Props = {
  order: GetOrderQuery['order'];
  onto: Maybe<string>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
