import styled from '@emotion/styled';

export const Field = styled.div(
    ({theme}) => `
  border-bottom: 1px solid ${theme.colors.grey4};
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`,
);
