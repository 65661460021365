import {useContext} from 'react';
import {captureException} from '@sentry/nextjs';
import {ApolloError} from '@apollo/client';

import {useForm, useLoggedInAs, useNavigation} from '../../../hooks';
import {Context} from '../../global/Provider';
import {assertString} from '../../../utils/string';
import {redeemAccessPass} from '../../../api/functions/redeemAccessPass';
import {Column, Container, Row} from '../../layout';
import {H2, H4, Strong, Text} from '../../typography';
import {NoticeBox} from '../NoticeBox';
import {Alert, Button, Input} from '../../forms';
import {useTrack} from '../../global/SegmentProvider';

export const AffirmationCardRedeemForm: React.FC = () => {
  const {getQueryParam, goTo} = useNavigation();
  const {handleLogout, refreshSession} = useContext(Context);
  const track = useTrack();
  const loggedInAs = useLoggedInAs();
  const userEmail = getQueryParam('email');
  const code = getQueryParam('code');
  const onto = getQueryParam('onto');

  const {data, handleChange, handleSubmit, loading, errors} = useForm<{
    email: string;
    code: string;
  }>({
    initialValues: {
      email: assertString(userEmail),
      code: assertString(code),
    },
    onSubmit: async (e, {data, setErrors}) => {
      const onSubmit = async (email: string, code: string) => {
        track('Affirmation card redeem initiated', {
          code,
          email,
        });
        await redeemAccessPass({
          code,
        });
        await refreshSession?.();

        track('Affirmation card redeem success', {
          code,
          email,
        });
        if (onto) {
          const params = new URLSearchParams(onto.split('?')[1]);
          const singleID = params.get('s');
          if (singleID) {
            goTo({
              pathname: '/play',
              query: {
                s: singleID,
              },
            });
            return;
          }
        }
        goTo({
          pathname: '/thank-you/affirmation-cards',
        });
      };

      try {
        await onSubmit(data.email, data.code);
      } catch (err) {
        captureException(err);
        if (err instanceof Error || err instanceof ApolloError) {
          setErrors({form: err.message});
        }
      }
    },
    validations: {
      code: {
        required: {
          value: true,
          message: 'Please enter a code',
        },
      },
    },
  });

  return (
    <Container maxWidth="sm">
      <H2 size="xl">Enter the code from your Mindful Affirmations cards</H2>
      <H4 as="p" colour="grey7" spacing={20} lineHeight="150%">
        Please enter the code on the first card of your card deck to unlock
        these sessions.
      </H4>
      {errors?.form === 'Share limit exceeded.' ? (
        <NoticeBox>
          <Strong>Already unlocked your deck?</Strong>
          <Text>
            If you have already unlocked your deck, please check our{' '}
            <Text
              decoration="underline"
              colour="primary"
              as={(props) => (
                <a
                  {...props}
                  href="https://help.mindfulness.com/en/articles/8693368-mindful-affirmation-cards"
                  title="FAQs on using your Affirmation Cards"
                  target="_blank"
                  rel="noreferrer"
                />
              )}
            >
              FAQs on using your Affirmation Cards
            </Text>{' '}
            for support.
          </Text>
        </NoticeBox>
      ) : null}

      <Row as="form" onSubmit={handleSubmit}>
        <Column spacing={30}>
          <Input
            label="Affirmation Cards Code"
            id="code"
            placeholder="Enter the code from your first card"
            value={data.code}
            onChange={handleChange('code')}
            error={errors?.code}
          />
          <Alert
            error={
              errors?.form === 'Share limit exceeded.' ?
                'This code has already been used to unlock a deck previously.' :
                errors?.form === 'Share with this code was not found.' ?
                'This code was not found, please check the code and try again' :
                errors?.form
            }
          />
        </Column>

        <Column spacing={16}>
          <Button
            loading={loading}
            type="submit"
            id="RedeemAffirmationDeck"
            wide
          >
            Unlock Cards
          </Button>
        </Column>
        <Column textAlign="center">
          <Text fontSize="xs" colour="grey7">
            {`You are signed in as ${loggedInAs}`}{' '}
            <Text
              as="button"
              decoration="underline"
              cursor="pointer"
              onClick={() => handleLogout?.()}
            >
              Switch accounts
            </Text>
          </Text>
        </Column>
      </Row>
    </Container>
  );
};
