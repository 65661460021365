import {gql} from '@apollo/client';
import {QuoteFragment} from '../../api/fragments';

export const GET_DAILY_QUOTE = gql`
  query getDailyQuote {
    todaysQuote {
      ...QuoteFragment
    }
  }
  ${QuoteFragment}
`;
