import {useRef, useState, useMemo} from 'react';
import {useRecoilValue} from 'recoil';
import {Maybe} from '@mindfulness/utils/maybe';
import {switchEnum} from '@mindfulness/utils/logic';
import {toggle} from '@mindfulness/utils/boolean';

import {
  BundleDetailFragment,
  CollectionFeedFragment,
  SingleDetailFragment,
} from '../../../types/api';
import {playerPlayState} from '../../../state/atoms';
import {AutoPlaySwitch} from '../../ui/AutoPlaySwitch';
import {CourseStack} from '../../ui/CourseStack';
import {PlaylistStack} from '../../ui/PlaylistStack';
import {TeacherPanel} from '../../ui/TeacherPanel';
import {Box} from '../../layout';
import {APIImage, Icon} from '../../global';
import {Title2, Text} from '../../typography';

import {
  CloseButton,
  Sidebar,
  SidebarInner,
  CoverImage,
  Content,
  TitlePadding,
  BottomSheetBar,
} from './Play.styles';
import {assertString} from '../../../utils';
import {Divider} from '../../ui';

export const PlayerSidebar: React.FC<{
  bundle: Maybe<BundleDetailFragment>;
  collection: Maybe<CollectionFeedFragment>;
  single: Maybe<SingleDetailFragment>;
}> = ({bundle, collection, single}) => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(true);
  const status = useRecoilValue(playerPlayState);

  const firstContributor = useMemo(
      () => single?.contributors[0],
      [single?.contributors[0]],
  );

  return (
    <Box position="relative">
      <CloseButton
        id={`PlayerSidebarClose`}
        role="button"
        aria-label="Close sidebar"
        open={open}
        type="button"
        onClick={() => setOpen(toggle)}
      >
        <Icon name="arrow-sidebar" size={16} />
      </CloseButton>
      <Sidebar ref={sidebarRef} open={open} status={status}>
        <SidebarInner>
          {bundle?.coverImage?.url ? (
            <CoverImage>
              <APIImage
                url={bundle?.coverImage?.url}
                height={301}
                decoration="none"
                background="white"
              />
            </CoverImage>
          ) : null}

          <Content>
            <TitlePadding>
              <BottomSheetBar />
              <Title2 loading={!bundle && !collection} spacing={4}>
                {bundle?.title || collection?.title}
              </Title2>
              <Text loading={!bundle && !collection} loadingHeight={66}>
                {bundle?.description || collection?.description}
              </Text>
              {firstContributor && (
                <TeacherPanel
                  loading={!(single && (bundle || collection))}
                  spacing={10}
                  teacher={firstContributor.producer}
                />
              )}
              <AutoPlaySwitch />
              <Divider bottomSpacing={20} topSpacing={10} />
            </TitlePadding>

            {single ? (
              <>
                {collection ?
                  switchEnum(assertString(collection.layout), {
                    COURSE: (
                      <CourseStack
                        key={collection.id}
                        collection={collection}
                        bundleId={undefined}
                        singleId={single.id}
                      />
                    ),
                    else: (
                      <PlaylistStack
                        key={collection.id}
                        bundleId={undefined}
                        collection={collection}
                        singleId={single.id}
                      />
                    ),
                  }) :
                  bundle ?
                  bundle.collections.map((collection) =>
                    switchEnum(assertString(collection.playerLayout), {
                      COURSE: (
                        <CourseStack
                          key={collection.id}
                          collection={collection}
                          bundleId={bundle.id}
                          singleId={single.id}
                        />
                      ),
                      else: (
                        <PlaylistStack
                          key={collection.id}
                          collection={collection}
                          bundleId={bundle.id}
                          singleId={single.id}
                        />
                      ),
                    }),
                  ) :
                  null}
              </>
            ) : null}
          </Content>
        </SidebarInner>
      </Sidebar>
    </Box>
  );
};
