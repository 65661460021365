import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {isUndefined} from 'lodash';
import styled from '@emotion/styled';
import {Theme} from '@emotion/react';
import {switchEnum} from '@mindfulness/utils/logic';
import FadeIn from 'react-fade-in';
import dayjs from 'dayjs';

import {
  Box,
  Card,
  Column,
  Container,
  Flex,
  Row,
  Stack,
} from '../../layout';
import {Small, Title1, Text, H4} from '../../typography';
import {Avatar} from '../../ui/Avatar';
import {Field} from './ProfileField.styles';
import {Maybe, SessionFragment} from '../../../types/types';
import {assertString} from '../../../utils/string';
import {getFinalizedOrders} from '../../../api/functions/getFinalizedOrders';
import type {
  FinalizedOrderFragment,
  SubscriptionFragment,
  SubscriptionStatus,
} from '../../../types/api';
import {Context} from '../../global/Provider';
import {Button} from '../../forms';
import {Divider} from '../../ui/Divider';
import {getSubscriptions} from '../../../api/functions/getSubscriptions';
import {updateUser} from '../../../api';
import {useTrackPage} from '../../../hooks';
import {Section} from '../../layouts/Section';
import {ButtonAnchor} from 'shared/components/global/ButtonLink/ButtonAnchor';
import {ButtonLink} from 'shared/components/global/ButtonLink';

export const Profile: React.FC = () => {
  const [orders, setOrders] = useState<Array<FinalizedOrderFragment>>();
  const [subscriptions, setSubscriptions] = useState<
    Array<SubscriptionFragment>
  >([]);
  const {session, refreshSession, handleLogout} = useContext(Context);
  useTrackPage('Profile', 'profile');
  const firstName = useMemo(() => {
    const firstName = session?.firstName;
    if (firstName?.endsWith('s')) {
      return `${firstName}’`;
    }
    if (firstName) {
      return `${firstName}’s`;
    }
    return firstName;
  }, [session?.firstName]);

  useEffect(() => {
    (async () => {
      await refreshSession?.();
      const res = await getFinalizedOrders();
      const productsOnly = (res || [])
          .map((order) => {
            return {
              ...order,
              purchasables: order.purchasables.filter((p) => {
                return !p.unlocks?.subscription;
              }),
            };
          })
          .filter((o) => o.purchasables.length > 0);
      setOrders(productsOnly || []);
      const subs = await getSubscriptions();
      const subscriptionsOnly = subs.filter((sub) =>
        ['mcom-plus', 'mcom-basics'].includes(
            assertString(sub.plan.productCode),
        ),
      );
      setSubscriptions(subscriptionsOnly || []);
    })();
  }, []);

  const accessPass = useMemo(() => {
    if (!session?.user) return;
    const endsAt = session?.user?.accessPassEndsAt;
    if (!endsAt) return;
    const now = dayjs();
    const end = dayjs(endsAt);
    if (now.isAfter(end)) {
      return;
    }

    return {
      title: switchEnum(assertString(session.user?.accessPassSource, 'else'), {
        BOGO: 'Gift Pass',
        GIFT_CARD: 'Gift Pass',
        GROUP_PASS: 'Group Pass',
        GUEST_PASS: 'Guest Pass',
        SUPPORT: 'Complimentary',
        TEAM_PASS: 'Team Pass',
        else: 'Complimentary',
      }),
      endsAt: dayjs(endsAt).format('Do MMMM YYYY'),
    };
  }, [session]);

  useEffect(() => {
    if (isUndefined(session)) {
      window.location.href = '/';
    }
  }, [session]);

  return (
    <Section>
      <Container maxWidth="md">
        <Row spacing={30}>
          <Column>
            <Card background="greyWarm" shadow="none" padding={34}>
              <Avatar session={session} size="lg" spacing={10} />
              <Title1>{`${firstName ? `${firstName} ` : ''}Profile`}</Title1>
            </Card>
          </Column>
        </Row>
        <Row spacing={60}>
          <Column>
            <Stack direction="vertical" space={20}>
              <ProfileField label="First Name" value="firstName" editable />
              <ProfileField label="Last Name" value="lastName" editable />
              <ProfileField label="Email" value="email" />
            </Stack>
          </Column>
        </Row>
        <FadeIn>
          {subscriptions.length > 0 || accessPass ? (
            <Row spacing={40}>
              <Column spacing={20}>
                <H4 weight="bold">Subscriptions</H4>
              </Column>
              <Column>
                <Stack direction="vertical">
                  {accessPass ? (
                    <>
                      <Flex justify="space-between" items="start">
                        <Box>
                          <Text>{`Mindfulness Plus+ (${accessPass.title})`}</Text>
                          <Text fontSize="sm">
                            <Text as="span" colour="highlight2">Active</Text>
                            <span>{' · '}</span>
                            <span>{`Valid until ${accessPass.endsAt}`}</span>
                          </Text>
                        </Box>
                      </Flex>
                      <Divider spacing={20} />
                    </>
                  ) : subscriptions.map((subscription) => (
                    <SubscriptionItem key={subscription.id} {...subscription} />
                  ))}
                </Stack>
              </Column>
            </Row>
          ) : null}
        </FadeIn>

        <FadeIn>
          {orders?.length ? (
            <Row spacing={12}>
              <Column spacing={20}>
                <H4 weight="bold">Purchases</H4>
              </Column>
              <Column>
                <Stack direction="vertical">
                  {orders.map((order) => {
                    return order.purchasables.map((purchasable) => (
                      <PurchaseItem
                        key={purchasable.id}
                        {...purchasable}
                        finalizedAt={order.finalizedAt}
                      />
                    ));
                  })}
                </Stack>
              </Column>
            </Row>
          ) : null}
        </FadeIn>
        <Row>
          <Column justify="center">
            <Button
              variant="outline"
              id="ProfileLogout"
              onClick={() => handleLogout?.()}
            >
              Logout
            </Button>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

const ProfileField: React.FC<{
  label: string;
  value: keyof SessionFragment['user'];
  editable?: boolean;
}> = ({label, value, editable}) => {
  const {session} = useContext(Context);
  const [editing, setEditing] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement>(null);
  const [val, setVal] = useState<string>(
      (session?.user?.[value] as string) || '',
  );

  useEffect(() => {
    setVal((session?.user?.[value] as string) || '');
  }, [session?.user?.[value]]);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref.current, editing]);

  return (
    <Field
      as="form"
      onSubmit={async (e) => {
        e.preventDefault();
        if (!session) return;
        await updateUser({
          details: {
            [value]: val,
          },
          session,
        });
        setEditing(false);
      }}
    >
      <Box width="100%">
        <Small fontSize="xs">{label}</Small>
        {editing ? (
          <StyledInput
            ref={ref}
            value={val}
            onChange={(e) => setVal(e.target.value)}
          />
        ) : (
          <Text>{val}</Text>
        )}
      </Box>
      {editable ? (
        <>
          {editing ? (
            <Stack shrink={0} space={10} direction="horizontal">
              <Button
                variant="faded"
                size="xs"
                type="button"
                onClick={() => {
                  setVal((session?.user?.[value] as string) || '');

                  setEditing(false);
                }}
                id={`ProfileCancel${label}`}
              >
                Cancel
              </Button>
              <Button size="xs" id={`ProfileSave${label}`} type="submit">
                Save
              </Button>
            </Stack>
          ) : (
            <Button
              variant="faded"
              type="button"
              size="xs"
              onClick={() => setEditing(true)}
              id={`ProfileEdit${label}`}
            >
              Edit
            </Button>
          )}
        </>
      ) : null}
    </Field>
  );
};

const SubscriptionItem: React.FC<SubscriptionFragment> = ({
  plan,
  store,
  autoRenews,
  autoRenewsAt,
  storePlanId,
  expiresAt,
  status,
}) => {
  const {session} = useContext(Context);
  const storeName = useMemo(() => {
    return switchEnum(store, {
      STRIPE: 'Web',
      APPLE: 'Apple',
      PLAY: 'Google',
      else: '',
    });
  }, [store]);
  const statusText = useMemo(() => {
    return switchEnum(status, {
      TRIALLING: 'Free Trial',
      ACTIVE: 'Active',
      else: undefined,
    });
  }, [status]);
  const colour = useMemo(() => {
    return switchEnum<SubscriptionStatus, Maybe<keyof Theme['colors']>>(status, {
      TRIALLING: 'highlight2',
      ACTIVE: 'highlight2',
      else: undefined,
    });
  }, [status]);
  return (
    <>
      <Flex justify="space-between" items="start">
        <Box position='relative'>
          {['EXPIRED', 'ERROR'].includes(status) ? <Exclamation /> : null}
          <Text>{`Mindfulness ${
            plan.productCode?.includes('basics') ? 'Basics' : 'Plus+'
          } (${storeName})`}</Text>
          {['EXPIRED', 'ERROR'].includes(status) ? (
            <Text fontSize="sm" colour="accent">
              {`Expired ${dayjs(expiresAt).format(
                  'Do MMMM YYYY',
              )}`}
            </Text>
          ) : (
            <Text fontSize="sm">
              {statusText ? (
                <>
                  <Text as="span" colour={colour}>{statusText}</Text>
                  <span>{' · '}</span>
                </>
              ) : null}
              <span>{autoRenews ? `Renews ${dayjs(autoRenewsAt).format(
                  'Do MMMM YYYY',
              )}` : storePlanId?.includes('lifetime') ? 'Lifetime' : `Expires ${dayjs(expiresAt).format(
                  'Do MMMM YYYY',
              )}`}</span>
            </Text>
          )}
        </Box>
        <FadeIn>
          {session?.userId ? (
            <ButtonAnchor
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.API_PROTOCOL || 'https://'}${
                process.env.API_DOMAIN
              }/f/billing-portal?userId=${session.userId}`}
              variant={status === 'EXPIRED' ? 'primary' : 'faded'}
              size="xs"
              id={`Manage${storeName}`}
            >
              {status === 'EXPIRED' ? 'Renew' : 'Manage'}
            </ButtonAnchor>
          ) : null}
        </FadeIn>
      </Flex>
      <Divider spacing={20} />
    </>
  );
};
const PurchaseItem: React.FC<
  FinalizedOrderFragment['purchasables'][0] & {
    finalizedAt: string;
  }
> = ({title, subtitle, content, unlocks, finalizedAt}) => {
  const href = useMemo(() => {
    if (unlocks?.bundle || unlocks?.single) {
      return content && 'webSlug' in content ? content?.webSlug : '/';
    }
    return '/';
  }, [content, unlocks?.subscription, unlocks?.bundle, unlocks?.single]);
  return (
    <>
      <Flex justify="space-between" items="start">
        <Box>
          <Text>{title}</Text>
          <Text fontSize="sm">{subtitle}</Text>
          <Text fontSize="sm">{`Purchased ${dayjs(finalizedAt).format(
              'Do MMMM YYYY',
          )}`}</Text>
        </Box>
        <ButtonLink
          href={assertString(href, '/')}
          variant="faded"
          size="xs"
          id={`Access`}
        >
          Access
        </ButtonLink>
      </Flex>
      <Divider spacing={20} />
    </>
  );
};


export const Exclamation: React.FC = () => {
  return (
    <ExclamationWrapper>
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.71151 3.8389C8.42452 2.6943 10.4385 2.08337 12.4987 2.08337C13.8666 2.08337 15.2212 2.35281 16.485 2.8763C17.7488 3.39978 18.8971 4.16707 19.8644 5.13435C20.8317 6.10162 21.599 7.24995 22.1224 8.51375C22.6459 9.77756 22.9154 11.1321 22.9154 12.5C22.9154 14.5603 22.3044 16.5742 21.1598 18.2872C20.0152 20.0002 18.3884 21.3354 16.485 22.1238C14.5816 22.9122 12.4871 23.1185 10.4665 22.7166C8.44588 22.3146 6.5898 21.3225 5.13301 19.8657C3.67621 18.4089 2.68412 16.5529 2.28219 14.5322C1.88026 12.5116 2.08654 10.4172 2.87496 8.51375C3.66337 6.61036 4.9985 4.9835 6.71151 3.8389ZM12.4987 7.2917C12.2224 7.2917 11.9575 7.40145 11.7621 7.5968C11.5668 7.79215 11.457 8.0571 11.457 8.33337V12.5C11.457 12.7763 11.5668 13.0413 11.7621 13.2366C11.9575 13.432 12.2224 13.5417 12.4987 13.5417C12.775 13.5417 13.0399 13.432 13.2353 13.2366C13.4306 13.0413 13.5404 12.7763 13.5404 12.5V8.33337C13.5404 8.0571 13.4306 7.79215 13.2353 7.5968C13.0399 7.40145 12.775 7.2917 12.4987 7.2917ZM13.457 16.2709C13.4342 16.2045 13.4027 16.1414 13.3633 16.0834L13.2383 15.9271C13.0918 15.7826 12.9058 15.6847 12.7037 15.6457C12.5017 15.6068 12.2926 15.6286 12.1029 15.7084C11.9766 15.7611 11.8604 15.8351 11.7591 15.9271C11.6626 16.0245 11.5862 16.1399 11.5344 16.2668C11.4825 16.3937 11.4563 16.5296 11.457 16.6667C11.4587 16.8028 11.487 16.9373 11.5404 17.0625C11.5872 17.1918 11.6618 17.3092 11.759 17.4064C11.8562 17.5036 11.9736 17.5782 12.1029 17.625C12.2276 17.6801 12.3624 17.7086 12.4987 17.7086C12.635 17.7086 12.7699 17.6801 12.8945 17.625C13.0238 17.5782 13.1412 17.5036 13.2384 17.4064C13.3356 17.3092 13.4103 17.1918 13.457 17.0625C13.5104 16.9373 13.5387 16.8028 13.5404 16.6667C13.5455 16.5973 13.5455 16.5277 13.5404 16.4584C13.5224 16.3919 13.4943 16.3287 13.457 16.2709Z" fill="#FF4D6D"/>
      </svg>
    </ExclamationWrapper>
  );
};

const ExclamationWrapper = styled.div(({theme}) => `
  display: none;
  @media (min-width: ${theme.breakpoints.md.min}) {
    display: block;
  }
  position: absolute;
  left: -32px;
`);
const StyledInput = styled.input`
    width: 100%;
  `;
