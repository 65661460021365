import * as React from 'react';
import {ArticlesTemplate} from '@mindfulness/cms';

import {Column, Container, Flex, Grid, Row, Section} from '../../layout';
import {H1} from '../../typography';
import {Sections} from '../../global';
import {ArticleCard} from '../../ui';
import {ArticleBodyData, ReferencedArticles} from '../../../types/types';
import {PaginationButton} from './Articles.styles';
import {useQueryParam} from '../../../hooks/useQueryParam';
import {useTrackPage} from '../../../hooks';

export const Articles: React.FC<Props> = ({
  articles,
  template,
  articleCount,
}) => {
  useTrackPage('Articles', 'articles');
  const [activePage, setActivePage] = useQueryParam('page', '1', false);
  const totalPages = Math.floor(articleCount / 24);
  const pageArray = Array.from(Array(totalPages).keys());
  return (
    <>
      <Section>
        <Container>
          <Row spacing={40}>
            <Column
              span={{
                xs: 24,
                md: 18,
              }}
            >
              <H1 spacing={14}>Latest Mindfulness Articles</H1>
            </Column>
          </Row>
          <Grid
            cols={{
              xs: 1,
              sm: 2,
              lg: 3,
            }}
            rowGap={{
              xs: 40,
              md: 80,
            }}
            spacing={50}
          >
            {articles.map((article) => (
              <ArticleCard {...article} key={article._id} />
            ))}
          </Grid>
          <Flex gap={16} justify="center">
            {pageArray.map((page) => (
              <PaginationButton
                active={page + 1 === Number(activePage)}
                onClick={() => {
                  setActivePage(`${page + 1}`);
                }}
                key={page}
              >
                {page + 1}
              </PaginationButton>
            ))}
          </Flex>
        </Container>
      </Section>
      {template?.sections ? (
        <Sections sections={template.sections} indexOffset={1} />
      ) : null}
    </>
  );
};

type Props = {
  articles: Array<ReferencedArticles & ArticleBodyData>;
  template: ArticlesTemplate;
  articleCount: number;
};
