import {when} from '@mindfulness/utils/maybe';
import {switchEnum} from '@mindfulness/utils/logic';
import {
  Maybe,
  SingleDetailFragment,
  SingleVariantFragment,
} from '../../../types/types';
import {getSingleById, getBundle, getTagCollection} from '../../../api';
import {
  BundleDetailFragment,
  CollectionFeedFragment,
} from '../../../types/api';
import {isOnClient} from '../../../utils';
import {redeemAccessPass} from '../../../api/functions/redeemAccessPass';


const getSingle = async (id: string) => {
  if (!id) return;
  const single = await getSingleById({id, withUserData: true, resultsTake: 5});
  if (!single) return;
  if (isOnClient()) {
    const params = new URLSearchParams(window.location.search);
    const productID = params.get('product');
    if (productID) {
      const code = switchEnum(productID, {
        'D1': 'FRYXQA',
        'else': undefined,
      });
      if (code) {
        await redeemAccessPass({
          code,
        });

        const updatedSingle = await getSingleById({id, withUserData: true, resultsTake: 5});
        return updatedSingle;
      }
    }
  }
  return single;
};
const getCollectionSingleId = (
    collection: CollectionFeedFragment,
    singleId: Maybe<string>,
): string => {
  if (singleId) {
    return singleId;
  }

  const collectionHasSingle = collection.results.items.find((i) => {
    if (i.__typename === 'Single') {
      return i.id === singleId;
    }
  });
  if (collectionHasSingle) {
    return singleId as string;
  }
  // First single that has had less that 90% played
  for (const i of collection.results.items) {
    if (i.__typename === 'Single') {
      if (
        !i.userData?.lastPlayedPercent ||
        i.userData.lastPlayedPercent <= 90
      ) {
        return i.id;
      }
    }
  }
  // First single
  for (const i of collection.results.items) {
    if (i.__typename === 'Single') {
      return i.id;
    }
  }

  return singleId as string;
};

const getSingleId = (
    bundle: Maybe<BundleDetailFragment>,
    singleId: Maybe<string>,
): string => {
  if (!bundle && singleId) {
    return singleId;
  }
  // Check that the bundle actually has the single from the query params in it
  const bundleHasSingle = bundle?.collections.find((c) =>
    c.results.items.find((i) => {
      if (i.__typename === 'Single') {
        return i.id === singleId;
      }
    }),
  );
  if (bundleHasSingle) {
    return singleId as string;
  }
  // First single that has had less that 90% played
  if (bundle?.collections) {
    for (const c of bundle?.collections) {
      for (const i of c.results.items) {
        if (i.__typename === 'Single') {
          if (
            !i.userData?.lastPlayedPercent ||
            i.userData.lastPlayedPercent <= 90
          ) {
            return i.id;
          }
        }
      }
    }
    // First single
    for (const c of bundle.collections) {
      for (const i of c.results.items) {
        if (i.__typename === 'Single') {
          return i.id;
        }
      }
    }
  }

  return singleId as string;
};

// We hydrate here because we want to get the userData
export const hydrate = async ({
  bundleId,
  collectionId,
  singleId,
  variantId,
  withUserData,
}: {
  bundleId: Maybe<string>;
  collectionId: Maybe<string>;
  singleId: Maybe<string>;
  variantId: Maybe<string>;
  withUserData?: boolean;
}): Promise<PlayProps> => {
  // Get the tag collection if there is a c query param
  const collection = await when(
      collectionId,
      async (c) =>
        await getTagCollection(
            {
              id: c,
              withUserData: !!withUserData,
              resultsTake: 100,
            },
            {
              partialRefetch: true,
              fetchPolicy: 'network-only',
            },
        ),
  );
  if (collection) {
    const id = getCollectionSingleId(collection, singleId);
    const single = await getSingle(id);

    const variant =
      single?.variants?.find(({id}) => variantId === id) ||
      single?.variants?.[0];

    if (id !== singleId) {
      return {
        variant,
        collection,
        single,
        redirectSingle: single,
      };
    }
    return {
      collection,
      single,
      variant,
    };
  }

  const bundle = await when(
      bundleId,
      async (b) =>
        await getBundle(
            {
              id: b,
              withUserData: true,
              resultsTake: 100,
            },
            {
              partialRefetch: true,
              fetchPolicy: 'network-only',
            },
        ),
  );
  const id = getSingleId(bundle, singleId);
  const single = await getSingle(id);

  const variant =
    single?.variants?.find(({id}) => variantId === id) ||
    single?.variants?.[0];

  if (id !== singleId) {
    return {
      variant,
      bundle,
      single,
      redirectSingle: single,
    };
  }

  return {
    single,
    variant,
    bundle,
  };
};

export type PlayProps = Partial<{
  single: SingleDetailFragment;
  redirectSingle: SingleDetailFragment;
  variant: SingleVariantFragment;
  bundle: Maybe<BundleDetailFragment>;
  collection: Maybe<CollectionFeedFragment>;
}>;
