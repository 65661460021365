import {RatingsFragment} from '../../../types/api';
import {Icon} from '../../global/Icon';
import {Small} from '../../typography/Text';

export const Ratings: React.FC<{
  ratings: RatingsFragment;
}> = ({ratings}) => (
  <Small colour="grey7">
    {ratings?.averageFormatted ? (
      <Icon name="star" size={12}>{ratings.averageFormatted}</Icon>
    ) : null}
  </Small>
);
