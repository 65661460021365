import {SingleHighlight} from '@mindfulness/cms';

import {GET_SINGLE} from '../../../api';
import {SingleFragment} from '../../../types/types';
import {adminClient} from '../../../utils';

// TODO, apply the async error handler and log more information about the single.
export const getStaticProps = async (
    section: SingleHighlight,
): Promise<SingleFragment | null> => {
  if (!section.single?.id) return null;
  try {
    const {data} = await adminClient.query({
      query: GET_SINGLE,
      variables: {
        id: section.single.id,
      },
    });
    return data.single;
  } catch (e) {
    console.error(
        e,
        `Failed to getStaticProps in SingleHighlight for ${section.single.id}`,
    );
    return null;
  }
};
