import * as React from 'react';
import {TeacherTemplate} from '@mindfulness/cms';
import dynamic from 'next/dynamic';

import {ProducerFragment} from '../../../types/types';
import {Column, Container, Grid, Row} from '../../layout';
import {H1, H4} from '../../typography';
import {Breadcrumb, TeacherFeedBlock} from '../../ui';
import {useTrack} from '../../global/SegmentProvider';
import {Section} from '../../layouts/Section';

const Sections = dynamic(() =>
  import('../../global/Sections/index').then(({Sections}) => Sections),
);

export const Teachers: React.FC<Props> = ({producers, template}) => {
  const track = useTrack();
  return (
    <>
      <Section>
        <Container>
          <Row spacing={40}>
            <Column
              span={{
                xs: 24,
                md: 18,
              }}
            >
              <Breadcrumb items={[{title: 'Teachers', link: '/teachers'}]} />
              <H1 spacing={14}>World Trusted Teachers</H1>
              <H4 colour="grey7">
                {`Discover the world's most renowned mindfulness experts all in one place! Our comprehensive listing features experts from various backgrounds and cultures who have dedicated their lives to teaching the transformative power of mindfulness. From bestselling authors to respected teachers, our experts will guide you on your journey towards greater peace, happiness, and fulfillment. Explore their insights, practices, and teachings today and unlock the benefits of mindfulness in your own life. Start your journey to mindfulness mastery now!`}
              </H4>
            </Column>
          </Row>
          <Grid
            cols={{
              xs: 2,
              lg: 4,
            }}
            rowGap={24}
            columnGap={24}
            spacing={50}
          >
            {producers.map((producer, index) => {
              return (
                <TeacherFeedBlock
                  key={producer.webSlug}
                  priority={index < 8}
                  onClick={() => {
                    track('Collection item selected', {
                      item_index: index,
                      item_id: producer.id,
                      item_type: 'PRODUCER',
                    });
                  }}
                  {...producer}
                />
              );
            })}
          </Grid>
        </Container>
      </Section>
      {template?.sections ? (
        <Sections sections={template.sections} indexOffset={1} />
      ) : null}
    </>
  );
};

type Props = {
  producers: Array<ProducerFragment>;
  template: TeacherTemplate;
};
