import {TeacherFeed} from '@mindfulness/cms';

import {GET_PRODUCER} from '../../../api';
import {ProducerFragment} from '../../../types/types';
import {adminClient} from '../../../utils';

export const getStaticProps = async (
    section: TeacherFeed,
): Promise<{ producers: Array<ProducerFragment> } | null> => {
  if (!section.blocks) {
    throw new Error('Blocks not provided in getStaticProps for TeacherFeed');
  }
  try {
    const producers = await Promise.all(
        section.blocks.map(async (block) => {
          if (!block.producer) {
            throw new Error(
                'Producer not provided in getStaticProps for TeacherFeed',
            );
          }
          const {data} = await adminClient.query({
            query: GET_PRODUCER,
            variables: {
              id: block.producer.id,
            },
          });
          return data.producer;
        }),
    );
    return {producers};
  } catch (e) {
    console.error(`Failed to getStaticProps in TeacherFeed`);
    return null;
  }
};
