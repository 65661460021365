/** Global */
export * from './components/global';

/** Templates */
export * from './components/templates';

/** Layout components */
export * from './components/layout';

/** Typography components */
export * from './components/typography';

/** Form components */
export * from './components/forms';

/** Hooks */
export * from './hooks';

/** JSON ld schemas */
export * from './schemas';

/** UI items */
export * from './components/ui';

/** Export global shared type definitions */
export * from './types/types';

export * from './utils/index';

/** Export any getStaticProps functions from sections */

import {getStaticProps as getCollectionStaticProps} from './components/sections/Collection/Collection.effects';
import {getStaticProps as getSingleHighlightStaticProps} from './components/sections/SingleHighlight/SingleHighlight.effects';
import {getStaticProps as getSingleInlinePlayerStaticProps} from './components/sections/SingleInlinePlayer/SingleInlinePlayer.effects';
import {getStaticProps as getSinglePlayerStaticProps} from './components/sections/SinglePlayer/SinglePlayer.effects';
import {getStaticProps as getTeacherFeedStaticProps} from './components/sections/TeacherFeed/TeacherFeed.effects';
import {getStaticProps as getPlanPickerStaticProps} from './components/sections/PlanPicker/PlanPicker.effects';
import {getStaticProps as getHeroPlanPickerStaticProps} from './components/sections/HeroPlanPicker/HeroPlanPicker.effects';
import {getStaticProps as getContentCollectionStaticProps} from './components/sections/ContentCollection/ContentCollection.effects';

export const getSectionStaticProps = {
  Collection: getCollectionStaticProps,
  SingleHighlight: getSingleHighlightStaticProps,
  SingleInlinePlayer: getSingleInlinePlayerStaticProps,
  SinglePlayer: getSinglePlayerStaticProps,
  TeacherFeed: getTeacherFeedStaticProps,
  PlanPicker: getPlanPickerStaticProps,
  HeroPlanPicker: getHeroPlanPickerStaticProps,
  ContentCollection: getContentCollectionStaticProps,
};

/** Export browse page getStaticProps */
import {
  getStaticProps as getBrowseStaticProps,
  getStaticPaths as getBrowseStaticPaths,
} from './components/templates/Browse/Browse.effects';
import {
  getStaticProps as getFeedStaticProps,
  getStaticPaths as getFeedStaticPaths,
} from './components/templates/Feed/Feed.effects';
import {
  getStaticProps as getMeditationStaticProps,
  getStaticPaths as getMeditationStaticPaths,
} from './components/templates/Meditation/Meditation.effects';
import {
  getStaticProps as getDailyStaticProps,
  getStaticPaths as getDailyStaticPaths,
} from './components/templates/Daily/Daily.effects';
import {
  getStaticProps as getGratitudeStaticProps,
  getStaticPaths as getGratitudeStaticPaths,
} from './components/templates/Gratitude/Gratitude.effects';
import {
  getStaticProps as getTalkStaticProps,
  getStaticPaths as getTalkStaticPaths,
} from './components/templates/Talk/Talk.effects';
import {
  getStaticProps as getSoundscapeStaticProps,
  getStaticPaths as getSoundscapeStaticPaths,
} from './components/templates/Soundscape/Soundscape.effects';
import {
  getStaticProps as getMusicStaticProps,
  getStaticPaths as getMusicStaticPaths,
} from './components/templates/Music/Music.effects';
import {
  getStaticProps as getScenicStaticProps,
  getStaticPaths as getScenicStaticPaths,
} from './components/templates/Scenic/Scenic.effects';
import {
  getStaticProps as getSpecialStaticProps,
  getStaticPaths as getSpecialStaticPaths,
} from './components/templates/Special/Special.effects';
import {
  getStaticProps as getStoryStaticProps,
  getStaticPaths as getStoryStaticPaths,
} from './components/templates/Story/Story.effects';
import {
  getStaticProps as getBreathworkStaticProps,
  getStaticPaths as getBreathworkStaticPaths,
} from './components/templates/Breathwork/Breathwork.effects';
import {
  getStaticProps as getCollectionTemplateStaticProps,
  getStaticPaths as getCollectionStaticPaths,
} from './components/templates/Collection/Collection.effects';
import {
  getStaticProps as getTeacherTemplateStaticProps,
  getStaticPaths as getTeacherStaticPaths,
} from './components/templates/Teacher/Teacher.effects';
import {getStaticProps as getPageStaticProps} from './components/templates/Page/Page.effects';
import {getStaticProps as getTeachersTemplateStaticProps} from './components/templates/Teachers/Teachers.effects';
import {getStaticPaths as getPlaylistStaticPaths} from './components/templates/Playlist/Playlist.effects';
import {getStaticProps as getOnboardingStaticProps} from './components/templates/Onboarding/Onboarding.effects';
import {getStaticPaths as getOnboardingStaticPaths} from './components/templates/Onboarding/Onboarding.effects';

export const getTemplateStaticProps = {
  Browse: getBrowseStaticProps,
  Feed: getFeedStaticProps,
  Meditation: getMeditationStaticProps,
  Daily: getDailyStaticProps,
  Gratitude: getGratitudeStaticProps,
  Talk: getTalkStaticProps,
  Soundscape: getSoundscapeStaticProps,
  Music: getMusicStaticProps,
  Scenic: getScenicStaticProps,
  Special: getSpecialStaticProps,
  Breathwork: getBreathworkStaticProps,
  Story: getStoryStaticProps,
  Collection: getCollectionTemplateStaticProps,
  Teacher: getTeacherTemplateStaticProps,
  Teachers: getTeachersTemplateStaticProps,
  Onboarding: getOnboardingStaticProps,
  Page: getPageStaticProps,
  ContentCollection: getContentCollectionStaticProps,
};

export const getTemplateStaticPaths = {
  Browse: getBrowseStaticPaths,
  Feed: getFeedStaticPaths,
  Meditation: getMeditationStaticPaths,
  Daily: getDailyStaticPaths,
  Gratitude: getGratitudeStaticPaths,
  Talk: getTalkStaticPaths,
  Soundscape: getSoundscapeStaticPaths,
  Music: getMusicStaticPaths,
  Scenic: getScenicStaticPaths,
  Special: getSpecialStaticPaths,
  Breathwork: getBreathworkStaticPaths,
  Story: getStoryStaticPaths,
  Collection: getCollectionStaticPaths,
  Teacher: getTeacherStaticPaths,
  Playlist: getPlaylistStaticPaths,
  Onboarding: getOnboardingStaticPaths,
};

import {hydrate as hydratePlanPicker} from './components/sections/PlanPicker/PlanPicker.effects';
import {hydrate as hydrateHeroPlanPicker} from './components/sections/HeroPlanPicker/HeroPlanPicker.effects';

export const hydrateSection = {
  PlanPicker: hydratePlanPicker,
  HeroPlanPicker: hydrateHeroPlanPicker,
};

import {hydrate as hydrateCheckout} from './components/templates/Checkout/Checkout.effects';
import {hydrate as hydratePlay} from './components/templates/Play/Play.effects';

export const hydrateTemplate = {
  Checkout: hydrateCheckout,
  Play: hydratePlay,
};
