import {FC} from 'react';
import {Column, Row} from '../../layout';
import {Button, Alert, CardInput} from '../../forms';

export const CreditCard: FC<Props> = ({
  onSubmit,
  onCardChanged,
  errors,
  loading,
  cardChanged,
  allHaveTrial,
}) => {
  return (
    <Row as="form" onSubmit={onSubmit}>
      <Alert error={errors?.form} />
      <Column spacing={34}>
        <CardInput
          id="CardNumber"
          label="Card Number"
          onChange={onCardChanged}
        />
      </Column>
      <Column>
        <Button
          id="CheckoutWithCreditCard"
          wide
          loading={loading}
          disabled={!cardChanged}
          fullLoader
        >
          {allHaveTrial ? 'Start Free Trial' : 'Checkout with Credit Card'}
        </Button>
      </Column>
    </Row>
  );
};

type Props = {
  onSubmit: (e: React.FormEvent) => Promise<void>;
  onCardChanged: () => void;
  errors: Record<string, string>;
  loading: boolean;
  cardChanged: boolean;
  allHaveTrial: boolean;
};
