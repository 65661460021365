import * as React from 'react';
import {useRecoilState, useResetRecoilState} from 'recoil';
import {switchEnum} from '@mindfulness/utils/logic';

import {Header} from './Header';
import {defaultOnboardingSteps} from './Onboarding.effects';
import {OnboardingMain} from './Onboarding.styles';
import {Personalization} from './Personalization';
import {Plan} from './Plan';
import {ProgressBar} from './ProgressBar';
import {Stats} from './Stats';
import {Signup} from './Signup';
// import {PersonalizedPlan} from './PersonalizedPlan';
import {Question} from './Question';

import {
  Maybe,
  OnboardingConfig,
  OnboardingPersonalization,
  // OnboardingPersonalizedPlan,
  OnboardingPlan,
  OnboardingStats,
  QuestionData,
  Steps,
} from '../../../types/types';
import {
  onboardingSelectionsState,
  onboardingStepsState,
  onboardingStepState,
} from '../../../state/atoms';
import {OnClient} from '../../ui';
import {useTrackPage} from '../../../hooks';

export const Onboarding: React.FC<Props> = ({data}) => {
  useTrackPage('Onboarding', `/get-started/${data?.id}`);
  const [step, setStep] = useRecoilState(onboardingStepState);
  const resetSteps = useResetRecoilState(onboardingStepsState);
  const resetSelections = useResetRecoilState(onboardingSelectionsState);

  React.useEffect(() => {
    if ([data?.id].includes(defaultOnboardingSteps[0])) {
      // Reset any data because we are restarting the onboarding journey
      resetSteps();
      resetSelections();
    }
    if (data?.id !== undefined && data?.id !== step) {
      setStep(data.id as Steps);
    }
  }, [data]);

  return switchEnum(data?.type || 'else', {
    plan: function OnboardingPlanBlock() {
      return <Plan {...(data as OnboardingPlan)} />;
    },
    signup: function OnboardingSignupBlock() {
      return <Signup />;
    },
    else: function OnboardingBlock() {
      return (
        <>
          <OnClient>
            <ProgressBar />
          </OnClient>
          <Header type={data?.type} />
          <OnboardingMain>
            {switchEnum(data?.type || 'else', {
              question: function OnboardingQuestionBlock() {
                return <Question {...(data as QuestionData)} />;
              },
              stats: function OnboardingStatsBlock() {
                return <Stats {...(data as OnboardingStats)} />;
              },
              personalization: function OnboardingPersonalisationBlock() {
                return (
                  <Personalization {...(data as OnboardingPersonalization)} />
                );
              },
              // 'personalized-plan': function OnboardingPersonalizedPlanBlock() {
              //   return (
              //     <PersonalizedPlan {...(data as OnboardingPersonalizedPlan)} />
              //   );
              // },
              else: null,
            })}
          </OnboardingMain>
        </>
      );
    },
  });
};

type Props = {
  data: Maybe<OnboardingConfig>;
};
