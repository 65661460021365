import {useEffect, useState} from 'react';
import Cookie from 'js-cookie';
import {AttentionGrabber} from '@mindfulness/cms';
import {Maybe} from '@mindfulness/utils/maybe';
import {percentOf} from '@mindfulness/utils/math';
import {throttle} from 'lodash';

import {useModal} from './useModal';
import {useTrack} from '../components/global/SegmentProvider';

export const useAttentionModal = (
    attentionGrabber: Maybe<AttentionGrabber>,
) => {
  const [canFire, setCanFire] = useState<boolean>(
      !(Cookie.get('attention') === 'true'),
  );
  const [fired, setFired] = useState<string>('');
  const {open} = useModal('attention');
  const track = useTrack();

  useEffect(() => {
    let timeOut: Maybe<NodeJS.Timeout>;
    if (!attentionGrabber) {
      return;
    }
    const {time, scrollPercentage, trigger, enabled, title, cohort} = attentionGrabber;
    if (!enabled || !trigger) {
      return;
    }

    const handleTrigger = async (reason: string) => {
      const cookie = Cookie.get('attention') === 'true';
      if (!cookie) {
        setFired(reason);
        Cookie.set('attention', 'true', {expires: 30});
        await track('Attention modal opened', {
          title,
          cohort,
        });
      }
    };
    const handleScroll = () => {
      const scrollPercent = percentOf(
          window.scrollY + window.innerHeight,
          document.body.scrollHeight,
      );
      if (scrollPercentage && scrollPercent >= scrollPercentage) {
        handleTrigger('scroll');
      }
    };
    const throlledHandleScroll = throttle(handleScroll, 200);
    if (time && ['all', 'time'].includes(trigger)) {
      timeOut = setTimeout(() => {
        handleTrigger('time');
      }, time * 1000);
    }
    if (['all', 'pageExit'].includes(trigger)) {
      document.addEventListener('mouseout', () => handleTrigger('pageExit'));
    }
    if (scrollPercentage && ['all', 'scroll'].includes(trigger)) {
      window.addEventListener('scroll', throlledHandleScroll);
    }

    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
      document.removeEventListener('mouseout', () => handleTrigger('pageExit'));
      window.removeEventListener('scroll', throlledHandleScroll);
    };
  }, [attentionGrabber]);

  useEffect(() => {
    if (!canFire) {
      return;
    }
    if (fired && canFire) {
      open({
        props: attentionGrabber,
      });

      setCanFire(false);
    }
  }, [fired, canFire, open, attentionGrabber]);
};
