import * as React from 'react';

import {useForm, useNavigation} from '../../hooks';

import {Button, Input} from '../forms';
import {OverlayModal} from '../global';
import {Container, Row, Column} from '../layout';
import {H3} from '../typography';

export const CouponModal: React.FC = () => {
  const {addParam} = useNavigation();
  const {data, handleChange, handleSubmit, loading, errors} = useForm<{
    coupon: string;
  }>({
    initialValues: {
      coupon: '',
    },
    onSubmit: async (e, {data}) => {
      addParam('/subscribe', data);
    },
    redirectAction: true,
    validations: {
      coupon: {
        required: {
          value: true,
          message: 'Please enter your coupon address',
        },
      },
    },
  });
  return (
    <OverlayModal name="coupon" closeButton>
      <Container maxWidth="xs">
        <Row as="form" onSubmit={handleSubmit}>
          <Column>
            <H3 as="p" weight="bold" spacing={16}>
              Apply a coupon
            </H3>
          </Column>
          <Column spacing={30}>
            <Input
              label="Coupon"
              placeholder="Enter coupon"
              onChange={handleChange('coupon')}
              value={data?.coupon}
              error={errors?.coupon}
            />
          </Column>
          <Column>
            <Button id="ApplyCoupon" type="submit" wide loading={loading}>
              Apply Coupon
            </Button>
          </Column>
        </Row>
      </Container>
    </OverlayModal>
  );
};
