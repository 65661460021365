import React, {useEffect, useMemo} from 'react';
import {ApolloError} from '@apollo/client';
import {justOne} from '@mindfulness/utils/array';
import {omitEmpty} from '@mindfulness/utils/object';
import {isUndefined} from '@mindfulness/utils/maybe';

import {PlayProps, hydrate} from '../components/templates/Play/Play.effects';
import {Context, ModalContext} from '../components/global';
import {useNavigation} from './useNavigation';
import {useModal} from './useModal';

export const usePlayer = () => {
  const {session} = React.useContext(Context);
  const {openModal, isOpen} = React.useContext(ModalContext);
  const {update, router} = useNavigation();

  const [loading, setLoading] = React.useState(true);
  const [playerProps, setPlayerProps] = React.useState<PlayProps>();

  const unlockModal = useModal('unlock');

  const bundleId = useMemo(() => {
    if (!router.isReady) return;
    return justOne(router.query.b);
  }, [router.query.b, router.isReady]);
  const collectionId = useMemo(() => {
    if (!router.isReady) return;
    return justOne(router.query.col);
  }, [router.query.c, router.isReady]);
  const singleId = useMemo(() => {
    if (!router.isReady) return;
    return justOne(router.query.s);
  }, [router.query.s, router.isReady]);
  const variantId = useMemo(() => {
    if (!router.isReady) return;
    return justOne(router.query.v);
  }, [router.query.v, router.isReady]);

  const modalIsOpen = isOpen('action');
  React.useEffect(() => {
    (async () => {
      setLoading(true);
      if (!openModal || modalIsOpen) return;

      try {
        if (isUndefined(session)) {
          openModal?.({
            name: 'action',
            props: {
              title: 'Signup to access this content',
              closeButton: false,
            },
          });
          return;
        }
        const props = await hydrate({
          bundleId,
          collectionId,
          singleId,
          variantId,
        });
        setPlayerProps(props);
      } catch (err) {
        if (err instanceof ApolloError) {
          if (err.message === 'Authentication required.') {
            openModal?.({
              name: 'action',
              props: {
                title: 'Signup to access this content',
                closeButton: false,
              },
            });
            return;
          }
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [
    bundleId,
    collectionId,
    singleId,
    variantId,
    modalIsOpen,
    openModal,
    session,
  ]);

  useEffect(() => {
    if (playerProps?.single?.id && playerProps?.variant?.id) {
      setLoading(false);

      const {userData} = playerProps.single;
      if (!userData || userData?.locked) {
        unlockModal.open();
        return;
      }
    }
  }, [playerProps?.single?.id, playerProps?.variant?.id]);

  if (playerProps?.redirectSingle) {
    update(
        omitEmpty({
          b: playerProps.bundle?.id,
          col: playerProps.collection?.id,
          s: playerProps.redirectSingle.id,
          v: playerProps.variant?.id,
        }),
    );
  }

  return {loading, bundleId, collectionId, ...playerProps};
};
