import {FC, useMemo} from 'react';

import type {PurchaseFragment} from '../../../types/api';
import {AppButtonText, Small, Text} from '../../typography';
import {Box, Flex, Stack} from '../../layout';
import {BackgroundAPIImage} from '../../global';
import {formatMoney} from '../../../utils/money';
import {assertString} from '../../../utils/string';
import {RemoveButton} from './PurchaseCheckout.styled';
import {useAddToCart} from '../../../hooks/useAddToCart';
import {Divider} from '../../ui';
import {useUntil} from '../../../hooks/useUntil';

export const LineItem: FC<PurchaseFragment> = (purchase) => {
  const {title, subtitle, conditions, price, cellImage, trialDays} = purchase;
  const {removeFromCart, loading} = useAddToCart();
  const _trialDays = useUntil([price?.discount?.trialDays, trialDays]);
  const moneyDiscount = useMemo(() => {
    if (price?.discount) {
      return price?.discount.amountOff ?? price?.discount.percentOff ?? false;
    }
    if (price?.comparePriceUSD && price?.comparePriceUSD > price.priceUSD) {
      return price?.comparePriceUSD;
    }
    return false;
  }, [
    price?.discount?.amountOff,
    price?.discount?.percentOff,
    price?.comparePriceUSD,
  ]);

  const itemPrice = useMemo(() => {
    if (price?.comparePriceUSD && price?.comparePriceUSD > price.priceUSD) {
      return price?.comparePriceUSD;
    }
    const itemPrice = price?.priceUSD || 0;
    return itemPrice;
  }, [price?.priceUSD]);

  const discountPrice = useMemo(() => {
    const finalPrice =
      price?.discount?.finalPrice || price?.comparePriceUSD || 0;
    return finalPrice;
  }, [price?.discount?.finalPrice, price?.comparePriceUSD]);

  return (
    <Box
      style={{
        opacity: loading ? 0.5 : 1,
        pointerEvents: loading ? 'none' : 'auto',
      }}
    >
      <Flex wrap="nowrap" gap={10} items="start">
        <Box
          overflow="hidden"
          radius="lg"
          position="relative"
          width="64px"
          height="64px"
          zIndex={1}
          shrink={0}
        >
          <BackgroundAPIImage
            src={assertString(cellImage?.url, '/images/line-item.png')}
            doNotResize
          />
        </Box>

        <Flex
          column
          items="start"
          justify={conditions ? 'space-between' : 'center'}
          height="64px"
          grow
        >
          <Stack direction="vertical" space={5}>
            <AppButtonText>{title}</AppButtonText>
            <Small
              colour="primary"
              transform="uppercase"
              fontSize="2xs"
              fontWeight="bold"
            >
              {subtitle}
            </Small>
          </Stack>
          {conditions ? (
            <Small colour="grey7" fontSize="2xs">
              {conditions}
            </Small>
          ) : null}
        </Flex>

        <Box height="64px">
          <Stack direction="vertical" justify="space-between" items="end" wide>
            <Box textAlign="right">
              {_trialDays ? (
                <>
                  <Text colour="grey5" fontSize="sm">
                    {formatMoney(itemPrice)}
                  </Text>
                  <Text colour="highlight2" fontWeight="bold" fontSize="xs">
                    FREE TRIAL
                  </Text>
                </>
              ) : moneyDiscount !== false ? (
                <>
                  <Text colour="grey5" decoration="line-through" fontSize="sm">
                    {formatMoney(price?.comparePriceUSD || itemPrice)}
                  </Text>
                  <Text colour="highlight2">{formatMoney(discountPrice)}</Text>
                </>
              ) : (
                <Text>{formatMoney(itemPrice)}</Text>
              )}
            </Box>
            <RemoveButton
              type="button"
              onClick={async (e) => {
                e.stopPropagation();
                e.preventDefault();
                await removeFromCart(purchase);
              }}
            >
              Remove
            </RemoveButton>
          </Stack>
        </Box>
      </Flex>
      <Divider spacing={10} />
    </Box>
  );
};
