import {ContentCollection} from '@mindfulness/cms';
import {Maybe} from '@mindfulness/utils/maybe';
import {getTagCollection} from '../../../api';
import {CollectionFeedFragment} from '../../../types/api';

export const getStaticProps = async (
    section: ContentCollection,
): Promise<Maybe<{ apiCollection: Maybe<CollectionFeedFragment> }>> => {
  try {
    if (!section.collection?.id) return;
    const collection = await getTagCollection({
      id: section.collection.id,
      withUserData: false,
      resultsTake: 100,
    });
    return {apiCollection: collection};
  } catch (e) {
    console.error(`Failed to getStaticProps in ContentCollection`, e, section);
  }
};

