import {TagFeedFragment} from '../../../types/api';
import {getRelatedFeeds, getTagFeed} from '../../../api';
import {Maybe, RelatedFeeds} from '../../../types/types';

const mapRelatedFeeds = async ({
  slugs = [],
  feedPrefix,
}: {
  slugs: RelatedFeeds;
  feedPrefix: string;
}): Promise<Array<TagFeedFragment>> =>
  Promise.all(
      slugs
          .map(async ({slug, title}: { slug: string; title: string }) => {
            const tagFeed = await getTagFeed({
              id: `${feedPrefix}.${slug}`,
              withUserData: false,
              resultsTake: 10,
            });

            return {
              ...tagFeed,
              title,
            };
          })
          .filter(Boolean),
  ) as Promise<Array<TagFeedFragment>>;

const getFeeds = async (props: Params): Promise<Maybe<APIProps>> => {
  try {
    const feedPrefix = props.customFeed ?
      `${props.customFeed}` :
      `t-${props.topicId}`;
    const id = `${feedPrefix}.${props.feed}`;

    const relatedFeedSlugs = await getRelatedFeeds(id);
    const relatedFeeds = await mapRelatedFeeds({
      slugs: relatedFeedSlugs,
      feedPrefix,
    });
    const viableRelatedFeeds = relatedFeeds.filter(
        (feed) => feed?.collections.length,
    );
    const matchingFeed = viableRelatedFeeds.find((f) => f.id === id)?.id;
    const firstFeed = viableRelatedFeeds[0]?.id;

    const tagFeed = await getTagFeed({
      id: matchingFeed || firstFeed || id,
      withUserData: false,
      resultsTake: 10,
    });

    return {
      tagFeed,
      relatedFeeds: viableRelatedFeeds,
    };
  } catch (e) {
    console.error('Failed to get feeds', e);
  }
};

export const getStaticProps = getFeeds;

export const getStaticPaths = async (id: string) => {
  const feeds = await getRelatedFeeds(id);
  return feeds?.filter(({id}) => id.split('.')[1] !== 'featured');
};
type Params = {
  customFeed: string;
  topicId: string;
  feed: string;
};
export type APIProps = {
  tagFeed: Maybe<TagFeedFragment | null>;
  relatedFeeds: Array<TagFeedFragment>;
};
