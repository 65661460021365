import * as React from 'react';

import {EnrichedPages} from '../../../types/types';
import {Sections} from '../../global';

import {
  useNavigation,
  usePageTimeTracking,
  useScrollTracking,
  useTrackPage,
} from '../../../hooks';
import {useAttentionModal} from '../../../hooks/useAttentionModal';
import {assertString, defaultLanderThankyou, onClient, unNull} from '../../../utils';
import {MobileBanner} from '../../ui/MobileBanner';
import {useTrack} from '../../../components/global/SegmentProvider';

export const Page: React.FC<EnrichedPages> = ({
  sections,
  action,
  attentionGrabber,
  title,
  type,
  _id,
}) => {
  const {router} = useNavigation();
  const track = useTrack();
  useAttentionModal(attentionGrabber);
  useScrollTracking();
  usePageTimeTracking();
  useTrackPage(assertString(title, 'Page'), _id, {
    action: action?.action,
    page_type: type,
  });

  React.useEffect(() => {
    onClient(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const productID = urlParams.get('product');
      if (productID && productID !== 'true') {
        track('QR code scanned', {
          productId: unNull(urlParams.get('product')),
          path: window.location.pathname,
        });
      }
    });
  }, []);

  // Get the thank you page ready
  React.useEffect(() => {
    if (!router.isReady) return;
    const thankYou = action?.thankYouLink || action?.thankYouPage?.current || defaultLanderThankyou();
    // Only prefetch local routes
    if (thankYou.startsWith('/')) {
      router.prefetch(thankYou);
    }
  }, [router.isReady, action?.thankYouPage?.current, action?.thankYouLink]);

  return (
    <>
      <Sections sections={sections} />
      {type === 'bundle' && action?.bundle ? (
        <MobileBanner deeplink={`mcom://bundle/${action.bundle.id}/play`} />
      ) : null}
    </>
  );
};
