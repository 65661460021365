import {Maybe, when} from '@mindfulness/utils/maybe';
import {getBundle, getPickerPlans} from '../../../api';
import {EnrichedPages} from '../../../types/types';
import {getPageCheckoutType} from '../../../utils';
import {BundleDetailFragment} from '../../../types/api';

const getPageBundle = async (
    bundle: EnrichedPages['bundle'],
): Promise<Maybe<BundleDetailFragment>> => {
  if (!bundle?.id) return;

  const pageBundle = await getBundle({
    id: bundle.id,
    withUserData: false,
    resultsTake: 0,
  });

  return pageBundle;
};

export const getStaticProps = async (
    page: EnrichedPages,
): Promise<EnrichedPages> => {
  const apiBundle = await getPageBundle(page?.bundle);
  try {
    if (!page?.action?.plans) {
      return {
        ...page,
        action: when(page.action, (a) => ({
          ...a,
          plans: undefined,
        })),
        apiBundle,
      };
    }
    const {action, plans, coupon} = page.action;
    const type = getPageCheckoutType(action);

    const res = await getPickerPlans({
      type,
      // @ts-ignore
      plans,
      coupon,
    });
    if (!res?.enrichedPlans) {
      throw new Error('No plans returned in Page.effects');
    }
    return {
      ...page,
      action: {
        ...page.action,
        plans: res.enrichedPlans,
      },
      apiBundle,
    };
  } catch (e) {
    console.error(`Failed to enrich action in Page`, e, page.action);
    return {
      ...page,
      action: when(page.action, (a) => ({
        ...a,
        plans: undefined,
      })),
      apiBundle,
    };
  }
};
