import styled from '@emotion/styled';

export const Header = styled.header(
    ({theme}) => `
  padding-top: ${theme.spacings[20]};
  padding-bottom: ${theme.spacings[20]};

  @media (min-width: ${theme.breakpoints.md.min}) {
    padding-top: ${theme.spacings[72]};
    padding-bottom: ${theme.spacings[72]};
  }
`,
);
