import {SingleHighlight} from '@mindfulness/cms';

import {getSingleById} from '../../../api';
import {SingleDetailFragment} from '../../../types/types';


// TODO, apply the async error handler and log more information about the single.
export const getStaticProps = async (
    section: SingleHighlight,
): Promise<{richSingle: SingleDetailFragment} | null> => {
  if (!section.single?.id) return null;

  try {
    const single = await getSingleById({
      id: section.single.id,
      withUserData: false,
      resultsTake: 5,
    });
    return {richSingle: single};
  } catch (e) {
    console.error(
        e,
        `Failed to getStaticProps in SingleHighlight for ${section.single.id}`,
    );
    return null;
  }
};
