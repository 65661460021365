import * as React from 'react';
import Head from 'next/head';
import {CollectionTemplate} from '@mindfulness/cms';

import {CollectionsList} from '../../ui';
import {Sections} from '../../global';
import {CollectionFeedFragment} from '../../../types/api';
import {MobileBanner} from '../../ui/MobileBanner';

export const Collection: React.FC<Props> = ({collection, template}) => {
  return collection ? (
    <>
      <CollectionsList location="collection" collections={[collection]} feedId={undefined} />
      {template?.sections ? (
        <Sections sections={template.sections} indexOffset={1} />
      ) : null}
      <MobileBanner deeplink={`mcom://collection/${collection.id}`} />
      <Head>
        <title>{`${collection.title} | Mindfulness.com`}</title>
      </Head>
    </>
  ) : null;
};

type Props = {
  collection: CollectionFeedFragment;
  template?: CollectionTemplate;
};
