import {gql} from '@apollo/client';
import {OrderFragment} from '../../api/fragments';

export const MUTATE_CHECKOUT = gql`
  mutation checkout($order: ID!, $stripe: StripeSubscriptionInput!) {
    checkout(order: $order, stripe: $stripe) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;
