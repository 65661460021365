import {useContext} from 'react';
import {isUndefined} from 'lodash';
import {useSetRecoilState} from 'recoil';

import {Alert} from '../../forms/Alert';
import {useForm} from '../../../hooks/useForm';
import {Box, Column, Container, Row} from '../../layout';
import {Small, Text, TitleLarge} from '../../typography';
import {Button} from '../../forms';
import {InlineForm, Input} from '../InlineActionForm/InlineActionForm.styles';
import {Context} from '../../global/Provider';
import {useNavigation} from '../../../hooks/useNavigation';
import {assertString} from '../../../utils/string';
import {redirectState} from '../../../state/atoms';
import {Section} from '../../layouts/Section';

export const SubscribeForm: React.FC<Props> = () => {
  const {session} = useContext(Context);
  const {goTo, router} = useNavigation();
  const setRedirect = useSetRecoilState(redirectState);
  const {data, handleChange, handleSubmit, loading, errors} = useForm<{
    email: string;
  }>({
    initialValues: {
      email: assertString(session?.email, ''),
    },
    redirectAction: true,
    onSubmit: async (e, {data}) => {
      setRedirect(router.asPath);
      goTo({
        pathname: '/checkout',
        query: {
          products: 'b47397fb-38be-4865-9130-c13203fe4494',
          email: data?.email,
          view: 'signup',
        },
      });
    },
    validations: {
      email: {
        required: {
          value: true,
          message: 'Please enter your email address',
        },
      },
    },
  });

  return isUndefined(session) ||
    (session && !session.user?.hasActiveSubscription) ? (
    <Section background="greyWarm">
      <Container>
        <Row>
          <Column
            span={{
              xs: 24,
              md: 8,
            }}
          >
            <TitleLarge as="p">Get Free Access</TitleLarge>
            <Text colour="grey7">
              Start a 7-day free trial to access the entire library.
            </Text>
          </Column>
          <Column
            span={{
              xs: 24,
              md: 16,
            }}
            as="form"
            onSubmit={handleSubmit}
          >
            <Alert error={errors?.form} />
            <Box>
              <InlineForm>
                <Input
                  type="email"
                  placeholder="Enter your email address"
                  value={data?.email}
                  onChange={handleChange('email')}
                />

                <Button
                  id="SubscribeSubmit"
                  type="submit"
                  size="md"
                  loading={loading}
                >
                  {loading ? 'Loading' : 'Get Access'}
                </Button>
              </InlineForm>
              {errors?.email ? (
                <Small colour="accent">{errors?.email}</Small>
              ) : null}
            </Box>
          </Column>
        </Row>
      </Container>
    </Section>
  ) : null;
};

type Props = {};
