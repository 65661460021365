import * as React from 'react';
import {useEffect} from 'react';
import * as Sentry from '@sentry/nextjs';
import Cookies from 'js-cookie';
import {BrowseTemplate} from '@mindfulness/cms';
import dynamic from 'next/dynamic';

import {H4, TitleLarge} from '../../typography';
import {Container, Section} from '../../layout';
import {CollectionsList, PillList} from '../../ui';

import {APIProps} from './Feed.effects';
import {filterEmptyFeeds} from '../../../utils/version';
import {useHydratedFeed} from '../../../hooks/useHydratedFeed';
import {Context} from '../../global/Provider';
import {useDailyQuote} from '../../../hooks/useDailyQuote';
import {ErrorFallback} from '../../global/ErrorFallback';
import {onClient} from '../../../utils/next';
import {clearSession} from '../../../utils/auth';
import {useTrackPage} from '../../../hooks/useTrackPage';
import {Text} from '../../typography';
import {MobileBanner} from '../../ui/MobileBanner';
import {defaultHomeFeed} from '../../../utils';

const Sections = dynamic(() =>
  import('../../global/Sections/index').then(({Sections}) => Sections),
);

export const Feed: React.FC<Props> = ({feed: staticFeed, template, noTitle}) => {
  const {feed, ref} = useHydratedFeed(staticFeed);
  const {session} = React.useContext(Context);
  const {quote: dailyQuote} = useDailyQuote();
  useTrackPage('Feed', staticFeed?.tagFeed?.id || '');
  useEffect(() => {
    onClient(() => {
      if (
        staticFeed?.tagFeed?.id === 'web-home' &&
        typeof session === 'undefined'
      ) {
        if (Cookies.get('auth_token')) {
          clearSession();
        }
        window.location.href = '/';
      }
    });
  }, [session]);

  const pillItems = React.useMemo(() => {
    const feeds = filterEmptyFeeds(feed?.relatedFeeds);
    return feeds?.map((f) => {
      if (!feed) return;
      const {id, title} = f;
      const url = '/feed/' + id;

      return {
        url,
        label: title,
        active: feed.tagFeed?.id === id,
      };
    });
  }, [feed?.relatedFeeds]);

  const title = React.useMemo(() => {
    if (feed?.tagFeed?.id === defaultHomeFeed() && session) {
      if (!session.user.firstName) {
        return 'Welcome';
      }
      return `Hello, ${session.user.firstName}!`;
    }
    return feed?.tagFeed?.title;
  }, [feed?.tagFeed?.title, session]);

  const description = React.useMemo(() => {
    if (feed?.tagFeed?.id === defaultHomeFeed() && dailyQuote) {
      return `${dailyQuote.text} - ${dailyQuote.attribution}`;
    }
    return feed?.tagFeed?.description;
  }, [feed?.tagFeed?.description, feed?.tagFeed?.title, dailyQuote]);

  return (
    <Sentry.ErrorBoundary
      fallback={(props) => (
        <ErrorFallback {...props} index={0} sectionType={'Feed'} />
      )}
    >
      {noTitle ? null : (
        <Section
          ref={ref}
          background="white"
          sectionPadding={{
            paddingTop: 80,
            paddingBottom: 0,
          }}
        >
          <Container spacing={30}>
            <TitleLarge spacing={12}>{title}</TitleLarge>
            {<H4 colour="grey7">{description || ' '}</H4>}
          </Container>
        </Section>
      )}
      {pillItems ? <PillList items={pillItems} /> : null}
      {feed?.tagFeed?.collections ? (
        <CollectionsList
          collections={feed.tagFeed.collections || []}
          feedId={feed.tagFeed.id}
          priority
        />
      ) : (
        <Container>
          <TitleLarge spacing={12}>No collections found</TitleLarge>
          <Text>
            {`Sorry, we couldn't find any collections for this feed.`}
          </Text>
        </Container>
      )}
      {template?.sections ? (
        <Sections sections={template.sections} indexOffset={1} />
      ) : null}
      {feed?.tagFeed ? (
        <MobileBanner deeplink={feed.tagFeed.id === defaultHomeFeed() ? 'mcom://home' : `mcom://feed/${feed.tagFeed.id}`} />
      ) : null}
    </Sentry.ErrorBoundary>
  );
};

type Props = {
  feed?: APIProps;
  slug?: string;
  template: BrowseTemplate;
  noTitle?: boolean;
};
