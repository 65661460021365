import * as React from 'react';
import {useEffect} from 'react';
import Head from 'next/head';
import {useRecoilValue} from 'recoil';
import dynamic from 'next/dynamic';
import {isDefined} from '@mindfulness/utils/maybe';
import {inPast} from '@mindfulness/utils/time';

import {
  BundleDetailFragment,
  CollectionFeedFragment,
} from '../../../types/api';
import {LoadingSpinner} from '../../ui';
import {Box, Flex} from '../../layout';
import {usePlayer} from '../../../hooks/usePlayer';
import {useIsDefined} from '../../../hooks/useIsDefined';

import {PlayProps} from './Play.effects';
import {BundlePlayerContainer, BundlePlayerWrapper} from './Play.styles';
import {historyState, playerPlayState} from '../../../state/atoms';
import {PlayerSidebar} from './PlayerSidebar';
import {withOnClient} from '../../../hoc/withOnClient';
import {useWindowInnerHeight} from '../../../hooks/useWindowInnerHeight';
import {useNavigation} from '../../../hooks/useNavigation';
import {ButtonLink} from '../../global/ButtonLink';
import {onClient} from '../../../utils/next';
import {unNull} from '../../../utils/maybe';
import {videoSingleSchema} from '../../../schemas';
import {useTrackPage} from '../../../hooks';
import {useTrack} from '../../global/SegmentProvider';
import {MobileBanner} from '../../ui/MobileBanner';

const SinglePlayer = dynamic(() =>
  import('../../ui/SinglePlayer/index').then(({SinglePlayer}) => SinglePlayer),
);

const getSingleId = (
    item:
    | BundleDetailFragment['collections'][0]['results']['items'][0]
    | CollectionFeedFragment['results']['items'][0],
) => {
  return item.__typename === 'Single' ? item.id : undefined;
};

export const Play: React.FC<PlayProps> = withOnClient(() => {
  const status = useRecoilValue(playerPlayState);
  const track = useTrack();
  useWindowInnerHeight();
  useTrackPage('Play', 'play');

  const {
    bundle,
    bundleId,
    collection,
    collectionId,
    single,
    variant,
    loading,
  } = usePlayer();
  const isProduct = React.useMemo(() => {
    return !!onClient(() => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.has('product');
    });
  }, []);
  useEffect(() => {
    onClient(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const productID = urlParams.get('product');
      if (productID && productID !== 'true') {
        track('QR code scanned', {
          productId: unNull(urlParams.get('product')),
          path: window.location.pathname,
        });
      }
    });
  }, []);

  const nextAndPreviousSingles = React.useMemo(() => {
    if (collection) {
      const ids = collection.results.items
          .filter((i) => {
            if (i.__typename === 'Single') {
              return !isDefined(i.releaseDate) || inPast(new Date(i.releaseDate));
            }
            return false;
          })
          .map(getSingleId);
      const currentIndex = ids.indexOf(single?.id);
      return {
        prev: ids[currentIndex - 1],
        next: ids[currentIndex + 1],
        collectionNext: undefined,
        collectionPrev: undefined,
      };
    }
    if (!bundle) {
      return;
    }
    const currentCollection = bundle?.collections.find((collection) => {
      const ids = collection.results.items.map(getSingleId);
      return ids.includes(single?.id);
    });

    const currentCollectionIds =
      currentCollection?.results.items
          .filter((i) => {
            if (i.__typename === 'Single') {
              return !isDefined(i.releaseDate) || inPast(new Date(i.releaseDate));
            }
            return false;
          })
          .map(getSingleId) || [];
    const ids = bundle.collections.flatMap((collection) => {
      return collection.results.items
          .filter((i) => {
            if (i.__typename === 'Single') {
              return !isDefined(i.releaseDate) || inPast(new Date(i.releaseDate));
            }
            return false;
          })
          .map(getSingleId);
    });
    const currentIndex = ids.indexOf(single?.id);
    const collectionCurrentIndex = currentCollectionIds?.indexOf(single?.id);
    return {
      prev: ids[currentIndex - 1],
      next: ids[currentIndex + 1],
      collectionPrev: currentCollectionIds[collectionCurrentIndex - 1],
      collectionNext: currentCollectionIds[collectionCurrentIndex + 1],
    };
  }, [bundle, collection, single]);

  const hasBundle = useIsDefined(bundle);
  const hasCollection = useIsDefined(collection);
  const hasSidebar = hasBundle || hasCollection;

  return (
    <BundlePlayerContainer>

      <Head>
        <title>
          {bundle ? bundle.title : collection ? collection.title : ''}{' '}
          {single ? single.title : 'Play'} | Mindfulness.com
        </title>
        {single ? (<script {...videoSingleSchema({single})} />) : null}
        <link rel="canonical" href={`https://mindfulness.com/play?${[
          ...bundleId ? [`b=${bundleId}`] : [],
          ...single?.id ? [`s=${single.id}`] : [],
          ...variant?.id ? [`v=${variant.id}`] : [],
        ].join('&')}`} />
      </Head>
      <BackButton />
      <BundlePlayerWrapper hasSidebar={hasSidebar} status={status}>
        {loading || !(variant && single) ? (
          <Flex items="center" justify="center" height="100%">
            <LoadingSpinner />
          </Flex>
        ) : variant && single ? (
          <SinglePlayer
            bundleId={bundle?.id}
            frame={false}
            single={single}
            variant={variant}
            nextAndPreviousSingles={nextAndPreviousSingles}
          />
        ) : (
          <Flex items="center" justify="center" height="100%">
            <LoadingSpinner />
          </Flex>
        )}
      </BundlePlayerWrapper>

      {bundle || bundleId || collection || collectionId ? (
        <PlayerSidebar
          bundle={bundle}
          collection={collection}
          single={single}
        />
      ) : null}
      {!isProduct && single?.id && variant?.id ? (
        <MobileBanner deeplink={bundleId ? `mcom://bundle/${bundleId}/single/${single?.id}/play?variant=${variant?.id}` : `mcom://single/${single?.id}/${variant?.id}/play`} />
      ) : null}

    </BundlePlayerContainer>
  );
});

const BackButton: React.FC = () => {
  const history = useRecoilValue(historyState);
  const {router} = useNavigation();

  return (
    <Box position="fixed" top="30px" left="30px" zIndex={30}>
      <ButtonLink
        variant="secondary"
        size={{
          xs: 'sm',
          md: 'lg',
        }}
        id="PlayerBack"
        href={history && history !== router.asPath ? history : '/'}
      >
        Back
      </ButtonLink>
    </Box>
  );
};
