
import {GetFinalizedOrdersQuery} from '../../types/api';
import {GET_FINALIZED_ORDERS} from '../../api/queries/getFinalizedOrders';
import {getGraphClient} from '../../utils/graph';


export const getFinalizedOrders = async () => {
  const {data} = await getGraphClient().query<GetFinalizedOrdersQuery>({
    query: GET_FINALIZED_ORDERS,
  });
  return data?.orders;
};
