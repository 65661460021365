import {getGraphClient} from '../../utils/graph';
import {GetDailyQuoteQuery} from '../../types/api';
import {GET_DAILY_QUOTE} from '../queries/getDailyQuote';

export const getDailyQuote = async () => {
  const {data} = await getGraphClient().query<GetDailyQuoteQuery>({
    query: GET_DAILY_QUOTE,
  });
  return data.todaysQuote;
};
