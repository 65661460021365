import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {useRouter} from 'next/router';
import {captureException} from '@sentry/nextjs';

import {orderIdState, orderItemsState} from '../state/atoms';
import {getOrder} from '../api';
import {GetOrderQuery, PurchaseFragment} from '../types/api';
import {Maybe} from '../types/types';
import {useQueryParam} from './useQueryParam';
import {updateOrder} from '../api/functions/updateOrder';
import {DiscountContext} from '../components/global';
import {assertString} from '../utils/string';
import {useAddToCart} from './useAddToCart';
import {useTrack} from '../components/global/SegmentProvider';

export const usePurchaseCheckout = () => {
  const router = useRouter();
  const isAdding = useRef<boolean>(false);
  const track = useTrack();
  const {handleInvalidCoupon} = useContext(DiscountContext);
  const orderId = useRecoilValue(orderIdState);
  const [itemIds, setOrderItems] = useRecoilState(orderItemsState);

  const [orderIdParam] = useQueryParam('order');
  const [productsParam, setProductsParam] = useQueryParam('products');
  const [couponParam, setCouponParam] = useQueryParam('coupon');
  const [cohortParam, setCohortParam] = useQueryParam('cohort');
  const id = orderId || orderIdParam;
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Maybe<string>>();
  const [order, setOrder] = useState<GetOrderQuery['order']>();
  const {addToCart} = useAddToCart();

  useEffect(() => {
    // Don't fetch if we already have the order
    // But do fetch if an item has been removed from the order
    const orderIds = order?.purchasables?.map((p) => p.id) || [];
    if (order?.purchasables?.length === itemIds.length) {
      if (orderIds.every((id) => itemIds.includes(id))) {
        setLoading(false);
        return;
      }
    }

    if (!id) {
      setLoading(false);
      return;
    }

    setLoading(true);

    (async () => {
      try {
        const res = await getOrder({order: id});
        setOrderItems(res?.purchasables?.map(({id}) => id) || []);

        setOrder(res);

        await track('Checkout started', {
          order_id: id,
          purchasables:
            res?.purchasables?.map((p) => assertString(p.title)) || [],
          checkout_url: router.asPath,
        });
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [id, order, orderId, itemIds, order?.purchasables.length]);

  useEffect(() => {
    if (productsParam) {
      (async () => {
        try {
          if (isAdding.current) return;
          isAdding.current = true;
          const products = productsParam.split(',');
          for (const purchasable of products) {
            await addToCart(
              {
                id: purchasable,
              } as PurchaseFragment,
              {
                ...(couponParam ? {coupon: couponParam} : {}),
                metadata: {
                  ...(cohortParam ? {cohort: cohortParam} : {}),
                },
              },
            );
          }
        } catch {
          captureException(new Error('Failed to add product to cart'));
        } finally {
          setProductsParam(undefined);
          setCouponParam(undefined);
          setCohortParam(undefined);
          isAdding.current = false;
        }
      })();
      return;
    }
    if (couponParam) {
      (async () => {
        try {
          if (isAdding.current) return;
          isAdding.current = true;
          await handleCoupon(couponParam);
        } catch {
          captureException(new Error('Failed to add coupon to cart'));
        } finally {
          setCouponParam(undefined);
          isAdding.current = false;
        }
      })();
    }
  }, [productsParam, couponParam, cohortParam, isAdding.current]);

  const handleCoupon = useCallback(
      async (c: Maybe<string>) => {
        setError(undefined);
        handleInvalidCoupon?.(false);

        setLoading(true);
        if (!id) {
          setLoading(false);
          return;
        }
        try {
          const res = await updateOrder({
            order: id,
            changes: {
              coupon: c || '',
            },
          });
          setOrder(res);
        } catch (err) {
          if (err instanceof Error) {
            handleInvalidCoupon?.(true);
          }
        } finally {
          setLoading(false);
        }
      },
      [handleInvalidCoupon],
  );

  const items = order?.purchasables || [];

  return {
    loading,
    coupon: order?.coupon || undefined,
    items,
    error,
    order,
    setOrder,
    handleCoupon,
  };
};
