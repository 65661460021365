import * as React from 'react';
import Head from 'next/head';
import {Figure, Teachers, TeacherTemplate} from '@mindfulness/cms';
import dynamic from 'next/dynamic';
import Image from 'next/image';

import {Maybe, ProducerFragment, TagFeedFragment} from '../../../types/types';
import {APIImage, BackgroundImage} from '../../global';
import {Column, Container, Row, Stack, Section} from '../../layout';
import {H2, H4, Small, Text, TitleLarge} from '../../typography';
import {Breadcrumb, PillList} from '../../ui';
import {producerSchema} from '../../../schemas';
import {SubscribeForm} from '../../ui/SubscribeForm/SubscribeForm';

const CollectionsList = dynamic(() =>
  import('../../ui/CollectionsList/index').then(
      ({CollectionsList}) => CollectionsList,
  ));

const Sections = dynamic(() =>
  import('../../global/Sections/index').then(({Sections}) => Sections),
);

export const Teacher: React.FC<Props> = (props) => {
  const {producer, feed, template, relatedFeeds, additionalInfo} = props;
  const pillItems = React.useMemo(
      () =>
        relatedFeeds
            .filter(({collections}) =>
              Boolean(
                  collections.find(({results}) =>
                    results.items.find(({__typename}) =>
                      ['Single', 'Course', 'Bundle'].includes(__typename || ''),
                    ),
                  ),
              ),
            )
            .map(({id, title}) => {
              const slug = id.split('.')[1];
              const url = `${producer.webSlug}${
            slug === 'featured' ? '' : `/browse/${slug}`
              }`;
              return {
                url,
                label: title,
                active: id === feed.id,
              };
            }),
      [relatedFeeds, producer, feed],
  );
  const highProfile = Boolean(additionalInfo?.image);

  const socialLinks =
    additionalInfo?.sameAs?.filter((s) => {
      if (
        s.includes('https://facebook.com') ||
        s.includes('https://instagram.com') ||
        s.includes('https://twitter.com') ||
        s.includes('https://youtube.com') ||
        s.includes('https://linkedin.com')
      ) {
        return true;
      }
    }) || [];

  return (
    <>
      {highProfile ? (
        <HighProfileHeader {...props} socialLinks={socialLinks} />
      ) : (
        <LowProfileHeader {...props} socialLinks={socialLinks} />
      )}
      <SubscribeForm />

      <Section
        sectionPadding={{
          paddingTop: 60,
          paddingBottom: 0,
        }}
        background="white"
      >
        <Container>
          <Row>
            <Column>
              <TitleLarge spacing={16}>{`Library`}</TitleLarge>
              <H4 colour="grey7" spacing={16}>
                {feed.description}
              </H4>
            </Column>
          </Row>
        </Container>
      </Section>
      <PillList items={pillItems} />

      <CollectionsList collections={feed.collections} feedId={feed.id} noLinks />
      {template?.sections ? (
        <Sections sections={template.sections} indexOffset={1} />
      ) : null}
      <Head>
        <script {...producerSchema(producer, additionalInfo)} />
        <meta property="og:type" content="profile" />
        <meta property="profile:first_name" content={producer.shortName} />
        <meta
          property="profile:last_name"
          content={producer.fullName.replace(`${producer.shortName} `, '')}
        />
      </Head>
    </>
  );
};

const HighProfileHeader: React.FC<
  Props & {
    socialLinks: Array<string>;
  }
> = ({producer, feed, socialLinks, additionalInfo}) => {
  const browseTitle = React.useMemo(
      () => feed.relatedFeeds?.find(({id}) => id === feed.id)?.title,
      [feed],
  );
  return (
    <Section
      position="relative"
      sectionPadding={{
        paddingTop: 120,
        paddingBottom: 120,
      }}
    >
      {additionalInfo?.image ? (
        <BackgroundImage priority image={{image: additionalInfo.image} as Figure} />
      ) : null}
      <Container maxWidth="lg">
        <Row items="center">
          <Column
            span={{
              xs: 24,
              md: 9,
            }}
            color="white"
          >
            <Breadcrumb
              colour="white"
              items={[
                {title: 'Teachers', link: '/teachers'},
                {
                  title: producer.fullName,
                  link: producer.webSlug || undefined,
                },
                ...(browseTitle && browseTitle !== 'Featured' ?
                  [{title: browseTitle}] :
                  []),
              ]}
            />
            <Small spacing={10} fontWeight="bold" transform="uppercase">
              Teacher
            </Small>

            {producer.fullName ? (
              <H2 as="h1" spacing={10}>
                {producer.fullName}
              </H2>
            ) : null}
            {producer.blurb ? (
              <Text fontSize="md" spacing={30}>
                {producer.blurb}
              </Text>
            ) : null}
            <SocialLinks socialLinks={socialLinks} />
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

const LowProfileHeader: React.FC<Props & { socialLinks: Array<string> }> = ({
  producer,
  socialLinks,
}) => {
  return (
    <Section
      background="greyWarm"
      sectionPadding={{
        paddingTop: 60,
        paddingBottom: 60,
      }}
    >
      <Container>
        <Row justify="space-evenly" items="center">
          <Column
            span={{
              xs: 24,
              md: 9,
            }}
            spacing={{
              xs: 24,
              sm: 40,
              md: 0,
            }}
          >
            {producer.cellImage?.url ? (
              <APIImage
                url={producer.cellImage.url}
                background="greyWarm"
                ratio="13:15"
                alt={producer.fullName}
                loadingBackground
                priority
              />
            ) : null}
          </Column>
          <Column
            span={{
              xs: 24,
              md: 9,
            }}
          >
            <Small
              spacing={10}
              colour="primary"
              fontWeight="bold"
              transform="uppercase"
            >
              Teacher
            </Small>

            {producer.fullName ? (
              <H2 as="h1" spacing={10}>
                {producer.fullName}
              </H2>
            ) : null}
            {producer.blurb ? (
              <Text fontSize="md" spacing={30} colour="grey9">
                {producer.blurb}
              </Text>
            ) : null}
            {}
            {(socialLinks.length || 0) > 0 ? (
              <SocialLinks theme="dark" socialLinks={socialLinks} />
            ) : null}
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

const Link: React.FC<{
  name: string;
  link: string;
  theme?: 'light' | 'dark';
}> = ({name, link, theme = 'light'}) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <Image
        alt={name}
        src={
          theme === 'dark' ?
            `/images/${name}.png` :
            `/images/icon-${name}-circle-light.png`
        }
        width={25}
        height={25}
      />
    </a>
  );
};

const SocialLinks: React.FC<{
  socialLinks: string[];
  theme?: 'light' | 'dark';
}> = ({socialLinks, theme}) => {
  return (
    <>
      <Text fontSize="md" fontWeight="bold" spacing={12}>
        Social
      </Text>
      <Stack space={10}>
        {socialLinks.map((s) => {
          if (s.includes('https://facebook.com')) {
            return <Link name="facebook" link={s} key={s} theme={theme} />;
          }
          if (s.includes('https://instagram.com')) {
            return <Link name="instagram" link={s} key={s} theme={theme} />;
          }
          if (s.includes('https://twitter.com')) {
            return <Link name="twitter" link={s} key={s} theme={theme} />;
          }
          if (s.includes('https://youtube.com')) {
            return <Link name="youtube" link={s} key={s} theme={theme} />;
          }
          if (s.includes('https://linkedin.com')) {
            return <Link name="linkedin" link={s} key={s} theme={theme} />;
          }
        })}
      </Stack>
    </>
  );
};

type Props = {
  producer: ProducerFragment;
  feed: TagFeedFragment;
  template: TeacherTemplate;
  relatedFeeds: TagFeedFragment[];
  additionalInfo: Maybe<Teachers>;
  browseSlug?: string;
};
