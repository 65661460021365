import {getSinglePageData, getSinglesSlugs} from '../../../api';
import {SingleType} from '../../../types/api';

export const getStaticProps = async ({slug}: { slug: string }) => {
  return await getSinglePageData({
    slug,
    prefix: 'special-release',
  });
};

export const getStaticPaths = async (): Promise<
  Array<{ webSlug?: string | null | undefined }>
> => {
  const slugs = await getSinglesSlugs({type: SingleType.Special});
  return slugs;
};
