import * as React from 'react';
import {useSetRecoilState, useRecoilValue} from 'recoil';

import {onboardingStepState} from '../../../state/atoms';
import {OnboardingStats, QuestionData} from '../../../types/types';
import {
  onboardingNextStep,
  onboardingStepValues,
} from '../../../state/selectors';
import {LoadingSpinner} from '../../ui';
import {debug} from '../../../utils/logging';

import {
  ContinueButtonContainer,
  ContinueButton as Button,
} from './Onboarding.styles';
import {useRouter} from 'next/router';

export const ContinueButton: React.FC<
  QuestionData & { handlePageChange: () => void; loading: boolean }
> = ({minRequired, id, maxAllowed, handlePageChange, loading}) => {
  const selectedItems = useRecoilValue(onboardingStepValues(id));
  const nextStep = useRecoilValue(onboardingNextStep);
  const {prefetch} = useRouter();

  const onlyOne = React.useMemo(
      () => minRequired === 1 && maxAllowed === 1,
      [minRequired, maxAllowed],
  );

  React.useEffect(() => {
    debug('nextStep', nextStep);
    prefetch(nextStep);
  }, [nextStep]);

  return onlyOne ? null : (
    <ContinueButtonContainer>
      {selectedItems.length >= minRequired ? (
        <Button loading={loading} onClick={handlePageChange}>
          {loading ? <LoadingSpinner /> : 'Continue'}
        </Button>
      ) : null}
    </ContinueButtonContainer>
  );
};

export const StatsContinueButton: React.FC<OnboardingStats> = ({id}) => {
  const [loading, setLoading] = React.useState(false);
  const setStep = useSetRecoilState(onboardingStepState);
  const nextStep = useRecoilValue(onboardingNextStep);
  const {prefetch} = useRouter();

  React.useEffect(() => {
    debug('nextStep', nextStep);
    prefetch(nextStep);
  }, [nextStep]);

  React.useEffect(() => {
    setLoading(false);
  }, [id]);

  return (
    <ContinueButtonContainer>
      <Button
        loading={loading}
        onClick={() => {
          setStep(nextStep);
          setLoading(true);
        }}
      >
        {loading ? <LoadingSpinner /> : 'Continue'}
      </Button>
    </ContinueButtonContainer>
  );
};
