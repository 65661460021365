import * as React from 'react';
import {MeditationTemplate} from '@mindfulness/cms';

import {SinglePageData} from '../../../types/types';
import {SingleLayout} from '../../ui';

export const Meditation: React.FC<Props> = (props) => {
  return <SingleLayout {...props} />;
};

type Props = SinglePageData<MeditationTemplate>;
