import {Collection as CollectionProps} from '@mindfulness/cms';
import {getTagCollection} from '../../../api';
import {CollectionFeedFragment, Maybe} from '../../../types/api';

export const getStaticProps = async (
    section: CollectionProps,
): Promise<Maybe<CollectionFeedFragment>> => {
  if (!section.collection?.id) {
    throw new Error('Collection not provided in getStaticProps for Collection');
  }
  return await getTagCollection({
    id: section.collection.id,
    withUserData: false,
    resultsTake: 100,
  });
};
