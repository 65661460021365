import * as React from 'react';
import {MeditationTemplate} from '@mindfulness/cms';

import {SinglePageData} from '../../../types/types';
import {SingleLayout} from '../../ui';

export const Gratitude: React.FC<Props> = (props) => (
  <SingleLayout {...props} />
);

type Props = SinglePageData<MeditationTemplate>;
