import {Maybe} from '@mindfulness/utils/maybe';
import {useRecoilValue} from 'recoil';

import {CellOverlay} from './PlayerCardImage.styles';
import {Box} from '../../layout';
import {APIImage} from '../../global/Image';

import {Lottie} from '../../global/Lottie';
import {playerPlayState} from '../../../state/atoms';
import {SingleProgressBar} from '../SingleProgressBar';

export const PlayerCardImage: React.FC<{
  active: boolean;
  cellImage: string;
  lastPlayedPercent: Maybe<number>;
}> = ({active, cellImage, lastPlayedPercent}) => {
  const playerStatus = useRecoilValue(playerPlayState);
  return (
    <Box
      width="64px"
      height="64px"
      display="flex"
      shrink={0}
      position="relative"
      overflow="hidden"
      radius="lg"
    >
      <CellOverlay active={active}>
        {active ? (
          <Lottie
            path="/lottie/sound-wave.json"
            state={playerStatus === 'playing' ? 'play' : 'pause'}
            width={24}
            height={24}
          />
        ) : null}
        <SingleProgressBar percent={lastPlayedPercent} />
      </CellOverlay>
      <APIImage width={64} height={64} url={cellImage} />
    </Box>
  );
};
