import * as React from 'react';
import Image from 'next/image';

import {LinkButton} from '../../forms';
import {Row, Column, Flex, Box} from '../../layout';
import {Text} from '../../typography';
import {ModalContext} from '../../global';
import {DividerBox} from '../../ui/CheckoutWrapper/CheckoutWrapper.styles';

export const Guarantee: React.FC<{
  hasTrial: boolean;
}> = ({hasTrial}) => {
  const {openModal} = React.useContext(ModalContext);
  return (
    <DividerBox spacing={26}>
      <Row>
        <Column>
          <Flex gap={24} items="center">
            <Box minWidth="59px">
              <Image
                src="/images/guarantee.png"
                width={59}
                height={57}
                alt=""
              />
            </Box>
            <Box>
              <Text fontSize="sm" lineHeight="130%">
                {`Thanks to our `}
                <LinkButton
                  onClick={() =>
                    openModal?.({
                      name: 'guarantee',
                    })
                  }
                >
                  Mindfulness Guarantee
                </LinkButton>
                {`, you'll have 30 days ${
                  hasTrial ? `after your trial ends ` : ''
                }to get a full refund on your purchase.`}
              </Text>
            </Box>
          </Flex>
        </Column>
      </Row>
    </DividerBox>
  );
};
