import {definetly} from '@mindfulness/utils/definetly';
import {CardElement} from '@stripe/react-stripe-js';
import {Stripe, StripeElements} from '@stripe/stripe-js';

export const createPaymentMethod = async (
    elements: StripeElements | null,
    stripe: Stripe | null,
) => {
  if (!elements || !stripe) {
    throw new Error('Stripe elements not found');
  }

  const card = elements.getElement(CardElement);
  if (!card) {
    throw new Error('Stripe card not found');
  }
  const result = await stripe.createPaymentMethod({
    type: 'card',
    card,
  });
  // When there is an error throw it
  if (result.error) {
    throw new Error(result.error.message);
  }

  const paymentMethod = definetly(
      result.paymentMethod,
      'Payment method failed to create.',
  );

  return paymentMethod;
};
