import React from 'react';
import {ParsedUrlQuery} from 'querystring';
import {omitEmpty} from '@mindfulness/utils/object';

import {Box, Flex, Stack} from '../../layout';
import {Title3, Text, Small} from '../../typography';
import {
  BundleDetailFragment,
  CollectionFeedFragment,
  Maybe,
  SingleDetailFragment,
  SingleType,
} from '../../../types/api';
import {filterPlayerSingles, unNull} from '../../../utils';
import {useEquals} from '../../../hooks/useEquals';
import {Icon} from '../../global';
import {useNavigation} from '../../../hooks';
import {MixedFragment} from '../../../types/types';

import {PlayerCardImage} from '../PlayerCardImage';
import {HoverGroup} from '../PlaylistStack/PlaylistStack.styles';
import {useSingleLocked} from '../../../hooks/useSingleLocked';

export const CourseStack: React.FC<Props> = ({collection, ...ids}) => {
  return (
    <>
      <Box paddingX={30}>
        <Title3 weight="bold" spacing={16}>
          {collection.title}
        </Title3>
      </Box>
      <Box marginB={16}>
        {filterPlayerSingles(collection.results.items as MixedFragment[]).map(
            (item) => {
              if (item.__typename === 'Single') {
                const single = item as SingleDetailFragment;
                return <CourseItem {...single} {...ids} key={item.id} />;
              }
            },
        )}
      </Box>
    </>
  );
};

type Props = {
  collection: BundleDetailFragment['collections'][0] | CollectionFeedFragment;
  bundleId: Maybe<string>;
  singleId: Maybe<string>;
};

export const CourseItem: React.FC<
  SingleDetailFragment & {
    bundleId: Maybe<string>;
    singleId: Maybe<string>;
  }
> = ({singleId, bundleId, ...single}) => {
  const {id, cellImage, title, description, label, type, userData} = single;
  const active = useEquals(id, singleId);
  const {update} = useNavigation();
  const handleSingleLocked = useSingleLocked(single);
  return (
    <HoverGroup
      className="ActiveItem"
      active={active}
      key={single.id}
      onClick={() => {
        handleSingleLocked();
        update(
          omitEmpty({
            b: bundleId,
            s: single.id,
            v: userData?.defaultVariant,
          }) as ParsedUrlQuery,
        );
      }}
    >
      {/* ActiveItem class is used to scroll the active item into view on load */}
      <Flex gap={16} items="start">
        <PlayerCardImage
          active={active}
          cellImage={cellImage?.url}
          lastPlayedPercent={unNull(userData?.lastPlayedPercent)}
        />
        <Stack direction="vertical" justify="space-between" paddingY={4}>
          <Flex
            gap={8}
            height={'16px'}
            items="center"
            justify="space-between"
            spacing={6}
            wide
          >
            <Small
              fontWeight="bold"
              transform="uppercase"
              colour="primary"
              fontSize="2xs"
            >
              {label}
            </Small>
            {type !== SingleType.Music &&
              userData?.lastPlayedPercent &&
              userData.lastPlayedPercent >= 90 ? (
                <Icon name="played" size={16} colour="primary" />
              ) : null}

          </Flex>
          <Text fontWeight="bold" colour={active ? 'primary' : undefined}>
            {title}
          </Text>
          <Small colour="grey7" fontSize="xs">
            {description}
          </Small>
        </Stack>
      </Flex>
    </HoverGroup>
  );
};
