import * as React from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {Global} from '@emotion/react';
import {insertAfter} from '@mindfulness/utils/array';

import {ContinueButton} from './ContinueButton';
import {getStepChangeSteps} from './Onboarding.effects';

import {QuestionData, Steps} from '../../../types/types';
import {CheckButtons} from '../../forms';
import {Container} from '../../layout';
import {Title1, Text} from '../../typography';
import {
  onboardingStepsState,
  onboardingStepState,
} from '../../../state/atoms';
import {
  onboardingNextStep,
  onboardingStepValues,
} from '../../../state/selectors';
import {removeArrayItems, assertString, togglePushArrayItem} from '../../../utils';
import {useTrack} from '../../global/SegmentProvider';

export const Question: React.FC<QuestionData> = (props) => {
  const {title, description, options, id, maxAllowed, theme, minRequired} =
    props;
  const track = useTrack();
  const [loading, setLoading] = React.useState(false);
  const setSteps = useSetRecoilState(onboardingStepsState);
  const setStep = useSetRecoilState(onboardingStepState);
  const nextStep = useRecoilValue(onboardingNextStep);
  const [selectedItems, setSelectedItems] = useRecoilState(onboardingStepValues(id));
  const mappedOptions = options.map((option) => ({
    ...option,
    checked: selectedItems.includes(option.value),
  }));

  React.useEffect(() => {
    setLoading(false);
  }, [id]);

  const handlePageChange = React.useCallback(() => {
    setLoading(true);
    const getChangeSteps = getStepChangeSteps(id);
    const trackingData = () => ({
      question_id: id,
      question_text: title,
      answer_id: selectedItems,
      answer_text: selectedItems.map((i) =>
        assertString(options.find(({value}) => value === i)?.label),
      ),
    });

    track('Onboarding question answered', {
      ...trackingData(),
    });

    if (getChangeSteps) {
      const changeSteps = getChangeSteps(selectedItems);
      if (changeSteps?.add) {
        setSteps((prev) => {
          const nextSteps = removeArrayItems<Steps>(
              insertAfter<Steps>(prev, changeSteps.add, id),
              changeSteps.remove,
          );
          return nextSteps;
        });
        setStep(changeSteps.add[0]);
        return;
      }
    }
    if (nextStep) {
      setStep(nextStep);
      return;
    }
  }, [id, minRequired, selectedItems, nextStep]);

  return (
    <Container maxWidth="sm">
      <Global
        styles={`
      body {
        background-attachment: fixed;
        ${
          theme === 'light' ?
            `
        background-image: linear-gradient(170.24deg, #A3A5CE 0%, #8FCFCF 100%);
        background-color: #8FCFCF;
        ` :
            `
        background-image:linear-gradient(170.24deg, #6E5E8F 0%, #2F3055 100%);
        backgroun-color: #2F3055
      `
    }

};
      }
    `}
      />

      <Title1 spacing={10}>{title}</Title1>
      <Text spacing={40}>{description}</Text>
      <CheckButtons
        options={mappedOptions}
        maxAllowed={maxAllowed}
        onChoice={(value) => {
          setSelectedItems((prev) =>
            togglePushArrayItem(prev, value, maxAllowed),
          );
          if (minRequired === 1 && maxAllowed === 1) {
            handlePageChange();
          }
        }}
      />
      <ContinueButton
        {...props}
        handlePageChange={handlePageChange}
        loading={loading}
      />
    </Container>
  );
};
