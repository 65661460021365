import styled from '@emotion/styled';

export const HoverGroup = styled.button<{
  active: boolean;
  disabled?: boolean;
}>(
    ({theme, active, disabled}) => `
    ${active ? `background-color: ${theme.colors.greyWarm};` : ``}
    padding: 16px 30px;
    width: 100%;
    border-bottom: 1px solid ${theme.colors.greyWarm};
    display: block;
    text-align: left;
    transition: ease 0.3s opacity, ease 0.3s background-color;
    ${disabled ?
      `
          cursor: default;
          opacity: 0.7;

        ` :
      `
      &:hover {
        opacity: 0.7;
        p {
          color: ${theme.colors.primary};
        }
      }
    `
}

`,
);
