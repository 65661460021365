import {useEffect} from 'react';
import {onClient} from '../utils';

export const useWindowInnerHeight = () => {
  useEffect(() => {
    const handleResize = () => {
      if (!onClient) {
        return;
      }
      const doc = document?.documentElement;
      doc.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
};
