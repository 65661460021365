import {getCouponPlans, getPickerPlans} from '../../../api';
import {
  Maybe,
  PlanWithInstallments,
  ReferencedHeroPlanPicker,
} from '../../../types/types';

export const getStaticProps = async (
    section: ReferencedHeroPlanPicker,
): Promise<Maybe<{ enrichedPlans?: Array<PlanWithInstallments> }>> => {
  try {
    const data = await getPickerPlans(section);
    return data;
  } catch (e) {
    console.error(`Failed to getStaticProps in HeroPlanPicker`, e, section);
  }
};

export const hydrate = async (plans: Array<string>, coupon: string) => {
  const data = await getCouponPlans({
    plans,
    coupon,
  });

  return data;
};
