import {MUTATE_CHECKOUT} from '../../api/mutations/checkout';
import {getGraphClient} from '../../utils/graph';
import {CheckoutMutation, CheckoutMutationVariables} from '../../types/api';

export const checkout = async (
    variables: CheckoutMutationVariables,
) => {
  const res = await getGraphClient().mutate<CheckoutMutation>({
    mutation: MUTATE_CHECKOUT,
    variables,
  });
  return res.data?.checkout;
};
