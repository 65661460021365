import * as React from 'react';
import {SpecialTemplate} from '@mindfulness/cms';

import {SinglePageData} from '../../../types/types';
import {SingleLayout} from '../../ui';

export const SpecialRelease: React.FC<Props> = (props) => {
  return <SingleLayout {...props} />;
};

type Props = SinglePageData<SpecialTemplate>;
