import * as React from 'react';
import {CheckoutTemplate} from '@mindfulness/cms';
import {switchEnum} from '@mindfulness/utils/logic';
import maybe, {when} from '@mindfulness/utils/maybe';
import {until} from '@mindfulness/utils/fn';
import dayjs from 'dayjs';
import Image from 'next/image';

import {CheckoutForm} from './CheckoutForm';
import {ExpressCheckout} from './ExpressCheckout';
import {Guarantee} from './Guarantee';
import {EmptyCart} from '../../ui/EmptyCart';
import {Items} from './Items';
import {useLoadCheckout} from './useLoadCheckout';

import {Box, Column, Container, Row, Stack} from '../../layout';
import {AppTitle2, AppTitle3, Small, Text} from '../../typography';
import {Divider, PolicyLinks} from '../../ui';
import {DiscountContext} from '../../global';
import {Checkbox} from '../../forms';

import {formatMoney} from '../../../utils';
import {useNavigation, useTrackPage} from '../../../hooks';
import {CheckoutWrapper} from '../../ui/CheckoutWrapper';
import {useTrack} from '../../global/SegmentProvider';

export const Checkout: React.FC<Props> = ({template}) => {
  const {
    items,
    trial,
    coupon,
    onto,
    error,
    type,
    upgrade,
    bundle,
    payIn3,
    trialAndDiscount,
    loading,
  } = useLoadCheckout();
  const {handleDiscount} = React.useContext(DiscountContext);
  const track = useTrack();
  const {toggleParam} = useNavigation();
  useTrackPage('Checkout', 'checkout', {
    ...(type ? {page_version: type} : {page_version: 'default'}),
  });

  const itemPrice = React.useMemo(() => {
    if (!items) return 0;
    const item = items[0];
    const price = item?.discount?.finalPrice ?? item?.priceUSD;
    return price;
  }, [items, payIn3]);

  const showPayIn3 = React.useMemo(() => {
    if (itemPrice > 150 && type === 'event') return true;
    return false;
  }, [type, itemPrice]);

  const discounted = React.useMemo(() => {
    const firstDiscount = items.find(({discount}) => {
      if (!discount) return false;
      const valid = discount.valid === true;
      const displayValid = until(
          () => when(maybe(discount.amountOff), (a) => a > 0),
          () => when(maybe(discount.percentOff), (b) => b > 0),
          () => when(maybe(discount.trialDays), (c) => c > 0),
          () => Boolean(discount.freePass),
      );
      return valid && displayValid;
    });
    return firstDiscount;
  }, [items]);

  const hasTrial = React.useMemo(() => {
    if (trialAndDiscount) {
      return true;
    }
    if (discounted?.discount?.trialDays && Boolean(trial)) {
      return true;
    }
    if (Boolean(coupon) && coupon !== 'p__lifetime__discounted') {
      return false;
    }

    return Boolean(trial);
  }, [trial, discounted, trialAndDiscount]);

  const priceToday = React.useMemo(() => {
    if (hasTrial || !items.length) {
      return '$0.00';
    }
    const extras = upgrade ? 50.99 : 0;
    if (payIn3) {
      const installment = Math.floor((itemPrice * 1.1) / 3);
      return formatMoney(installment + extras);
    }
    return formatMoney(itemPrice + extras);
  }, [hasTrial, upgrade, itemPrice, payIn3]);

  const totalSaved = React.useMemo(() => {
    const start = upgrade ? 34 : 0;
    const amount = items.reduce((prev, current) => {
      if (!current.discount?.finalPrice) {
        return prev;
      }
      const difference = current.priceUSD - current.discount.finalPrice;
      return prev + difference;
    }, start);
    return amount;
  }, [items, upgrade]);

  const hasOneOffEvent = React.useMemo(() => {
    return Boolean(
        items.find(
            (plan) =>
              plan.renewalInterval === 'NEVER' && plan.productCode === 'mcom-event',
        ),
    );
  }, [items]);

  const trialDays = React.useMemo(() => {
    if (trialAndDiscount) {
      if (discounted?.discount?.trialDays) {
        return discounted?.discount?.trialDays;
      }
      return 7;
    }
    if (!trial) {
      return 0;
    }

    if (!discounted) {
      return 7;
    }
    return when(discounted.discount?.trialDays, (t) => t) || 0;
  }, [items, trial, discounted]);

  const billedText = React.useMemo(() => {
    if (!items.length || hasOneOffEvent || type === 'gift') return '';

    if (!hasTrial) {
      return switchEnum(items[0].renewalInterval, {
        YEAR: 'Billed today and renews annually. Cancel anytime.',
        MONTH: 'Billed today and renews monthly. Cancel anytime.',
        NEVER: 'Billed today, 30-day money-back guarantee.',
        else: '',
      });
    }
    const trialEndDate = dayjs().add(trialDays, 'd');
    return `On the ${trialEndDate.format(
        'D MMM',
    )} you will be billed ${formatMoney(
        items
            .map(({priceUSD, renewalInterval, discount}) => {
              if (trialAndDiscount) {
                return discount?.finalPrice || priceUSD;
              }
              if (renewalInterval === 'NEVER') {
                return discount?.finalPrice || 0;
              }
              return priceUSD;
            })
            .reduce((price, currentValue) => currentValue + price),
    )} if you haven\'t cancelled your trial`;
  }, [
    trial,
    trialDays,
    hasTrial,
    items,
    hasOneOffEvent,
    trialAndDiscount,
    type,
  ]);

  const handleTogglePayIn3 = React.useCallback(() => {
    toggleParam('/subscribe', 'p3', 'true');
  }, [toggleParam]);

  React.useEffect(() => {
    if (discounted && handleDiscount) {
      handleDiscount(discounted.discount);
    }
  }, [discounted, handleDiscount]);

  React.useEffect(() => {
    const screenViewed = switchEnum(type || 'else', {
      bogo: 'Bogo subscription screen viewed',
      gift: 'Gift subscription screen viewed',
      else: 'Subscription screen viewed',
    });

    track(screenViewed, {});
  }, [type]);

  return (
    <CheckoutWrapper
      template={template}
      loading={loading}
      itemCount={items.length}
    >
      {!loading && !items.length ? (
        <EmptyCart />
      ) : (
        <>
          <Items
            loading={loading}
            items={items}
            hasTrial={hasTrial}
            discounted={discounted}
            error={error}
            onto={onto}
            type={type}
            coupon={coupon}
            trial={trial || false}
            upgrade={upgrade}
            bundle={bundle}
            trialAndDiscount={trialAndDiscount || false}
            trialDays={trialDays}
            payIn3={payIn3}
          />
          <Container gutter={{xs: 16, md: 80}}>
            <Row>
              {showPayIn3 ? (
                <>
                  <Column spacing={4}>
                    <Checkbox
                      label="Pay-in-3 monthly installments"
                      checked={payIn3}
                      onChange={() => handleTogglePayIn3()}
                      tooltip="Split the total cost of this purchase into three equal payments. You will pay the first instalment today, and the remaining two payments will be automatically charged to your card at 30-day intervals."
                    />
                  </Column>
                  <Column>
                    <Small fontSize="xs" colour="grey7">
                      {`Three easy monthly payments of ${formatMoney(
                          Math.floor((itemPrice * 1.1) / 3),
                      )}.`}
                    </Small>
                  </Column>
                  <Divider spacing={16} />
                </>
              ) : null}
              {totalSaved > 0 && !trial ? (
                <>
                  <Column span={18} spacing={6}>
                    <Text fontWeight="bold" colour="highlight2">
                      Discount applied
                    </Text>
                  </Column>
                  <Column span={6} minContent spacing={6}>
                    <Text fontWeight="bold" colour="highlight2" align="end">
                      {`US${formatMoney(totalSaved)}`}
                    </Text>
                  </Column>
                </>
              ) : null}

              <Column span={18}>
                <AppTitle2>Total due today</AppTitle2>
                <Small colour="grey5" lineHeight="130%">
                  {billedText}
                </Small>
              </Column>
              <Column span={6} minContent spacing={showPayIn3 ? 10 : 0}>
                <AppTitle3 as="p" align="end" loading={loading}>
                  {`US${priceToday}`}
                </AppTitle3>
              </Column>

              <Divider spacing={16} />
            </Row>
            <Guarantee hasTrial={hasTrial} />
            <Text align="center" fontWeight="bold" spacing={16}>
              Continue with
            </Text>
            {payIn3 ? null : (
              <ExpressCheckout
                coupon={coupon}
                plan={items[0]}
                onto={onto}
                trial={trialDays}
                template={template}
                type={type}
                upgrade={upgrade}
                bundleSlug={bundle?.slug || undefined}
              />
            )}

            <CheckoutForm
              coupon={coupon}
              plan={items[0]}
              onto={onto}
              trial={trialDays}
              template={template}
              type={type}
              upgrade={upgrade}
              bundleSlug={bundle?.slug || undefined}
              payIn3={payIn3}
            />

            <Stack direction="vertical" wide items="center">
              <Box marginY={16} paddingX={16} maxWidth="prose">
                <PolicyLinks />
              </Box>
              <Image
                width={134}
                height={67}
                src="/images/payment-methods.png"
                alt="Visa and Mastercard"
              />
            </Stack>

            {type !== 'gift' && hasTrial ? (
              <Row topSpacing={16}>
                <Column textAlign="center">
                  <Text fontSize="sm" colour="grey8">
                    You won&apos;t be charged until the end of your free trial.
                    You can easily cancel anytime before the trial expires.
                  </Text>
                </Column>
              </Row>
            ) : null}

            <Divider spacing={30} />
          </Container>
        </>
      )}
    </CheckoutWrapper>
  );
};

type Props = {
  template: CheckoutTemplate;
};
