import * as React from 'react';
import {Global} from '@emotion/react';

import {StatsContinueButton} from './ContinueButton';
import {
  StatsTitle,
  ImageContainer,
  Circles,
  Circle,
} from './Onboarding.styles';

import {OnboardingStats} from '../../../types/types';
import {Container} from '../../layout';
import {Text} from '../../typography';

export const Stats: React.FC<OnboardingStats> = (props) => {
  const {title, description, image} = props;
  return (
    <Container maxWidth="sm">
      <Global
        styles={`
        body {
          background-attachment: fixed;
          background-image: linear-gradient(163.37deg, #37374B 0%, #272753 92.38%);
          background-color: #272753;
        }
      `}
      />
      <StatsTitle spacing={10}>
        {title.split('[').map((string) => {
          const [before, after] = string.split(']');
          return after ? (
            <>
              <span>{before}</span>
              {after}
            </>
          ) : (
            <>{before}</>
          );
        })}
      </StatsTitle>
      <Text spacing={40}>{description}</Text>
      {image ? (
        <ImageContainer>
          <Container maxWidth="lg" gutter={0}>
            <img src={image} width="100%" height="auto" />
          </Container>
        </ImageContainer>
      ) : (
        <Circles>
          <Circle />
          <Circle />
          <Circle />
        </Circles>
      )}
      <StatsContinueButton {...props} />
    </Container>
  );
};
