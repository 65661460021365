import * as React from 'react';
import {useStripe} from '@stripe/react-stripe-js';
import {CanMakePaymentResult, Stripe} from '@stripe/stripe-js';
import {CheckoutTemplate} from '@mindfulness/cms';
import {round} from 'lodash';
import {isDefined} from '@mindfulness/utils/maybe';

import {ApplePayButton, GooglePayButton} from './Checkout.styles';
import {Row, Column, Flex} from '../../layout';
import {Icon, Context, ModalContext} from '../../global';
import {CheckoutType, Maybe, PlanFragment} from '../../../types/types';
import {LoadingSpinner} from '../../ui';
import {Alert} from '../../forms';
import {useNavigation} from '../../../hooks';
import {useTrack} from '../../global/SegmentProvider';

export const ExpressCheckout: React.FC<Props> = ({
  coupon,
  plan,
  onto,
  trial,
  template,
  type,
  upgrade,
  bundleSlug,
}) => {
  const {openModal} = React.useContext(ModalContext);
  const {getCheckoutMethod, session, refreshSession} = React.useContext(Context);
  const stripe = useStripe();
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const {getQueryParam} = useNavigation();
  const track = useTrack();

  const [paymentRequest, setPaymentRequest] =
    React.useState<ReturnType<Stripe['paymentRequest']>>();
  const [canMakePayment, setCanMakePayment] =
    React.useState<CanMakePaymentResult>();

  React.useEffect(() => {
    if (stripe && plan) {
      const amount =
        Number(
            (
              (isDefined(plan.discount?.finalPrice) ?
              plan.discount?.finalPrice :
              plan.priceUSD) || 0
            ).toFixed(2),
        ) * 100;

      const intent = round(trial ? 0 : amount);

      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        displayItems: [
          ...(trial ?
            [{label: '7 day free trial', amount: intent}] :
            [
              {
                label: `Mindfulness Plus+ ${plan.name}`,
                amount: intent,
                pending: true,
              },
            ]),
        ],
        total: {
          label: `Due today`,
          amount: intent,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          // Show the expresss checkout section
          setPaymentRequest(pr);
          setCanMakePayment(result);
        }
        setLoading(false);
      });

      pr.on('paymentmethod', async (ev) => {
        setError(undefined);
        // Call purchasePlan normally with paymentMethodId
        try {
          if (session) {
            // old unauthed session may not have a name
            const name = session.fullName || session.firstName || '';
            // SSO sign in may not have an email
            const email = session.email || '';
            // Switch between which mutation should be called
            const method =
              getCheckoutMethod?.(type) || (() => 'Checkout method not found');
            const error = await method({
              name,
              email,
              coupon: plan.discount?.coupon || undefined,
              plan,
              onto,
              trial,
              paymentMethod: ev.paymentMethod,
              upgrade,
              bundleSlug,
            });

            if (error) {
              setError(error);
            }
            await refreshSession?.();
          } else {
            const emailParam = getQueryParam('email');
            openModal?.({
              name: 'action',
              props: {
                action: template,
                title: template?.modal?.title,
                buttonText: 'Continue',
                paragraph: template?.modal?.paragraph,
                redirect: async ({
                  name,
                  email,
                }: {
                  email: string;
                  name: string;
                }) => {
                  const method =
                    getCheckoutMethod?.(type) ||
                    (() => 'Checkout method not found');
                  const error = await method({
                    name,
                    email,
                    coupon: plan.discount?.coupon || undefined,
                    plan,
                    onto,
                    trial,
                    paymentMethod: ev.paymentMethod,
                    upgrade,
                    bundleSlug,
                  });
                  if (error) {
                    setError(error);
                  }
                  await refreshSession?.();
                },
                ...(emailParam ?
                  {
                    view: 'login',
                    email: emailParam,
                  } :
                  {}),
              },
              onClose: () => setLoading(false),
            });
          }

          // Tell browser to hide payment UI
          ev.complete('success');
        } catch (err) {
          if (err instanceof Error) {
            // Tell browser payment failed
            setError(err.message);
          }
          ev.complete('fail');
        }
      });
    }
  }, [stripe, coupon, plan, trial]);

  return loading ? (
    <Flex justify="center" spacing={10}>
      <LoadingSpinner />
    </Flex>
  ) : canMakePayment && paymentRequest ? (
    <Row spacing={10}>
      {error ? (
        <Column spacing={10}>
          <Alert error={error} />
        </Column>
      ) : null}
      <Column>
        {canMakePayment.googlePay ? (
          <GooglePayButton
            type="button"
            aria-label="GooglePay"
            onClick={() => {
              track('Button clicked', {
                button_id: 'GooglePay',
                button_text: 'Google Pay',
                path: window.location.pathname,
              });
              track('Express checkout used', {
                method: 'GOOGLE',
              });
              paymentRequest.show();
            }}
          >
            <Icon name="google-pay" />
          </GooglePayButton>
        ) : null}
        {canMakePayment.applePay ? (
          <ApplePayButton
            type="button"
            aria-label="ApplePay"
            onClick={() => {
              track('Button clicked', {
                button_id: 'ApplePay',
                button_text: 'Apple Pay',
                path: window.location.pathname,
              });
              track('Express checkout used', {
                method: 'APPLE',
              });
              paymentRequest.show();
            }}
          >
            <Icon name="apple-pay" width={47} height={21} />
          </ApplePayButton>
        ) : null}
      </Column>
    </Row>
  ) : null;
};

type Props = {
  coupon: Maybe<string>;
  trial: number;
  plan: Maybe<PlanFragment>;
  onto: Maybe<string>;
  template: CheckoutTemplate;
  type: CheckoutType;
  upgrade: Maybe<boolean>;
  bundleSlug: Maybe<string>;
};
