import {SingleHighlight} from '@mindfulness/cms';

import {GET_SINGLE} from '../../../api';
import {SingleFragment} from '../../../types/types';
import {adminClient} from '../../../utils';

export const getStaticProps = async (
    section: SingleHighlight,
): Promise<SingleFragment | null> => {
  if (!section.single) {
    throw new Error('Single not provided in getStaticProps for Single');
  }
  try {
    const {data} = await adminClient.query({
      query: GET_SINGLE,
      variables: {
        id: section.single?.id,
      },
    });
    return data.single;
  } catch (e) {
    console.error(
        `Failed to getStaticProps in SingleHighlight for ${section.single?.id}`,
    );
    return null;
  }
};
