import {FC} from 'react';

import {Column, Flex, Row} from '../../layout';
import {LoadingSpinner} from '../../ui';
import {Alert} from '../../forms';
import {ApplePayButton, GooglePayButton} from '../Checkout/Checkout.styles';
import {Icon} from '../../global';
import {Maybe} from '../../../types/api';
import {CanMakePaymentResult, Stripe} from '@stripe/stripe-js';
import {useTrack} from '../../global/SegmentProvider';

export const ExpressCheckout: FC<Props> = ({
  error,
  loading,
  canMakePayment,
  paymentRequest,
}) => {
  const track = useTrack();
  return loading ? (
    <Flex justify="center" spacing={10}>
      <LoadingSpinner />
    </Flex>
  ) : canMakePayment?.googlePay || canMakePayment?.applePay ? (
    <Row spacing={10}>
      {error ? (
        <Column spacing={10}>
          <Alert error={error} />
        </Column>
      ) : null}
      <Column>
        {canMakePayment?.googlePay ? (
          <GooglePayButton
            type="button"
            aria-label="GooglePay"
            onClick={() => {
              track('Button clicked', {
                button_id: 'GooglePay',
                button_text: 'Google Pay',
                path: window.location.pathname,
              });
              track('Express checkout used', {
                method: 'GOOGLE',
              });
              paymentRequest?.show();
            }}
          >
            <Icon name="google-pay" />
          </GooglePayButton>
        ) : null}
        {canMakePayment?.applePay ? (
          <ApplePayButton
            type="button"
            aria-label="ApplePay"
            onClick={() => {
              track('Button clicked', {
                button_id: 'ApplePay',
                button_text: 'Apple Pay',
                path: window.location.pathname,
              });
              track('Express checkout used', {
                method: 'APPLE',
              });
              paymentRequest?.show();
            }}
          >
            <Icon name="apple-pay" width={47} height={21} />
          </ApplePayButton>
        ) : null}
      </Column>
    </Row>
  ) : null;
};

type Props = {
  loading: boolean;
  error: Maybe<string>;
  canMakePayment: Maybe<CanMakePaymentResult>;
  paymentRequest: Maybe<ReturnType<Stripe['paymentRequest']>>;
};
