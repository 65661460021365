import * as React from 'react';

import {Logo} from '../../brand';
import {Link} from '../../global';
import {Container} from '../../layout';

import {OnboardingHeader} from './Onboarding.styles';
import {Maybe, OnboardingConfig} from '../../../types/types';

export const Header: React.FC<{
  type: Maybe<OnboardingConfig['type']>;
}> = ({type}) => (
  <Container maxWidth="sm">
    <OnboardingHeader type={type}>
      <Link href="/">
        <a>
          <Logo view="light" />
        </a>
      </Link>
    </OnboardingHeader>
  </Container>
);
