import {Theme} from '@emotion/react';
import styled from '@emotion/styled';

export const LogoWrapper = styled.div(
    ({theme}) => `
position: fixed;
top: 0;
left: 0;
padding-top: ${theme.spacings[16]};
display: none;

@media (min-width: ${theme.breakpoints.xl.min}) {
  display: block;
}
`,
);

export const TestimonialToastWrapper = styled.div(
    ({theme}) => `
position: fixed;
bottom: 0;
right: 0;
padding-right: ${theme.spacings[16]};
display: none;

@media (min-width: ${theme.breakpoints.xl.min}) {
  display: block;
}
`,
);

export const Bar = styled.div(
    ({theme}) => `
border-bottom: 1px solid ${theme.colors.grey4};
text-align: center;
padding: ${theme.spacings[8]} ${theme.spacings[16]};
font-size: ${theme.fontSizes.sm};
position: sticky;
top: 0;
`,
);


export const DividerBox = styled.div<{
  spacing?: keyof Theme['spacings'];
}>(({theme, spacing = 16}) => `
  border-bottom: ${theme.colors.grey4} 1px solid;
  padding: 0 ${theme.spacings[16]} ${theme.spacings[16]};
  margin-bottom: ${theme.spacings[spacing]};
`);
