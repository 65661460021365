import {isUndefined, when} from '@mindfulness/utils/maybe';
import {useContext, useEffect, useState} from 'react';
import {Maybe, BundleDetailFragment} from '../types/api';
import {Context} from '../components/global';
import {getBundle} from '../api';

const hydrate = async (id: Maybe<string>): Promise<Maybe<BundleDetailFragment>> => {
  try {
    const bundle = await when(
        id,
        async (b) =>
          await getBundle(
              {
                id: b,
                withUserData: true,
                resultsTake: 100,
              },
              {
                partialRefetch: true,
                fetchPolicy: 'network-only',
              },
          ),
    );
    return bundle;
  } catch (e) {
    console.error(`Failed to useHydrateBundle`, e, id);
  }
};


export const useHydratedBundle = (staticBundle: BundleDetailFragment) => {
  const [loading, setLoading] = useState(true);
  const {session} = useContext(Context);
  const [bundle, setBundle] = useState<BundleDetailFragment>(staticBundle);

  useEffect(() => {
    (async () => {
      if (!staticBundle?.id || session === null) return;
      if (isUndefined(session)) {
        setLoading(true);
        // If the user logs out, remove all the userData
        setBundle(staticBundle);
        setLoading(false);
        return;
      }
      setLoading(true);
      const hydratedBundle = await hydrate(staticBundle.id);

      if (hydratedBundle) {
        setBundle(hydratedBundle);
      }
      setLoading(false);
    })();
  }, [staticBundle?.id, session]);

  return {
    loading,
    bundle,
  };
};
