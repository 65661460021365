import React from 'react';
import {useRouter} from 'next/router';
import {isDefined} from '@mindfulness/utils/maybe';

import {CheckoutProps} from './Checkout.effects';
import {onClient} from '../../../utils';
import {PlanFragment} from '../../../types/types';
import {useNavigation} from '../../../hooks/useNavigation';

export const useLoadCheckout = () => {
  const {asPath} = useRouter();
  const [cart, setCart] = React.useState<CheckoutProps>();
  const {goTo} = useNavigation();

  React.useEffect(() => {
    onClient(async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlParams);

      const res = await fetch('/api/checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });
      const json = await res.json();
      setCart(json);
    });
  }, [asPath, goTo]);

  return {
    loading: !isDefined(cart),
    ...(cart ?
      cart :
      {
        items: [] as Array<PlanFragment>,
        trial: undefined,
        coupon: undefined,
        error: undefined,
        type: undefined,
        upgrade: undefined,
        bundle: undefined,
        trialAndDiscount: undefined,
        payIn3: undefined,
        onto: undefined,
      }),
  };
};
