import styled from '@emotion/styled';

export const RemoveButton = styled.button(({theme}) => `
  font-size: ${theme.fontSizes.xs};
`);


export const Center = styled.div(() => `
  margin: 1rem auto;
  text-align: center;
  max-width: 260px;
`);
