import styled from '@emotion/styled';

export const CellOverlay = styled.div<{
  active: boolean;
}>(
    ({active}) => `
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;

    ${
      active ?
        `
    background: rgba(55, 55, 75, 0.4);
    ` :
        ``
}
.play-wave {
  position: absolute;
  left: 7px;
  top: 7px;
}
`,
);

