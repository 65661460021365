import maybe, {whenAsync} from '@mindfulness/utils/maybe';
import {getCheckoutBundle, getCouponPlans, getPlans} from '../../../api';
import {
  BundleCheckoutFragment,
  CheckoutType,
  Maybe,
  PlanFragment,
} from '../../../types/types';
import {switchEnum} from '@mindfulness/utils/logic';
import {purchaseIds} from '../../../utils/checkout-migration';

export const hydrate = async ({
  coupon,
  plan,
  trial: trialString,
  onto,
  upgrade: upgradeString,
  b,
  td: tdString,
  p3: p3String,
  type,
}: Props): Promise<CheckoutProps> => {
  const p3 = p3String === 'true' ? true : false;
  const trial = trialString === 'true' ? true : false;
  const upgrade = upgradeString === 'true' ? true : false;
  const td = tdString === 'true' ? true : false;
  // If they have a coupon applied then they get no trial.
  // If there is no plan, then they get the default trial.
  // If there is no trial param then they get no trial.
  const defaultPlanAlias = 'annual_3';
  const planAliases = (plan?.split(',') || [defaultPlanAlias]).map((p) => {
    return switchEnum(p.split('_')[0] || 'else', {
      lifetime: 'lifetime_2',
      monthly: 'monthly_2',
      annual: 'annual_3',
      else: p,
    });
  });

  const purchaseAliases = planAliases.map((p) =>
    purchaseIds(p, trial, type || ''),
  );

  // No trial for bogo or giftcards or lifetime plans
  const trialApplied = ['bogo', 'gift'].includes(type || '') ? false : trial;

  // const sortPlans = (a, b) =>
  //   planAliases.indexOf(a.webAlias as string) -
  //   planAliases.indexOf(b.webAlias as string);

  const bundle = await whenAsync(b, async (id) => {
    try {
      return await getCheckoutBundle(id);
    } catch (e) {
      console.error('Could not find bundle with id ', id, e);
    }
  });
  const returnContext = {
    onto,
    type,
    trialAndDiscount: td || false,
    purchaseAliases,
    upgrade,
    bundle,
  };
  if (coupon) {
    try {
      const {plans, error} = await getCouponPlans({
        plans: planAliases,
        coupon,
      });

      const hasTrial = trialApplied ?
        true :
        Boolean(plans?.[0]?.discount?.trialDays);
      return {
        ...returnContext,
        items: plans || [],
        coupon,
        trial: hasTrial,
        payIn3: p3,
        error,
      };
    } catch (err) {
      console.error(err);
    }
  }
  const {plans, error} = await getPlans({
    plans: planAliases,
  });
  const hasTrial = trialApplied ?
    true :
    Boolean(plans?.[0]?.discount?.trialDays);

  return {
    ...returnContext,
    items: plans || [],
    coupon: maybe(plans?.[0]?.discount?.coupon),
    trial: hasTrial,
    payIn3: p3,
    error,
  };
};

export type CheckoutProps = {
  items: Array<PlanFragment>;
  coupon: Maybe<string>;
  trial: boolean;
  onto: Maybe<string>;
  error: string | undefined;
  type: CheckoutType;
  upgrade: Maybe<boolean>;
  bundle: Maybe<BundleCheckoutFragment>;
  trialAndDiscount: boolean;
  payIn3: Maybe<boolean>;
  purchaseAliases: Array<Maybe<string>>;
};

type Props = {
  coupon: Maybe<string>;
  plan: Maybe<string>;
  onto: Maybe<string>;
  // true or false for pay in 3
  p3: Maybe<string>;
  // bundle id
  b: Maybe<string>;
  // trial and discount
  trial: Maybe<string>;
  upgrade: Maybe<string>;
  td: Maybe<string>;
  type: Maybe<CheckoutType>;
};
