import {BackgroundAPIImage} from '../../global';
import {Flex, Box} from '../../layout';
import {AppButtonText, Text} from '../../typography';

export const UpgradeLineItem = () => (
  <Flex wrap="nowrap" gap={10} items="start" spacing={16}>
    <Box
      overflow="hidden"
      radius="lg"
      position="relative"
      width="64px"
      height="64px"
      zIndex={1}
    >
      <BackgroundAPIImage src="/images/line-item.png" doNotResize />
    </Box>

    <Flex column items="start" height="64px" grow>
      <AppButtonText>Mindfulness Plus+</AppButtonText>
      <Text
        colour="primary"
        transform="uppercase"
        fontSize="xs"
        fontWeight="bold"
      >
        12-Month Membership
      </Text>
    </Flex>
    <Box textAlign="right">
      <Text colour="grey5" decoration="line-through">
        $84.99
      </Text>
      <Text colour="highlight">$50.99</Text>
    </Box>
  </Flex>
);
