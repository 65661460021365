import React from 'react';

import {Alert, LinkButton} from '../../forms';
import {Box, Container, Row} from '../../layout';
import {Maybe} from '../../../types/types';
import {PurchaseFragment} from '../../../types/api';
import {LoadingLineItem} from '../Checkout/LineItem';
import {Text} from '../../typography';
import {ModalContext} from '../../global';
import {LineItem} from './LineItem';

export const Items: React.FC<{
  loading: boolean;
  error: Maybe<string>;
  items: Array<PurchaseFragment>;
  coupon: Maybe<string>;
  handleCoupon: (coupon: Maybe<string>) => Promise<void>;
}> = ({loading, error, items, coupon, handleCoupon}) => {
  const {openModal} = React.useContext(ModalContext);

  return (
    <Container gutter={{xs: 0, md: 80}}>
      <Row spacing={16}>
        <Box
          width="100%"
          background="greyWarm"
          radius={{
            xs: 'none',
            md: 'lg',
          }}
          padding={16}
        >
          <Alert error={error} />
          {loading ? (
            <LoadingLineItem />
          ) : (
            items.map((item) => <LineItem {...item} key={item.id} />)
          )}

          {!coupon ? (
            <Text fontSize="sm" colour="grey7">
              {'Have a coupon? '}
              <LinkButton
                onClick={() =>
                  openModal?.({
                    name: 'purchaseCoupon',
                    props: {
                      handleCoupon,
                    },
                  })
                }
                type="button"
              >
                Enter code
              </LinkButton>
            </Text>
          ) : items.length ? (
            <Text fontSize="sm" colour="grey7">
              {'Coupon applied. '}
              {coupon ? (
                <LinkButton
                  onClick={() => {
                    handleCoupon(undefined);
                  }}
                >
                  Remove Coupon
                </LinkButton>
              ) : null}
            </Text>
          ) : null}
        </Box>
      </Row>
    </Container>
  );
};
