import * as React from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {
  ButtonText,
  ContinueButtonContainer,
  TimedButton,
} from './Onboarding.styles';
import {onboardingStepState} from '../../../state/atoms';
import {onboardingNextStep} from '../../../state/selectors';
import {LoadingSpinner} from '../../../components/ui';

export const TimedContinueButton: React.FC<
  React.PropsWithChildren<{
    time: number;
  }>
> = ({children, time}) => {
  const setStep = useSetRecoilState(onboardingStepState);
  const nextStep = useRecoilValue(onboardingNextStep);
  const [status, setStatus] = React.useState('ready');

  React.useEffect(() => {
    setStatus('loading');
    setTimeout(() => {
      setStatus('complete');
    }, time * 1000);
  }, []);

  return (
    <ContinueButtonContainer>
      <TimedButton
        time={time}
        status={status}
        onClick={() => {
          setStep(nextStep);
          setStatus('redirecting');
        }}
      >
        {status === 'redirecting' ? (
          <LoadingSpinner />
        ) : (
          <ButtonText>
            {status === 'complete' ? 'Continue' : children}
          </ButtonText>
        )}
      </TimedButton>
    </ContinueButtonContainer>
  );
};
