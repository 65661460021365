import {
  getCollectionsPaths,
  getTagCollection,
  getTagCollectionByWebSlug,
} from '../../../api';
import {CollectionFeedFragment} from '../../../types/api';
import {Maybe} from '../../../types/types';

export const getStaticProps = async ({
  id,
  webSlug,
}: Params): Promise<Maybe<CollectionFeedFragment>> => {
  if (id) {
    return await getTagCollection({
      id,
      withUserData: false,
      resultsTake: 100,
    });
  }
  if (webSlug) {
    return await getTagCollectionByWebSlug({
      webSlug,
      withUserData: false,
      resultsTake: 100,
    });
  }
  throw new Error('No id or webslug was provided');
};

export const getStaticPaths = getCollectionsPaths;

type Params = {
  id?: string;
  webSlug?: string;
};
