import React, {useCallback, useEffect, useState} from 'react';
import {Maybe, isDefined} from '@mindfulness/utils/maybe';
import {Checkout3Template} from '@mindfulness/cms';
import Image from 'next/image';
import {CanMakePaymentResult, Stripe} from '@stripe/stripe-js';
import {round} from 'lodash';
import {useStripe} from '@stripe/react-stripe-js';

import {EmptyCart} from '../../ui/EmptyCart';
import {
  assertNumber,
  assertString,
  formatMoney,
} from '../../../utils';
import {CheckoutWrapper} from '../../ui/CheckoutWrapper';
import {usePurchaseCheckout} from '../../../hooks/usePurchaseCheckout';
import {Items} from './Items';
import {Box, Column, Container, Row, Stack} from '../../layout';
import {AppTitle2, AppTitle3, Small, Text} from '../../typography';
import {Checkbox} from '../../forms';
import {PolicyLinks} from '../../ui';
import {Guarantee} from '../Checkout/Guarantee';
import {ExpressCheckout} from './ExpressCheckout';
import {useQueryParam} from '../../../hooks/useQueryParam';
import {updateOrder} from '../../../api/functions/updateOrder';
import {CheckoutForm} from './CheckoutForm';
// import {useModal} from '../../../hooks/useModal';
import {Center} from './PurchaseCheckout.styled';
import {DividerBox} from '../../ui/CheckoutWrapper/CheckoutWrapper.styles';
import {GetOrderQuery, OrderFragment} from '../../../types/api';
import {useTrackPage} from '../../../hooks/useTrackPage';

export const PurchaseCheckout: React.FC<Props> = ({template}) => {
  // const {open} = useModal('plus');
  const {loading, items, error, order, setOrder, coupon, handleCoupon} =
    usePurchaseCheckout();

  // const hasUpsellInCart = useMemo(() => {
  //   if (!order?.available?.upsellOffer) return false;

  //   const upsell = items.find(
  //       ({id}) => order.available?.upsellOffer?.id === id,
  //   );
  //   return !!upsell;
  // }, [order?.available?.upsellOffer, items]);

  // const handleAddUpsell = useCallback(async () => {
  //   setLoadingUpsell(true);
  //   if (!order?.available?.upsellOffer?.id) return;
  //   if (hasUpsellInCart) {
  //     await removeFromCart(order?.available?.upsellOffer);
  //     setLoadingUpsell(false);
  //     return;
  //   }
  //   await add(order?.available?.upsellOffer);
  //   setLoadingUpsell(false);
  // }, [order, hasUpsellInCart]);

  return (
    <CheckoutWrapper template={template} items={items} loading={loading}>
      {!loading && (!items?.length || !order) ? (
        <EmptyCart />
      ) : (
        <Checkout
          handleCoupon={handleCoupon}
          items={items}
          loading={loading}
          error={error}
          coupon={coupon}
          order={order}
          setOrder={setOrder}
        />
      )}
    </CheckoutWrapper>
  );
};

const Checkout: React.FC<{
  handleCoupon: (coupon: Maybe<string>) => Promise<void>;
  items: OrderFragment['purchasables'];
  loading: boolean;
  error: Maybe<string>;
  coupon: Maybe<string>;
  order: GetOrderQuery['order'];
  setOrder: React.Dispatch<React.SetStateAction<GetOrderQuery['order']>>;
}> = ({loading, items, error, coupon, handleCoupon, order, setOrder}) => {
  const [expressError, setError] = useState<string>();
  const [expressLoading, setLoading] = useState<boolean>(true);
  const [paymentRequest, setPaymentRequest] =
    useState<ReturnType<Stripe['paymentRequest']>>();
  const stripe = useStripe();
  const [canMakePayment, setCanMakePayment] = useState<CanMakePaymentResult>();
  const [onto] = useQueryParam('onto');
  const [open, setOpen] = useState<boolean>(false);
  const [payIn3, setPayIn3] = React.useState<boolean>();
  const [loadingPayIn3, setLoadingPayIn3] = React.useState<boolean>(false);

  useTrackPage('Checkout', 'checkout', {
    page_version: 'purchase',
  });

  useEffect(() => {
    if (isDefined(payIn3) || !isDefined(order)) return;
    if (order?.available?.instalments === false) {
      setPayIn3(false);
      return;
    }
    setPayIn3(!!order?.payInstalments);
  }, [order?.payInstalments, order?.available?.instalments, payIn3]);

  const handlePayIn3 = useCallback(async () => {
    setLoadingPayIn3(true);
    if (!order?.id) return;
    const _order = await updateOrder({
      order: order?.id,
      changes: {
        payInstalments: !payIn3,
      },
    });
    setLoadingPayIn3(false);
    setPayIn3(!!_order?.payInstalments);
    setOrder(_order);
  }, [payIn3, setOrder, order]);

  useEffect(() => {
    if (canMakePayment && !canMakePayment.google && !canMakePayment.apple) {
      setOpen(true);
    }
  }, [canMakePayment]);

  useEffect(() => {
    (async () => {
      if (stripe && order) {
        try {
          const amount = round(
              Number(((order.summary?.total || 0) * 100).toFixed(2)),
          );

          const pr = stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
              label: `Due today`,
              amount,
            },
            requestPayerName: true,
            requestPayerEmail: true,
            displayItems: order.purchasables?.map((item) => ({
              label: assertString(item.title),
              amount: round(
                  Number(
                      (
                        item.price?.comparePriceUSD ||
                    item.price?.priceUSD ||
                    0
                      ).toFixed(2),
                  ) * 100,
              ),
              pending: true,
            })),
          });

          const _canMakePayment = await pr.canMakePayment();
          if (_canMakePayment) {
            setCanMakePayment(_canMakePayment);
            setPaymentRequest(pr);
            return;
          }
          setCanMakePayment({});
        } catch (error) {
          if (error instanceof Error) {
            setError(error.message);
          } else {
            setError('Unknown error');
          }
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [stripe, order]);
  return (
    <>
      <Items
        items={items}
        error={error}
        coupon={coupon}
        handleCoupon={handleCoupon}
        loading={loading}
      />
      <Container gutter={{xs: 16, md: 80}}>
        <Row>
          {order?.available?.instalments ? (
            <>
              <Column spacing={4}>
                <DividerBox>
                  <Checkbox
                    label="Pay-in-3 monthly installments"
                    checked={payIn3}
                    onChange={() => handlePayIn3()}
                    loading={loadingPayIn3}
                    tooltip="Split the total cost of this purchase into three equal payments. You will pay the first installment today, and the remaining two payments will be automatically charged to your card at 30-day intervals."
                    description="Includes a 5% surcharge for payment plans."
                  />
                </DividerBox>
              </Column>
            </>
          ) : null}
          {/* Temporarily disabled due to backend requirements */}
          {/* {order?.available?.upsellOffer ? (
                <>
                  <Column spacing={4}>
                    <DividerBox>
                      <Checkbox
                        loading={loadingUpsell}
                        label={`Add Plus+ Membership For Only ${formatMoney(
                            order.available.upsellOffer.price?.priceUSD || 0,
                        )}`}
                        checked={hasUpsellInCart}
                        onClickInfo={() => open()}
                        description={`Limited-time only. Normally ${formatMoney(
                            order.available.upsellOffer.price?.comparePriceUSD ||
                            0,
                        )}`}
                        onChange={() => handleAddUpsell()}
                      />
                    </DividerBox>
                  </Column>
                </>
              ) : null} */}
        </Row>
        <DividerBox>
          <Row>
            <Column span={18}>
              <Stack space={5} direction="vertical">
                <AppTitle2>Total due today</AppTitle2>
              </Stack>
            </Column>
            <Column span={6} minContent>
              <AppTitle3 as="p" align="end" loading={loading}>
                {`US${formatMoney(assertNumber(order?.summary?.dueToday))}`}
              </AppTitle3>
            </Column>
          </Row>
        </DividerBox>
        {order?.available?.guarantee ? <Guarantee hasTrial={false} /> : null}
        <Text align="center" fontWeight="bold" spacing={16}>
          Continue with
        </Text>
        {payIn3 ? null : (
          <ExpressCheckout
            error={expressError}
            loading={expressLoading}
            paymentRequest={paymentRequest}
            canMakePayment={canMakePayment}
          />
        )}
        <CheckoutForm order={order} onto={onto} open={open} setOpen={setOpen} />
        <Center>
          {order?.summary?.conditions ? (
            <Small fontSize="xs" lineHeight="130%">
              {order.summary.conditions}
            </Small>
          ) : null}
        </Center>
        <Stack direction="vertical" wide items="center">
          <Box marginY={16} paddingX={16} maxWidth="prose">
            <PolicyLinks />
          </Box>
          <Image
            width={134}
            height={67}
            src="/images/payment-methods.png"
            alt="Visa and Mastercard"
          />
        </Stack>
      </Container>
    </>
  );
};

type Props = {
  template: Checkout3Template;
};
