import * as React from 'react';

import {useForm} from '../../hooks';

import {Button, Input} from '../forms';
import {ModalContext, OverlayModal} from '../global';
import {Container, Row, Column} from '../layout';
import {H3} from '../typography';
import {assertString} from '../../utils/string';

export const PurchaseCouponModal: React.FC = () => {
  const {closeModal, activeProps} = React.useContext(ModalContext);
  const props = activeProps?.purchaseCoupon;
  const {data, handleChange, handleSubmit, loading, errors} = useForm<{
    coupon: string;
  }>({
    initialValues: {
      coupon: assertString(props?.coupon),
    },
    onSubmit: async (e, {data}) => {
      props?.handleCoupon(data.coupon);
      closeModal?.({
        name: 'purchaseCoupon',
      });
    },
    validations: {
      coupon: {
        required: {
          value: true,
          message: 'Please enter a coupon code',
        },
      },
    },
  });

  return (
    <OverlayModal name="purchaseCoupon" closeButton>
      <Container maxWidth="xs">
        <Row as="form" onSubmit={handleSubmit}>
          <Column>
            <H3 as="p" weight="bold" spacing={16}>
              Apply a coupon
            </H3>
          </Column>
          <Column spacing={30}>
            <Input
              label="Coupon"
              placeholder="Enter coupon"
              onChange={handleChange('coupon')}
              value={data?.coupon}
              error={errors?.coupon}
            />
          </Column>
          <Column>
            <Button id="ApplyCoupon" type="submit" wide loading={loading}>
              Apply Coupon
            </Button>
          </Column>
        </Row>
      </Container>
    </OverlayModal>
  );
};
