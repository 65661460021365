import {gql} from '@apollo/client';
import {SubscriptionFragment} from '../fragments';

export const GET_SUBSCRIPTIONS = gql`
  query getSubscriptions {
    session {
      user {
        subscriptions {
          ...SubscriptionFragment
        }
      }
    }
  }
  ${SubscriptionFragment}
`;
