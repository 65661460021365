import styled from '@emotion/styled';
import {PlayerStatus} from '../../../types/types';

export const Sidebar = styled.div<{ open: boolean; status: PlayerStatus }>(
    ({theme, open, status}) => `
  background-color: ${theme.colors.white};
  transition: width linear 0.2s, margin-top ease 0.3s;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  position: relative;
  width: 100%;

  ${
    status === 'paused' ?
      `
    margin-top: 80svh;
  ` :
      `
    margin-top: 100svh;
  `
}

  @media (min-width: ${theme.breakpoints.lg.min}) {
    border-radius: 0;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 0;
    height: 100svh;
    ${
      open ?
        `
      width: 500px;
    ` :
        `
      width: 0px;

    `
}
  }

`,
);

export const SidebarInner = styled.div(
    ({theme}) => `
  width: 100%;
  height: 100%;

  @media (min-width: ${theme.breakpoints.lg.min}) {
    width: 500px;
  }
`,
);

export const CoverImage = styled.div(
    ({theme}) => `
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  @media (min-width: ${theme.breakpoints.lg.min}) {
    display: block;
  }
`,
);

export const Content = styled.div(
    ({theme}) => `
  background-color: ${theme.colors.white};
  z-index: 1;
  position: relative;
`,
);

export const TitlePadding = styled.div(
    ({theme}) => `
  padding: 10px 30px 0px 30px;

  @media (min-width: ${theme.breakpoints.lg.min}) {
    padding: 30px 30px 0 30px;
  }
`,
);

export const CloseButton = styled.button<{
  open: boolean;
}>(
    ({theme, open}) => `
  display: none;
  position: absolute;
  left: 0;
  top: 321px;
  background-color: ${theme.colors.white};
  width: 45px;
  height: 45px;
  border-radius: 45px 0 0 45px;
  transform: translateX(-100%);
  outline: none;
  align-items: center;
  justify-content: center;

  @media (min-width: ${theme.breakpoints.lg.min}) {
    display: flex;
  }

  svg {
    transition: transform ease 0.3s;
    ${
      open ?
        '' :
        `
        transform: rotate(180deg);`
}
  }
`,
);

export const BundlePlayerContainer = styled.div(
    ({theme}) => `
    @media (min-width: ${theme.breakpoints.lg.min}) {
      display: flex;
    }
`,
);

export const BundlePlayerWrapper = styled.div<{
  hasSidebar: boolean;
  status: PlayerStatus;
}>(
    ({theme, hasSidebar, status}) => `
    height: 100vh;
    height: 100svh;
    width: 100%;
    background-color: ${theme.colors.grey9};
    -webkit-overflow-scrolling: touch;
    transition: height ease 0.3s;
    @media (max-width: ${theme.breakpoints.lg.max}) {
      height: ${hasSidebar ? `${status === 'paused' ? `80` : `100`}` : '100'}vh;
      height: ${
        hasSidebar ? `${status === 'paused' ? `80` : `100`}` : '100'
}svh;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
`,
);

export const BottomSheetBar = styled.div(
    ({theme}) => `
  margin: 0 auto 10px auto;
  min-height: 3px;
  width: 80px;
  border-radius: 2px;
  background: ${theme.colors.grey4};

  @media (min-width: ${theme.breakpoints.lg.min}) {
    display: none;
  }
`,
);
