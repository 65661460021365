import * as React from 'react';
import copy from 'copy-to-clipboard';

import {BorderColumn, GiftcardCode, WideContainer} from './Giftcard.styles';

import {assertString} from '../../../utils';
import {useNavigation, useTrackPage} from '../../../hooks';
import {Box, Column, Container, Cover, Row} from '../../layout';
import {H4, Title2, Text} from '../../typography';
import {Logo} from '../../brand';
import {ButtonLink, Icon, Link} from '../../global';
import {Button} from '../../forms';
import {useTrack} from '../../global/SegmentProvider';

export const Giftcard: React.FC = () => {
  const [view, setView] = React.useState('home');
  const {getQueryParam} = useNavigation();
  useTrackPage('Giftcard', 'giftcard');
  const track = useTrack();

  const code = getQueryParam('code');
  const redeemLink = getQueryParam('redeemLink');

  const link = React.useMemo(
      () => redeemLink ?? {pathname: '/redeem', query: {code}},
      [code, redeemLink],
  );

  const onPrint = React.useCallback(() => {
    track('Gift card printed', {});
    window.print();
  }, []);

  return (
    <Cover justify="space-between">
      <Container maxWidth="s">
        <Box paddingY={30} display="flex" justify="center">
          <Logo />
        </Box>
      </Container>
      {view === 'home' ? (
        <>
          <Container maxWidth="s" spacing={64}>
            <Row>
              <Column textAlign="center">
                <Title2>Your gift card is ready</Title2>
                <Text>How would you like to use it?</Text>
              </Column>
            </Row>
          </Container>
          <WideContainer maxWidth="mlg">
            <Row>
              <BorderColumn
                textAlign="center"
                span={{
                  xs: 24,
                  md: 12,
                }}
                spacing={34}
              >
                <Title2 spacing={10}>For Myself</Title2>
                <H4 spacing={34} colour="grey7">
                  Apply this gift card directly to your account.
                </H4>
                <ButtonLink
                  id="GiftCardRedeemNow"
                  href={`/redeem?code=${code}`}
                >
                  Redeem Now
                </ButtonLink>
              </BorderColumn>
              <Column
                textAlign="center"
                span={{
                  xs: 24,
                  md: 12,
                }}
                spacing={34}
              >
                <Title2 spacing={10}>For Someone Else</Title2>
                <H4 spacing={34} colour="grey7">
                  Share this gift card with someone else.
                </H4>
                <Button
                  id="GiftCardShareOrPrint"
                  onClick={() => setView('share')}
                >
                  Share or Print
                </Button>
              </Column>
            </Row>
          </WideContainer>
          <Box></Box>
        </>
      ) : (
        <>
          <Container maxWidth="s" spacing={80}>
            <Row>
              <Column textAlign="center">
                <Box paddingX={16} paddingT={40} paddingB={30}>
                  <img
                    src="/images/gift-card-default.png"
                    alt="Mindfulness.com gift card"
                  />
                </Box>
                <Title2 spacing={16} weight="bold">
                  Your Mindfulness Practice Starts Here
                </Title2>
                <H4 colour="grey7" spacing={34}>
                  Use this code at checkout to redeem your gift card
                </H4>
                <GiftcardCode onClick={() => copy(assertString(code))}>
                  {code}
                </GiftcardCode>
                <H4 spacing={16} colour="grey7">
                  {'Go to '}
                  <Link passHref href={link}>
                    https://mindfulness.com/redeem
                  </Link>
                </H4>
              </Column>
            </Row>
          </Container>
          <Container maxWidth="s">
            <Row gap={16}>
              <Column
                span={{
                  xs: 12,
                }}
              >
                <Button wide id="GiftCardShare" action="share">
                  Share
                </Button>
              </Column>
              <Column
                span={{
                  xs: 12,
                }}
              >
                <Button onClick={() => onPrint()} wide id="GiftCardPrint">
                  <Icon size={24} name="print">
                    Print
                  </Icon>
                </Button>
              </Column>
            </Row>
          </Container>
        </>
      )}
    </Cover>
  );
};
