import React, {useContext} from 'react';
import {PlaylistTemplate} from '@mindfulness/cms';

import {Box, Stack} from '../../layout';
import {CollectionFeedFragment} from '../../../types/types';
import {ContentSplit} from '../../ui/ContentSplit';
import {APIImage} from '../../global/Image';
import {H4, Title1, Title3, Text} from '../../typography';
import {Readmore} from '../../ui/Readmore';
import {Divider} from '../../ui/Divider';
import {CollectionList} from '../../ui/CollectionList';
import {CollectionsList} from '../../ui/CollectionsList';
import {TeacherPanel} from '../../ui/TeacherPanel';
import {Ratings} from '../../ui/Rating';
import {assertString, unNull} from '../../../utils';
import {LoadingSpinner, SingleShare} from '../../ui';
import {BundleDetailFragment} from '../../../types/api';
import {Purchases} from '../../ui/Purchases';
import {ButtonLink} from '../../global/ButtonLink';
import {useHydratedBundle} from '../../../hooks/useHydratedBundle';
import {HydratedPurchase, usePurchases} from '../../../hooks/usePurchases';
import {useLockedBundle} from '../../../hooks/useLockedBundle';
import {useHydratedCollection} from '../../../hooks/useHydratedCollection';
import {Icon} from '../../global/Icon';
import {Context} from '../../global/Provider';
import {
  UnderlinedButton,
  UnderlinedLink,
} from '../../sections/HeroBundle/HeroBundle.styles';
import {SubscribeForm} from '../../ui/SubscribeForm/SubscribeForm';
import {AvailableEverywhere} from '../../ui/AvailableEverywhere';
import {useTrack} from '../../global/SegmentProvider';
import {useTrackPage} from '../../../hooks';
import {Section} from '../../layouts/Section';

export const Playlist: React.FC<
  BundleDetailFragment & {
    relatedCollections: Array<CollectionFeedFragment>;
    template: PlaylistTemplate;
  }
> = ({relatedCollections, template, ...playlist}) => {
  const {session} = useContext(Context);
  const [selected, setSelected] = React.useState<HydratedPurchase>();
  const track = useTrack();
  useTrackPage(playlist.title, playlist.id);
  const {loading: loadingPurchases, hydratedPurchases} = usePurchases(
      template?.purchases || [],
      template?.coupon,
  );
  const {bundle: b} = useHydratedBundle(playlist);
  const {
    isLockedBundle,
    isReleased,
    bundle,
    loginAction,
    loading: bundleLoading,
  } = useLockedBundle(b);
  const {
    title,
    subtitle,
    description,
    coverImage,
    cardImage,
    collections,
    curator,
    ratings,
  } = bundle;

  const purchases = hydratedPurchases.filter((p) => {
    if (!bundle.userData) return true;
    if (
      ['BASICS_OR_PLUS', 'BASICS'].includes(
          assertString(bundle.userData.upgradeTo),
      )
    ) {
      return true;
    }
    if (
      bundle.userData.upgradeTo === 'PLUS' &&
      p.purchase.purchase?.title?.includes('Plus+')
    ) {
      return true;
    }
  });

  return (
    <>
      <Box paddingT={{xs: 0, xl: 80}}>
        <Section padding={0}>
          <ContentSplit
            Left={() => (
              <>
                <Box
                  display={{
                    xs: 'none',
                    md: 'block',
                  }}
                  marginB={30}
                >
                  <APIImage
                    ratio={'339:191'}
                    url={cardImage?.url ?? coverImage?.url}
                    priority
                  />
                </Box>
                <Box
                  display={{
                    xs: 'block',
                    md: 'none',
                  }}
                  marginB={30}
                >
                  <APIImage
                    ratio={'339:191'}
                    decoration="none"
                    url={cardImage?.url ?? coverImage?.url}
                    priority
                  />
                </Box>
                <Box
                  paddingX={{
                    xs: 16,
                    md: 0,
                  }}
                >
                  <Stack direction="horizontal" wide justify="space-between">
                    <Title1>{title}</Title1>
                    <SingleShare bundle={bundle} colour="primary" />
                  </Stack>
                  {subtitle ? <Title3 spacing={10}>{subtitle}</Title3> : null}
                  {description ? (
                    <Readmore minHeight={78}>
                      <H4 lineHeight={'150%'} colour="grey8">
                        {description}
                      </H4>
                    </Readmore>
                  ) : null}
                  <Divider colour="grey4" spacing={{xs: 0, lg: 30}} />
                </Box>
              </>
            )}
            Right={() => (
              <Box width={'100%'}>
                <Stack direction="horizontal" items="center" space={10}>
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    colour="primary"
                    transform="uppercase"
                  >
                    Playlist
                  </Text>
                  {ratings && <Ratings ratings={ratings} />}
                </Stack>
                <TeacherPanel
                  loading={false}
                  spacing={30}
                  teacher={unNull(curator)}
                />
                {bundleLoading ? (
                  <Box
                    display="flex"
                    height="200px"
                    items="center"
                    justify="center"
                  >
                    <LoadingSpinner />
                  </Box>
                ) : (
                  <>
                    {isLockedBundle ? (
                      <Purchases
                        purchases={template.purchases || []}
                        hydratedPurchases={purchases}
                        loadingPurchases={loadingPurchases}
                        selected={selected}
                        setSelected={setSelected}
                        index={0}
                        coupon={template?.coupon}
                      />
                    ) : (
                      <>
                        <ButtonLink
                          id="StartPlaylist"
                          wide
                          href={`/play?b=${bundle?.id}`}
                        >
                          {isReleased ? (
                            bundle?.userData?.completedSessions ? (
                              <Icon name="player-play">Play Next</Icon>
                            ) : (
                              <Icon name="player-play">Start</Icon>
                            )
                          ) : (
                            'Scheduled'
                          )}
                        </ButtonLink>
                      </>
                    )}
                  </>
                )}
                <Divider spacing={30} />
                <AvailableEverywhere />
              </Box>
            )}
            UnderCard={() => (
              <>
                {!bundleLoading && isLockedBundle ? (
                  <Text align="center">
                    Already purchased?{' '}
                    {session ? (
                      <UnderlinedLink href={'/contact'}>
                        {'Contact Support'}
                      </UnderlinedLink>
                    ) : (
                      <UnderlinedButton
                        as="button"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          track('Button clicked', {
                            path: window.location.pathname,
                            button_text: 'Sign in',
                            id: 'BundleHeroSignIn',
                          });
                          loginAction();
                        }}
                      >
                        {'Sign in'}
                      </UnderlinedButton>
                    )}
                  </Text>
                ) : null}
              </>
            )}
          />
        </Section>
      </Box>

      <SubscribeForm />

      {collections.map((c, index) => (
        <PlaylistList
          collection={c}
          bundleId={bundle.id}
          key={c.id}
          index={index}
        />
      ))}

      <CollectionsList collections={relatedCollections || []} feedId={undefined} />
    </>
  );
};

const PlaylistList: React.FC<{
  collection: CollectionFeedFragment;
  bundleId: string;
  index: number;
}> = ({collection, bundleId, index}) => {
  const {collection: hydratedCollection} = useHydratedCollection(collection);
  return hydratedCollection ? (
    <CollectionList {...hydratedCollection} bundleId={bundleId} index={index} feedId={undefined} />
  ) : null;
};
