import styled from '@emotion/styled';
import {Column, Container} from '../../layout';

export const GiftcardCode = styled.button(
    ({theme}) => `
  background-color: ${theme.colors.grey4};
  font-size: 22px;
  line-height: 120%;
  font-weight: ${theme.fontWeights.bold};
  border-radius: ${theme.radius.sm};
  margin-bottom: 20px;
  padding: 20px 57px 25px;
  display: inline-block;
  border: dashed 1px ${theme.colors.grey1};
`,
);

export const WideContainer = styled(Container)(
    ({theme}) => `
width: 100%;
`,
);

export const BorderColumn = styled(Column)(
    ({theme}) => `
    @media (min-width: ${theme.breakpoints.md.min}) {
      border-right: 1px solid ${theme.colors.grey4};
    }
`,
);
