import * as React from 'react';
import {useRecoilValue} from 'recoil';

import {ProgressBarContainer, ProgressBarInner} from './Onboarding.styles';

import {onboardingProgress} from '../../../state/selectors';

export const ProgressBar: React.FC = () => {
  const progress = useRecoilValue(onboardingProgress);
  return (
    <ProgressBarContainer>
      <ProgressBarInner
        style={{
          width: progress,
        }}
      />
    </ProgressBarContainer>
  );
};
