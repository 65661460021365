import {useEffect, useState} from 'react';
import {QuoteFragment} from '../types/api';
import {getDailyQuote} from '../api/functions/getDailyQuote';

const hydrate = async () => {
  try {
    const quote = await getDailyQuote();
    return quote;
  } catch (e) {
    console.error(`Failed to useDailyQuote`, e);
  }
};

export const useDailyQuote = () => {
  const [loading, setLoading] = useState(true);
  const [quote, setQuote] = useState<QuoteFragment>();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const hydratedQuote = await hydrate();

      if (hydratedQuote) {
        setQuote(hydratedQuote);
      }
      setLoading(false);
    })();
  }, []);

  return {
    loading,
    quote,
  };
};
