
import {getProducers, getRelatedFeeds, getTagFeed} from '../../../api';
import {Maybe, TagFeedFragment} from '../../../types/types';

export const getStaticProps = async ({feedId}: { feedId: string }): Promise<Maybe<{
  feed: TagFeedFragment;
  relatedFeeds: TagFeedFragment[];
}>> => {
  try {
    const relatedFeedSlugs = await getRelatedFeeds(feedId);
    const relatedFeeds = await Promise.all(
        (relatedFeedSlugs || []).map(async ({slug, title}) => {
          try {
            const tagFeed = await getTagFeed({
              id: `${feedId.split('.')[0]}.${slug}`,
              withUserData: false,
              resultsTake: 10,
            });
            return {
              ...tagFeed,
              title,
            };
          } catch (e) {
            console.error(`Failed to get related feed ${feedId.split('.')[0]}.${slug}`, e);
            return undefined;
          }
        }).filter((feed) => Boolean(feed)) as unknown as TagFeedFragment[],
    );
    const feed = await getTagFeed({
      id: feedId,
      withUserData: false,
      resultsTake: 10,
    });

    if (!feed) {
      return;
    }

    return {
      feed,
      relatedFeeds,
    };
  } catch (e) {
    console.error(`Failed to getStaticProps in Teacher for ${feedId}`);
  }
};

export const getStaticPaths = getProducers;
