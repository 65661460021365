import * as React from 'react';
import {useRecoilValue} from 'recoil';
import {Figure} from '@mindfulness/cms';
import {omitEmpty} from '@mindfulness/utils/object';
import {snakeCase} from 'lodash/fp';

import {SplitCover, AuthForm} from '../../ui';
import {useNavigation} from '../../../hooks';

import {onboardingSelectionsState} from '../../../state/atoms';
import {reduceObject, sortAlphabetically} from '../../../utils';
import {useAuthView} from '../../../hooks/useAuthView';
import {useTrack} from '../../global/SegmentProvider';

export const Signup: React.FC = () => {
  const track = useTrack();
  // const trackingContext = React.useContext(TrackingContext);
  const {goTo} = useNavigation();
  const onboardingSelections = useRecoilValue(onboardingSelectionsState);

  const {token, email, view, loading, setLoading} = useAuthView();

  const defaultModalImage: Figure = React.useMemo(
      () => ({
        _type: 'figure',
        image: {
          _type: 'image',
          asset: {
            _type: 'reference',
            _ref: 'image-d83b3e8e73830eb39ff8a4fe95ab2dfa5ff58bf4-1440x1800-jpg',
          },
        },
      }),
      [],
  );

  React.useEffect(() => {
    const onboardingVals = reduceObject(
        onboardingSelections,
        (val) => {
          return sortAlphabetically([...val]);
        },
        (key) => snakeCase(key),
    );
    track('Onboarding completed', onboardingVals);
  }, []);

  return (
    <SplitCover index={0} image={defaultModalImage}>
      <AuthForm
        title="Your personalized account
        awaits you inside"
        paragraph={[
          {
            _key: 'e6826c8ead89',
            _type: 'block',
            children: [
              {
                _key: '4509945e2f740',
                _type: 'span',
                marks: [],
                text: 'Enter your email to access your plan.',
              },
            ],
            markDefs: [],
            style: 'normal',
          },
        ]}
        loading={loading}
        setLoading={setLoading}
        index={0}
        buttonText="Sign up with email"
        token={token}
        email={email}
        view={view}
        redirect={({email}) => {
          goTo({
            pathname: `/`,
            query: omitEmpty({
              email,
              pu: 'true',
            }),
          });
        }}
      />
    </SplitCover>
  );
};
