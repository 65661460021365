import {FC} from 'react';
import {Column, Flex, Row} from '../../layout';
import {Button} from '../../forms';
import {Icon} from '../../global';

export const OpenCreditCard: FC<Props> = ({onClick}) => {
  return (
    <Row>
      <Column>
        <Button id="OpenCreditCard" onClick={onClick} wide>
          <Flex items="center" gap={10}>
            <Icon name="credit-card" />
            <span>Credit Card</span>
          </Flex>
        </Button>
      </Column>
    </Row>
  );
};

type Props = {
  onClick: () => void;
};
