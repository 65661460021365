import * as React from 'react';
import {useEffect, useState} from 'react';
import {switchEnum} from '@mindfulness/utils/logic';

import {AuthForm, LoadingSpinner, OnClient, SplitCover} from '../../ui';
import {Context} from '../../global';
import {onClient} from '../../../utils';
import {useAuthView} from '../../../hooks/useAuthView';
import {useQueryParam} from '../../../hooks/useQueryParam';
import {debug} from '../../../utils/logging';
import {AffirmationCardRedeemForm} from '../../ui/AffirmationCardRedeemForm';
import {redeemAccessPass} from '../../../api/functions/redeemAccessPass';
import {useAsyncEffect} from '../../../hooks/useAsyncEffect';

export const LoginAffirmationDeck: React.FC = () => {
  const [_email, setEmail] = useState<string>();
  const {token, email, view, setView, loading: authLoading, setLoading: setAuthLoading} = useAuthView();
  const {session, refreshSession} = React.useContext(Context);
  const [onto] = useQueryParam('onto');
  const [loading, setLoading] = useState(false);

  const redirect = async () => {
    await onClient(async () => {
      if (!onto) return;
      setLoading(true);
      const url = new URL(`https://mindfulness.com${onto}`);
      const params = new URLSearchParams(url.search);
      debug('url', url);
      debug('onto params', params);
      // Get the single ID front the onto url and check if the user has access
      const singleID = params.get('s');
      debug('singleID', singleID);
      if (!singleID) {
        setLoading(false);
        return;
      }
      const productID = params.get('product');
      if (productID) {
        const code = switchEnum(productID, {
          'D1': 'FRYXQA',
          'else': undefined,
        });
        if (code) {
          await redeemAccessPass({
            code,
          });
          await refreshSession?.();
        }
      }
      // Go to the onto url
      window.location.href = onto;
    });
  };

  useAsyncEffect(async () => {
    if (!session || !onto || loading) return;
    await redirect();
  }, [session, onto, loading]);

  const onVerify = ({
    email,
  }: {
    email?: string | undefined;
    name?: string | undefined;
  }) => {
    setView('verify');
    setEmail(email);
  };

  useEffect(() => {
    if (email && !_email) {
      setEmail(email);
    }
  }, [email]);

  const image = React.useMemo(() => {
    if (session && !loading) {
      return '/images/affirmation-redeem-code.jpg';
    }
    if (view === 'verify') {
      return '/images/affirmation-redeem-verify.jpg';
    }
    return '/images/affirmation-card-redeem.jpg';
  }, [view, session, loading]);

  const mobileImage = React.useMemo(() => {
    if (session && !loading) {
      return '/images/affirmation-redeem-code-mobile.jpg';
    }
    if (view === 'verify') {
      return '/images/affirmation-redeem-verify-mobile.jpg';
    }
    return '/images/affirmation-card-redeem-mobile.jpg';
  }, [view, session, loading]);

  return (
    <SplitCover index={0} image={image} mobileImage={mobileImage}>
      {session === null || loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {session === undefined ? (
            <OnClient>
              <AuthForm
                loading={authLoading}
                setLoading={setAuthLoading}
                title="Create or login to your Mindfulness account"
                subtitle="To gain access to the coaching videos and meditations in your affirmation deck, create your login or sign in below."
                notice="If you already have the Mindfulness.com app installed, please use the email address found in your Account Profile under Personal Information."
                index={0}
                token={token}
                email={_email}
                view={view}
                setView={setView}
                redirect={redirect}
                // Change the link that is sent in the email to the user to verify so that they come back to the same page
                verifyLink={`${window.location.pathname}${window.location.search}`}
                onVerify={onVerify}
                // We are manually setting the view to 'verify' in the onVerify prop
                requireVerify={false}
                faqLink="http://help.mindfulness.com/en/articles/8693368-mindful-affirmation-cards"
              />
            </OnClient>
          ) : (
            <AffirmationCardRedeemForm />
          )}
        </>
      )}
    </SplitCover>
  );
};
