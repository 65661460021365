import * as React from 'react';
import {BrowseTemplate} from '@mindfulness/cms';
import dynamic from 'next/dynamic';

import {ReferencedPages} from '../../../types/types';
import {H4, Title1} from '../../typography';
import {Container, Section} from '../../layout';
import {Breadcrumb, CollectionsList, PillList} from '../../ui';

import {APIProps} from './Browse.effects';
import {assertString} from '../../../utils/string';
import {filterEmptyFeeds} from '../../../utils/version';
import {useTrackPage} from '../../../hooks';

const Sections = dynamic(() =>
  import('../../global/Sections/index').then(({Sections}) => Sections),
);

export const Browse: React.FC<Props> = ({
  hub,
  data,
  browseSlug,
  template,
}) => {
  const feeds = React.useMemo(
      () => filterEmptyFeeds(data?.relatedFeeds),
      [data?.relatedFeeds],
  );

  const browseTitle = React.useMemo(() => {
    return feeds?.find((feed) => {
      if (!feed) return false;
      return feed.id === data?.tagFeed?.id;
    })?.title;
  }, [feeds, data]);

  useTrackPage(assertString(browseTitle, 'Browse'), assertString(data?.tagFeed?.id, ''));

  const pillItems = React.useMemo(
      () =>
        feeds?.map((feed) => {
          if (!feed) return;
          const {id, title} = feed;
          const slug = feed.id.split('.')[1];
          const hubSlug = hub?.slug?.current;
          const firstId = feeds[0].id.split('.')[1];
          const browseUrl = slug !== firstId ? `/browse/${slug}` : '/browse';
          const url = hub ? `${hubSlug}${browseUrl}` : `/feed-preview/${id}`;

          return {
            url,
            label: title,
            active: browseSlug === slug || (!browseSlug && slug === firstId),
          };
        }),
      [feeds, hub, browseSlug],
  );
  return (
    <>
      <Section
        background="white"
        sectionPadding={{
          paddingTop: 80,
          paddingBottom: 0,
        }}
      >
        <Container>
          <Breadcrumb
            items={[
              ...(hub.slug?.current && hub.title ?
                [
                  {title: hub.title, link: hub.slug.current},
                  {title: 'Browse', link: `${hub.slug.current}/browse`},
                ] :
                []),
              ...(browseTitle !== 'Featured' ?
                [{title: assertString(browseTitle, 'Browse')}] :
                []),
            ]}
          />
          <Title1 spacing={12}>{`Browse by ${
            hub?.title || 'hub.title'
          }`}</Title1>
          <H4 colour="grey7" spacing={30}>
            {data?.tagFeed?.description}
          </H4>
        </Container>
      </Section>
      {pillItems ? <PillList items={pillItems} /> : null}
      {data?.tagFeed?.collections ? (
        <CollectionsList collections={data.tagFeed.collections} feedId={data.tagFeed.id} priority />
      ) : null}
      {template?.sections ? (
        <Sections sections={template.sections} indexOffset={1} />
      ) : null}
    </>
  );
};

type Props = {
  hub: ReferencedPages;
  data?: APIProps;
  browseSlug?: string;
  template: BrowseTemplate;
};
