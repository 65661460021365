import * as React from 'react';
import {Flex, Stack} from '../../layout';
import {Text} from '../../typography';
import {SwitchButton as SwitchToggle} from '../Switch/Switch.styles';
import {SwitchButton} from './SmallSwitch.styles';
import {slugify} from '../../../utils';

export const SmallSwitch: React.FC<{
  checked: boolean;
  title: string;
  onChange: () => void;
}> = ({checked, title, onChange}) => {
  const ariaId = React.useMemo(() => slugify(title), [title]);
  return (
    <SwitchButton
      role="switch"
      aria-checked={checked}
      aria-labelledby={ariaId}
      onClick={() => onChange()}
    >
      <Stack direction="horizontal" space={10}>
        <Text as="span" id={ariaId}>
          {title}
        </Text>
        <Flex items="center">
          <SwitchToggle variant="small" aria-checked={checked} />
        </Flex>
      </Stack>
    </SwitchButton>
  );
};
