import React, {useMemo} from 'react';
import {ParsedUrlQuery} from 'querystring';
import {omitEmpty} from '@mindfulness/utils/object';
import {isDefined} from '@mindfulness/utils/maybe';
import {inPast} from '@mindfulness/utils/time';

import {
  BundleDetailFragment,
  CollectionFeedFragment,
  Maybe,
  SingleDetailFragment,
  SingleType,
} from '../../../types/api';
import {filterPlayerSingles, unNull} from '../../../utils';
import {Box, Flex, Stack} from '../../layout';
import {Title3, Text, Small, Tag} from '../../typography';
import {Icon} from '../../global';

import {PlayerCardImage} from '../PlayerCardImage';
import {useEquals} from '../../../hooks/useEquals';
import {HoverGroup} from './PlaylistStack.styles';
import {useNavigation} from '../../../hooks';
import {MixedFragment} from '../../../types/types';
import {useContributorNames} from '../../../hooks/useContributorNames';
import {useSingleLocked} from '../../../hooks/useSingleLocked';

export const PlaylistStack: React.FC<Props> = ({collection, ...ids}) => {
  return (
    <>
      <Box paddingX={30}>
        <Title3 weight="bold" spacing={16}>
          {collection.title}
        </Title3>
      </Box>
      <Box marginB={16}>
        {filterPlayerSingles(collection.results.items as MixedFragment[]).map(
            (item) => {
              if (item.__typename === 'Single') {
                const single = item as SingleDetailFragment;
                return <PlaylistItem {...single} {...ids} key={item.id} />;
              }
            },
        )}
      </Box>
    </>
  );
};

type Props = {
  collection: BundleDetailFragment['collections'][0] | CollectionFeedFragment;
  bundleId: Maybe<string>;
  singleId: Maybe<string>;
};

const PlaylistItem: React.FC<
  SingleDetailFragment & {
    bundleId: Maybe<string>;
    singleId: Maybe<string>;
  }
> = ({singleId, bundleId, ...single}) => {
  const {id, cellImage, title, contributors, releaseDate, userData, type} =
    single;
  const active = useEquals(id, singleId);
  const {update} = useNavigation();
  const isScheduled = useMemo(
      () => !inPast(new Date(releaseDate)) && isDefined(releaseDate),
      [releaseDate],
  );
  const contributorNames = useContributorNames(contributors);
  useSingleLocked(single);
  return (
    <HoverGroup
      active={active}
      disabled={isScheduled}
      key={id}
      onClick={() => {
        update(
          omitEmpty({
            b: bundleId,
            s: id,
            v: userData?.defaultVariant,
          }) as ParsedUrlQuery,
        );
      }}
    >
      <Flex gap={16} items="stretch">
        <PlayerCardImage
          active={active}
          cellImage={cellImage?.url}
          lastPlayedPercent={unNull(userData?.lastPlayedPercent)}
        />
        <Flex justify="space-between" items="stretch" column wide>
          {contributorNames || isScheduled || type !== SingleType.Music ? (
            <Stack
              direction="horizontal"
              items="center"
              justify="space-between"
              wide
            >
              {contributorNames ? (
                <Small colour="grey5" spacing={4}>
                  {contributorNames}
                </Small>
              ) : null}

              {isScheduled ? (
                <Tag>Upcoming</Tag>
              ) : type !== SingleType.Music &&
                userData?.lastPlayedPercent &&
                userData.lastPlayedPercent >= 90 ? (
                <Icon name="played" size={16} colour="primary" />
              ) : null}
            </Stack>
          ) : null}

          <Text
            className="item-title"
            fontWeight="bold"
            colour={active ? 'primary' : undefined}
          >
            {title}
          </Text>
          <Text
            fontSize={'2xs'}
            transform="uppercase"
            fontWeight="bold"
            colour="grey7"
          >
            {single.label}
          </Text>
        </Flex>
      </Flex>
    </HoverGroup>
  );
};
