import * as React from 'react';
import {definetly} from '@mindfulness/utils/definetly';
import {omitEmpty} from '@mindfulness/utils/object';
import {captureException} from '@sentry/nextjs';

import {PolicyLinks, SplitCover} from '../../ui';
import {Context, Link} from '../../global';
import {Alert, Input, Button} from '../../forms';
import {Container, Row, Column} from '../../layout';
import {H2, H4} from '../../typography';

import {assertString, unNull, uniqueClientId} from '../../../utils';
import {useForm, useTrackPage, useNavigation} from '../../../hooks';
import {redeemGiftCardAnonymously} from '../../../api';
import {useQueryParam} from '../../../hooks/useQueryParam';
import {NoticeBox} from '../../ui/NoticeBox';
import {useTrack} from '../../global/SegmentProvider';

export const Redeem: React.FC = () => {
  useTrackPage('Redeem gift card', 'redeem');
  return (
    <SplitCover index={0} image="/images/redeem-img.png">
      <RedeemForm />
    </SplitCover>
  );
};

const RedeemForm: React.FC = () => {
  const {session} = React.useContext(Context);
  const {getQueryParam, goTo} = useNavigation();
  const track = useTrack();

  const userEmail = getQueryParam('email');
  const [cohort] = useQueryParam('cohort');
  const code = getQueryParam('code');

  const {data, handleChange, handleSubmit, loading, errors, setValue} =
    useForm<{
      email: string;
      code: string;
    }>({
      initialValues: {
        email: assertString(userEmail),
        code: assertString(code),
      },
      onSubmit: async (e, {data, setErrors}) => {
        const onSubmit = async (email: string, code: string) => {
          track('Gift card redeem initiated', {
            code,
            email,
          });

          const {generateInstantAccess} =
            (await redeemGiftCardAnonymously({
              email,
              code,
              cohorts: cohort ? [cohort] : [],
              deviceId: definetly(
                  uniqueClientId(),
                  'Missing client id. Likely running on server.',
              ),
            })) || {};

          track('Gift card redeem success', {
            code,
            email,
          });

          goTo({
            pathname: '/giftcard-redeemed',
            query: omitEmpty({
              link: unNull(generateInstantAccess?.link),
              email,
            }),
          });
        };

        try {
          await onSubmit(data.email, data.code);
        } catch (err) {
          captureException(err);
          setErrors({form: 'redeemed'});
        }
      },
      validations: {
        email: {
          required: {
            value: true,
            message: 'Please enter your email address',
          },
        },
        code: {
          required: {
            value: true,
            message: 'Please enter a code',
          },
        },
      },
    });

  React.useEffect(() => {
    if (userEmail) {
      setValue('email', userEmail);
    } else if (session?.email) {
      setValue('email', session.email);
    }
    if (code) {
      setValue('code', code);
    }
  }, [session, userEmail, code]);

  return (
    <Container maxWidth="s">
      <Alert
        error={
          errors?.form === 'redeemed' ? (
            <span>
              {
                'Oops - have you already redeemed your gift card? If you need further assistance, please contact our '
              }
              <Link href={`/contact?email=${data.email}`}>support team</Link>.
            </span>
          ) : (
            errors?.form
          )
        }
      />
      <H2 size="xl">Your Mindfulness Practice Starts Here</H2>
      <H4 as="p" colour="grey7" spacing={20} lineHeight="150%">
        Please enter your email and coupon code to redeem your Mindfulness.com
        subscription.
      </H4>
      <NoticeBox>
        {
          'If you already have the app installed, you must use the email address found in your Account Profile under Personal Information. '
        }
      </NoticeBox>
      <Row as="form" onSubmit={handleSubmit}>
        <Column spacing={16}>
          <Input
            label="Email"
            id="email"
            type="email"
            placeholder="Email address"
            value={data.email}
            onChange={handleChange('email')}
            error={errors?.email}
          />
        </Column>

        <Column spacing={30}>
          <Input
            label="Code"
            id="code"
            placeholder="Enter your code"
            value={data.code}
            onChange={handleChange('code', (c) => c.toUpperCase())}
            error={errors?.code}
          />
        </Column>

        <Column spacing={16}>
          <Button loading={loading} type="submit" id="Redeem" wide>
            Redeem Now
          </Button>
        </Column>
        <Column>
          <PolicyLinks />
        </Column>
      </Row>
    </Container>
  );
};
