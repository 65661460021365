import * as React from 'react';
import {useStripe, useElements} from '@stripe/react-stripe-js';

import {CheckoutTemplate} from '@mindfulness/cms';
import {Maybe} from '@mindfulness/utils/maybe';

import {Context, ModalContext} from '../../global';
import {usePreload} from '../../../hooks';
import {CheckoutType, PlanFragment} from '../../../types/types';
import {OpenCreditCard} from './OpenCreditCard';
import {CreditCard} from './CreditCard';
import {createPaymentMethod} from '../../../utils/stripe';
import {useQueryParam} from '../../../hooks/useQueryParam';
import {defaultCheckoutThankyou} from '../../../utils';
import {useTrack} from '../../global/SegmentProvider';

export const CheckoutForm: React.FC<Props> = ({
  payIn3,
  plan,
  onto,
  trial,
  template,
  type,
  upgrade,
  bundleSlug,
}) => {
  const [open, setOpen] = React.useState(false);
  const {session, getCheckoutMethod, refreshSession} = React.useContext(Context);
  const track = useTrack();
  const {openModal} = React.useContext(ModalContext);
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardChanged, setCardChanged] = React.useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();
  const [emailParam] = useQueryParam('email');

  usePreload(onto || defaultCheckoutThankyou());
  usePreload('/thank-you/bogo');
  usePreload('/giftcard-redeemed');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const paymentMethod = await createPaymentMethod(elements, stripe);

      if (session || emailParam) {
        // old unauthed session may not have a name
        const name = session?.fullName || session?.firstName || '';
        const email = session?.email || emailParam || '';
        // Switch between which mutation should be called
        const method =
          getCheckoutMethod?.(type) || (() => 'Checkout method not found');

        const error = await method({
          name,
          email,
          coupon: plan.discount?.coupon || undefined,
          plan,
          onto,
          trial,
          paymentMethod,
          upgrade,
          bundleSlug,
          payIn3,
        });

        if (error) {
          setErrors({
            form: error,
          });
        }
        await refreshSession?.();
        setLoading(false);
        return;
      }
      openModal?.({
        name: 'action',
        props: {
          action: template,
          title: template?.modal?.title,
          buttonText: 'Continue',
          paragraph: template?.modal?.paragraph,
          onClose: () => {
            setLoading(false);
          },
          redirect: async ({
            email,
            name,
          }: {
            email: string;
            name?: string;
          }) => {
            const method =
              getCheckoutMethod?.(type) || (() => 'Checkout method not found');

            const error = await method({
              name,
              email,
              coupon: plan.discount?.coupon || undefined,
              plan,
              onto,
              trial,
              paymentMethod,
              upgrade,
              bundleSlug,
              payIn3,
            });

            if (error) {
              setErrors({
                form: error,
              });
            }

            setLoading(false);
            await refreshSession?.();
          },
          ...(emailParam ?
            {
              view: 'login',
              email: emailParam,
            } :
            {}),
        },
      });
    } catch (e) {
      setLoading(false);
      if (e instanceof Error) {
        setErrors({
          form: e.message,
        });
        return;
      }

      setErrors({
        form: 'An unexpected error occured.',
      });
    }
  };

  React.useEffect(() => {
    if (cardChanged) {
      track('Payment info entered', {});
    }
  }, [cardChanged, track]);

  return open ? (
    <CreditCard
      loading={loading}
      onSubmit={handleSubmit}
      errors={errors}
      onCardChanged={() => setCardChanged(true)}
      cardChanged={cardChanged}
      allHaveTrial={false}
    />
  ) : (
    <OpenCreditCard onClick={() => setOpen(true)} />
  );
};

type Props = {
  coupon: Maybe<string>;
  trial: number;
  plan: PlanFragment;
  onto: Maybe<string>;
  template: CheckoutTemplate;
  type: CheckoutType;
  upgrade: Maybe<boolean>;
  bundleSlug: Maybe<string>;
  payIn3: Maybe<boolean>;
};
