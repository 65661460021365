import * as React from 'react';
import {useMemo} from 'react';

import {AuthForm, LoadingSpinner, SplitCover} from '../../ui';
import {Context} from '../../global';

import {useTrackPage} from '../../../hooks';
import {useAuthView} from '../../../hooks/useAuthView';
import {AffirmationCardRedeemForm} from '../../ui/AffirmationCardRedeemForm';

export const RedeemAffirmationDeck: React.FC = () => {
  useTrackPage('Redeem affirmation card', 'redeem');
  const [_email, setEmail] = React.useState<string>();

  const {token, email, view, setView, loading, setLoading} = useAuthView();
  const {session} = React.useContext(Context);
  const redirect = () => undefined;
  const onVerify = ({
    email,
  }: {
    email?: string | undefined;
    name?: string | undefined;
  }) => {
    setView('verify');
    setEmail(email);
  };

  React.useEffect(() => {
    if (email && !_email) {
      setEmail(email);
    }
  }, [email]);

  const image = useMemo(() => {
    if (session) {
      return '/images/affirmation-redeem-code.jpg';
    }
    if (view === 'verify') {
      return '/images/affirmation-redeem-verify.jpg';
    }
    return '/images/affirmation-card-redeem.jpg';
  }, [view, session]);

  const mobileImage = useMemo(() => {
    if (session) {
      return '/images/affirmation-redeem-code-mobile.jpg';
    }
    if (view === 'verify') {
      return '/images/affirmation-redeem-verify-mobile.jpg';
    }
    return '/images/affirmation-card-redeem-mobile.jpg';
  }, [view, session]);

  return (
    <SplitCover index={0} image={image} mobileImage={mobileImage}>
      {session === null ? (
        <LoadingSpinner />
      ) : (
        <>
          {session === undefined ? (
            <AuthForm
              loading={loading}
              setLoading={setLoading}
              title="Create or login to your Mindfulness account"
              subtitle="To gain access to the coaching videos and meditations for your affirmation cards, create your login or sign in below."
              notice="If you already have the Mindfulness.com app installed, please use the email address found in your Account Profile under Personal Information."
              index={0}
              token={token}
              email={_email}
              view={view}
              setView={setView}
              redirect={redirect}
              onVerify={onVerify}
              // We are manually setting the view to 'verify' in the onVerify prop
              requireVerify={false}
              // Change the link that is sent in the email to the user to verify so that they come back to the same page
              verifyLink={`${window.location.pathname}${window.location.search}`}
              faqLink="http://help.mindfulness.com/en/articles/8693368-mindful-affirmation-cards"
            />
          ) : (
            <AffirmationCardRedeemForm />
          )}
        </>
      )}
    </SplitCover>
  );
};
