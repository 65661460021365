import * as React from 'react';

import {UpgradeLineItem} from './UpgradeLineItem';
import {LineItem, LoadingLineItem} from './LineItem';
import {CheckoutProps} from './Checkout.effects';
import {LinkButton} from './Checkout.styles';

import {Alert} from '../../forms';
import {Container, Row, Box} from '../../layout';
import {Divider} from '../../ui';
import {Text} from '../../typography';
import {DiscountContext, ModalContext} from '../../global';
import {useNavigation} from '../../../hooks';
import {Maybe, PlanFragment} from '../../../types/types';
import {formatMoney} from '../../../utils';

export const Items: React.FC<
  Omit<CheckoutProps, 'purchaseAliases'> & {
    hasTrial: boolean;
    trialDays: number;
    discounted: Maybe<PlanFragment>;
    loading: boolean;
  }
> = ({
  items,
  hasTrial,
  error,
  trialDays,
  type,
  bundle,
  upgrade,
  coupon,
  discounted,
  loading,
}) => {
  const {handleDiscount} = React.useContext(DiscountContext);
  const {openModal} = React.useContext(ModalContext);
  const {removeParam} = useNavigation();

  const hasLifetime = React.useMemo(() => {
    return Boolean(
        items.find(
            (plan) =>
              plan.renewalInterval === 'NEVER' && plan.productCode === 'mcom-plus',
        ),
    );
  }, [items]);

  const discountOrTrial = React.useMemo(() => {
    if (!discounted?.discount) {
      return;
    }
    const {discount} = discounted;
    if (discount.percentOff) {
      return `${discounted.discount.percentOff}%`;
    }
    if (discount.amountOff) {
      return `${formatMoney(discount.amountOff)}`;
    }
    if (discount.trialDays) {
      return `${discount.trialDays} day free trial`;
    }
  }, [discounted]);

  return (
    <Container gutter={{xs: 0, md: 80}}>
      <Row spacing={16}>
        <Box
          width="100%"
          background="greyWarm"
          radius={{
            xs: 'none',
            md: 'lg',
          }}
          padding={16}
        >
          <Alert error={error} />
          {loading ? (
            <LoadingLineItem />
          ) : (
            items.map((item) => (
              <LineItem
                {...item}
                key={item.id}
                hasTrial={hasTrial}
                trialDays={trialDays}
                type={type}
                bundle={bundle}
              />
            ))
          )}
          {upgrade ? <UpgradeLineItem /> : null}

          <Divider />
          {!coupon ? (
            <Text fontSize="sm" colour="grey7">
              {'Have a coupon? '}
              <LinkButton
                onClick={() =>
                  openModal?.({
                    name: 'coupon',
                  })
                }
                type="button"
              >
                Enter code
              </LinkButton>
            </Text>
          ) : items.length ? (
            <Text fontSize="sm" colour="grey7">
              {discounted ?
                `${discountOrTrial} ${
                    hasLifetime ? `discount applied.` : `coupon applied. `
                } ` :
                ``}
              {!hasLifetime && discounted ? (
                <LinkButton
                  onClick={() => {
                    removeParam('/subscribe', 'coupon');
                    handleDiscount?.(undefined);
                  }}
                >
                  Remove Coupon
                </LinkButton>
              ) : null}
            </Text>
          ) : null}
        </Box>
      </Row>
    </Container>
  );
};
