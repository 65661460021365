import {useMemo} from 'react';
import * as React from 'react';
import {Maybe} from '@mindfulness/utils/maybe';
import {switchEnum} from '@mindfulness/utils/logic';
import dayjs from 'dayjs';

import {
  BundleCheckoutFragment,
  CheckoutType,
  PlanFragment,
} from '../../../types/types';
import {formatMoney} from '../../../utils';
import {Flex, Box} from '../../layout';
import {AppButtonText, Small, Text} from '../../typography';
import {BackgroundAPIImage} from '../../global';

import {BogoLineItem} from './BogoLineItem';

export const LineItem: React.FC<Props> = ({
  name,
  discount,
  priceUSD,
  hasTrial,
  trialDays,
  type,
  planCode,
  productCode,
  bundle,
}) => {
  const isBogo = useMemo(() => {
    return type === 'bogo' && Boolean(discount?.freePass);
  }, [discount, type]);

  const moneyDiscount = useMemo(() => {
    if (!discount) {
      return false;
    }
    return discount?.amountOff ?? discount.percentOff ?? false;
  }, [discount]);

  const tagLine = useMemo(() => {
    if (!hasTrial) return;
    if (bundle?.id === 'b-ynkb') {
      return 'Starts on 12th September 2pm AEST';
    }
    const trialEndDate = dayjs().add(trialDays, 'd');
    return `${trialDays}-day free trial ends ${trialEndDate.format('D MMM')}.`;
  }, [trialDays, hasTrial, bundle]);

  const isGuestpass = useMemo(() => {
    return discount?.coupon === 'p__annual__guest-pass-30';
  }, [discount]);

  const title = useMemo(() => {
    if (bundle && productCode === 'mcom-event') {
      return bundle.title;
    }
    if (isGuestpass) {
      return 'Guest Pass';
    }
    return `Mindfulness ${
      productCode?.includes('basics') ? 'Basics' : 'Plus+'
    } ${hasTrial ? 'Free Trial' : ''}`;
  }, [isGuestpass, bundle, hasTrial, productCode]);

  const subTitle = useMemo(() => {
    if (productCode === 'mcom-event') {
      return name;
    }
    if (type === 'gift') {
      return `Gift card - ${
        planCode?.includes('annual') ? '12 months' : 'lifetime'
      }`;
    }
    if (isGuestpass) {
      return '30-Day Trial';
    }

    return `${name} plan`;
  }, [name, isGuestpass, type, planCode, productCode]);

  const src = useMemo(() => {
    if (type === 'gift') {
      return planCode?.includes('annual') ?
        '/images/line-item-giftcard.png' :
        '/images/line-item-giftcard-lifetime.png';
    }

    if (type === 'event') {
      return switchEnum(bundle?.id || 'else', {
        'b-mfpvb': '/images/masterclass_cart.jpg',
        'b-ynkb': '/images/immersion_cart.png',
        'else': '/images/line-item.png',
      });
    }
    return '/images/line-item.png';
  }, [type, planCode, bundle]);

  return (
    <>
      <Flex wrap="nowrap" gap={10} items="start" spacing={16}>
        <Box
          overflow="hidden"
          radius="lg"
          position="relative"
          width="64px"
          height="64px"
          zIndex={1}
        >
          <BackgroundAPIImage src={bundle?.cellImage?.url || src} doNotResize />
        </Box>

        <Flex
          column
          items="start"
          justify={tagLine ? 'space-between' : 'center'}
          height="64px"
          grow
        >
          <Box>
            <AppButtonText>{title}</AppButtonText>
            <Text
              colour="primary"
              transform="uppercase"
              fontSize="xs"
              fontWeight="bold"
            >
              {subTitle}
            </Text>
          </Box>
          {tagLine ? (
            <Small colour="grey7" fontSize="xs">
              {tagLine}
            </Small>
          ) : null}
        </Flex>

        <Box textAlign="right">
          {hasTrial ? (
            <Text>$0.00</Text>
          ) : moneyDiscount ? (
            <>
              <Text colour="grey5" decoration="line-through">
                {formatMoney(priceUSD)}
              </Text>
              <Text colour="highlight2">
                {formatMoney(discount?.finalPrice as number)}
              </Text>
            </>
          ) : (
            <Text>{formatMoney(priceUSD)}</Text>
          )}
        </Box>
      </Flex>
      {isBogo ? <BogoLineItem /> : null}
    </>
  );
};

type Props = PlanFragment & {
  hasTrial: boolean;
  trialDays: number;
  type: CheckoutType;
  bundle: Maybe<BundleCheckoutFragment>;
};

export const LoadingLineItem: React.FC = () => (
  <Flex wrap="nowrap" gap={10} items="start" spacing={16}>
    <Box
      overflow="hidden"
      radius="lg"
      position="relative"
      width="64px"
      height="64px"
      zIndex={1}
      background="white"
      shrink={0}
    />

    <Flex column items="start" justify={'space-between'} height="64px" grow>
      <Box>
        <Box
          width="70px"
          height="12px"
          background="grey9"
          radius="sm"
          marginB={2}
        />
        <Box
          width="50px"
          height="10px"
          background="primary"
          radius="sm"
          marginB={2}
        />
      </Box>
    </Flex>

    <Box
      textAlign="right"
      width="50px"
      height="10px"
      marginB={2}
      background="greyWarm"
    />
  </Flex>
);
