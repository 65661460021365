import styled from '@emotion/styled';
import {Maybe, OnboardingConfig} from '../../../types/types';
import {Title1} from '../../typography';

export const ProgressBarContainer = styled.div(
    () => `
  background-color: rgba(255,255, 255, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  backdrop-filter: blur(2px);
  z-index: 10;
`,
);

export const ProgressBarInner = styled.div(
    ({theme}) => `
  background-color: ${theme.colors.white};
  height: 10px;
  transition: ease width 0.3s;
`,
);

export const OnboardingHeader = styled.header<{
  type: Maybe<OnboardingConfig['type']>;
}>(
    ({theme, type}) => `
    display: flex;
    margin-top: 50px;
    ${
      type === 'personalization' ?
        `
        margin-bottom: 30px;
      justify-content: center;
    ` :
        `
    margin-bottom: 100px;
    `
}
`,
);

export const OnboardingMain = styled.main(
    ({theme}) => `
  color: ${theme.colors.white};
  margin-bottom: 100px;
  position: relative;
`,
);

export const ImageContainer = styled.div(
    () => `
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;
`,
);

export const Circles = styled.div(
    () => `
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;
`,
);

export const Circle = styled.div(
    () => `
  background: rgba(255, 255, 255, 0.01);
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  aspect-ratio: 1;

  &:nth-child(1) {
    max-width: 754px;
    width: 110%;
    transform: translateY(50%) translateX(-50%);
  }
  &:nth-child(2) {
    max-width: 624px;
    width: 100%;
    transform: translateY(60%) translateX(-50%);
  }
  &:nth-child(3) {
    max-width: 513px;
    width: 90%;
    transform: translateY(70%) translateX(-50%);
  background: rgba(255, 255, 255, 0.03);

  }
`,
);

export const StatsTitle = styled(Title1)(
    ({theme}) => `
  span {
    color: ${theme.colors.primary};
  }
`,
);

export const ContinueButton = styled.button<{
  loading: boolean;
}>(
    ({theme, loading}) => `
    ${
      loading ?
        `
    pointer-events: none;
    opacity: 0.4;
    ` :
        ``
}
  width: 100%;
  background-image: ${theme.gradients.primary};
  color: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 77px;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: ${theme.fontWeights.bold};
`,
);

export const TimedButton = styled.button<{
  time?: number;
  status: string;
}>(
    ({theme, status, time}) => `
  width: 100%;
  background-color: rgba(55, 55, 75, 0.9);
  color: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 77px;
  font-weight: ${theme.fontWeights.bold};
  position: relative;
  ${
    status === 'complete' ?
      `
      ` :
      `
      pointer-events: none;
      `
}

  &::after {
    content: "";
    position: absolute;
    background-color: ${theme.colors.primary};
    height: 100%;
    transition: ease width ${time}s;
    justify-self: start;
    left: 0;
    ${status === 'redirecting' ? 'opacity: 0.4;' : ''}
    ${
      status === 'ready' ?
        `
        width: 0%;
        ` :
        `
        width: 100%;
        `
}

  }
`,
);

export const ContinueButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const ButtonText = styled.div(
    ({theme}) => `
  z-index: 1;
  color: ${theme.colors.white};
  width: 100%;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
`,
);

export const StyledNextButton = styled.button<{ loading: boolean }>(
    ({loading}) => `
    width: 100%;
  position: relative;
  ${
    loading ?
      `
  pointer-events: none;
  ` :
      ``
}
`,
);
