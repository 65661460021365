import styled from '@emotion/styled';

export const PaginationButton = styled.button<{
  active: boolean;
}>(
    ({theme, active}) => `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${theme.colors.greyWarm};
  color: ${theme.colors.primary};
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
  }
  ${
    active ?
      `
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
  ` :
      ''
}


`,
);
