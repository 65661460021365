import * as React from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import {useNextSanityImage} from 'next-sanity-image';
import {SanityClientLike} from '@sanity/image-url/lib/types/types';
import {useContext} from 'react';

import {Column, Container, Row} from '../../layout';
import {H1, H4} from '../../typography';
import {Context, SanityImage} from '../../global';
import {usePageTimeTracking, useScrollTracking, useTrackPage} from '../../../hooks';
import {EnrichedArticles, ReferencedPages} from '../../../types/types';
import {articleSchema} from '../../../schemas';

import {Header} from './Article.styles';
import {assertString} from '../../../utils/string';

const BlockContent = dynamic(() =>
  import('../../global/BlockContent/index').then(
      ({BlockContent}) => BlockContent,
  ),
);

export const Article: React.FC<
  EnrichedArticles & { hub?: ReferencedPages; mobile?: boolean }
> = ({hub, mobile = false, ...article}) => {
  const {body, title, tagline, excerpt, image} = article;
  const {client} = useContext(Context);
  useTrackPage(assertString(article?.title, 'Article'), assertString(article.slug?.current), {
    page_type: 'ARTICLE',
  });
  useScrollTracking();
  usePageTimeTracking();

  const metaImage = useNextSanityImage(
    client as unknown as SanityClientLike,
    image?.image || null,
    {
      imageBuilder: (builder) => {
        return builder.width(1200).height(630);
      },
    },
  );

  return (
    <>
      <Header>
        <Container maxWidth="prose">
          <Row>
            <Column textAlign="center">
              {tagline ? <H4 colour="primary">{tagline}</H4> : null}
              {title ? <H1 spacing={24}>{title}</H1> : null}
              {excerpt ? (
                <H4 spacing={30} size="lg">
                  {excerpt}
                </H4>
              ) : null}
            </Column>
          </Row>
        </Container>
        <Container maxWidth="lg">
          {image ? (
            <SanityImage
              image={image}
              decoration="rounded"
              ratio="16:9"
              priority
            />
          ) : null}
        </Container>
      </Header>
      {body ? (
        <BlockContent
          container={false}
          background="white"
          content={body}
          fontSize="md"
          spacing={mobile ? 30 : 16}
          mobile={mobile}
        />
      ) : null}
      <Head>
        <title>{`${title} | Mindfulness.com`}</title>
        <script
          {...articleSchema({
            imageUrl: metaImage?.src || undefined,
            ...article,
          })}
        />
        <meta property="og:type" content="article" />
        <meta property="article:published_time" content={article._createdAt} />
        <meta property="article:modified_time" content={article._updatedAt} />
        {hub?.title && <meta property="article:section" content={hub.title} />}
      </Head>
    </>
  );
};
