import {GetSubscriptionsQuery} from '../../types/api';
import {GET_SUBSCRIPTIONS} from '../../api/queries/getSubscriptions';
import {getGraphClient} from '../../utils/graph';

export const getSubscriptions = async () => {
  const {data} = await getGraphClient().query<GetSubscriptionsQuery>({
    query: GET_SUBSCRIPTIONS,
  });
  return data?.session?.user?.subscriptions || [];
};
