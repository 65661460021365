import {
  UpdateOrderMutation,
  UpdateOrderMutationVariables,
} from '../../types/api';
import {getGraphClient} from '../../utils/graph';

import {MUTATE_UPDATE_ORDER} from '../mutations/updateOrder';

export const updateOrder = async (variables: UpdateOrderMutationVariables) => {
  const {data} = await getGraphClient().mutate<UpdateOrderMutation>({
    mutation: MUTATE_UPDATE_ORDER,
    variables,
    fetchPolicy: 'network-only',
  });
  return data?.updateOrder;
};
