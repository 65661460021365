import styled from '@emotion/styled';

export const GooglePayButton = styled.button(
    ({theme}) => `
  border-radius: 100px;
  background-color: ${theme.colors.white};
  width: 100%;
  border: 1px solid ${theme.colors.grey5};
  display: flex;
align-items: center;
justify-content: center;
height: 60px;
`,
);

export const ApplePayButton = styled.button(
    ({theme}) => `
border-radius: 100px;
background-color: #000;
width: 100%;
border: 1px solid #000;
display: flex;
align-items: center;
justify-content: center;
height: 60px;
color: #fff;
`,
);

export const LinkButton = styled.button(
    () => `
text-decoration: underline;
`,
);
