import * as React from 'react';
import {uniqBy} from 'lodash';
import {Global} from '@emotion/react';
import Marquee from 'react-fast-marquee';

import {testimonials} from './Onboarding.effects';

import {Box, Container, Stack} from '../../layout';
import {Title1} from '../../typography';
import {TotalReviews, TestimonialCard} from '../../ui';
import {TimedContinueButton} from './TimedContinueButton';

import {OnboardingPersonalization} from '../../../types/types';

export const Personalization: React.FC<OnboardingPersonalization> = ({
  title,
  buttonText,
  buttonTime,
}) => {
  const uniqTestimonials = React.useMemo(
      () => uniqBy(testimonials, 'user'),
      [testimonials],
  );
  const firstHalf = React.useMemo(
      () => uniqTestimonials.slice(0, Math.ceil(uniqTestimonials.length / 2)),
      [uniqTestimonials],
  );
  const secondHalf = React.useMemo(
      () => uniqTestimonials.slice(Math.ceil(uniqTestimonials.length / 2)),
      [uniqTestimonials],
  );

  return (
    <>
      <Global
        styles={`
        body {
          background-attachment: fixed;
          background-image: linear-gradient(163.37deg, #37374B 0%, #272753 92.38%);
          background-color: #272753;
        }
      `}
      />
      <Box textAlign="center" marginB={33}>
        <Title1 spacing={10}>{title}</Title1>
        <TotalReviews colour="white" />
      </Box>
      <Box
        display={{
          xs: 'none',
          md: 'block',
        }}
      >
        <Marquee
          style={{
            marginBottom: '-36px',
          }}
          className="marquee"
        >
          {[...firstHalf, ...firstHalf, ...firstHalf].map(
              ({review, user, _id}) => (
                <TestimonialCard author={user} key={_id}>
                  {review}
                </TestimonialCard>
              ),
          )}
        </Marquee>
        <Marquee direction="right" className="marquee">
          {[...secondHalf, ...secondHalf, ...secondHalf].map(
              ({review, user, _id}) => (
                <TestimonialCard author={user} key={_id}>
                  {review}
                </TestimonialCard>
              ),
          )}
        </Marquee>
      </Box>
      <Box
        display={{
          xs: 'block',
          md: 'none',
        }}
      >
        <Container maxWidth="xs">
          <Stack direction="vertical" space={16} items="center">
            {uniqTestimonials.map(({review, user, _id}) => (
              <TestimonialCard author={user} key={_id} location="stack">
                {review}
              </TestimonialCard>
            ))}
          </Stack>
        </Container>
      </Box>
      <TimedContinueButton time={buttonTime}>{buttonText}</TimedContinueButton>
    </>
  );
};
