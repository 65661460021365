import {when} from '@mindfulness/utils/maybe';
import {useContext, useEffect, useState} from 'react';
import {Maybe} from '../types/api';
import {Context} from '../components/global';
import {getFeeds} from '../components/templates/Feed/Feed.effects';
import {APIProps} from '../components/templates/Browse/Browse.effects';
import {useIntersection} from './useIntersection';

const hydrate = async (id: Maybe<string>): Promise<Maybe<APIProps>> => {
  try {
    if (!id) return;
    const feed = await when(id, async (f) => await getFeeds(f));
    return feed;
  } catch (e) {
    console.error(`Failed to useHydrateFeed`, e, id);
  }
};

export const useHydratedFeed = (staticFeed: Maybe<APIProps>) => {
  const {isIntersecting, ref} = useIntersection(true);
  const [loading, setLoading] = useState(false);
  const {session} = useContext(Context);
  const [feed, setFeed] = useState<Maybe<APIProps>>(staticFeed);

  useEffect(() => {
    (async () => {
      if (!staticFeed?.tagFeed?.id || session === null || !isIntersecting) {
        return;
      }
      if (!session) {
        setLoading(true);
        // If the user logs out, remove all the userData
        setFeed(staticFeed);
        setLoading(false);
        return;
      }
      setLoading(true);
      const hydratedFeed = await hydrate(staticFeed?.tagFeed.id);

      if (hydratedFeed) {
        setFeed(hydratedFeed);
      }
      setLoading(false);
    })();
  }, [staticFeed?.tagFeed?.id, session, isIntersecting]);

  return {
    loading,
    feed,
    ref,
  };
};
