import {GET_PLAYLIST_PATHS} from '../../../api';
import {GetPlaylistsQuery} from '../../../types/types';
import {adminClient} from '../../../utils';

export const getStaticPaths = async () => {
  const {data} = await adminClient.query<GetPlaylistsQuery>({
    query: GET_PLAYLIST_PATHS,
    variables: {
      withUserData: false,
      resultsTake: 100,
    },
  });
  return data.bundles.items;
};
